import React, { useState } from 'react'
import { observer } from 'mobx-react'
import { Button, Drawer, Form } from 'antd';
import useStore from '../../../../../store';
import FormComponent from './FormComponent';
import { vsmBankAccount, vsmNotify } from '../../../../../config/Originator/AppsMessage';

const AddComponent = observer((props) => {
    const [form] = Form.useForm();
    const { AppsAuthStore, BanksStore } = useStore()
    const [saving, setSaving] = useState(false)
    const [isDisabled, setIsDisabled] = useState(true)
    const [fetchBankNames, setFetchBankNames] = useState(true)
    const [fetchCurrencies, setFetchCurrencies] = useState(true)
    const [fetchBankTypes, setFetchBankTypes] = useState(true)
    const [currentTab, setCurrentTab] = useState("account")

    const handleSubmit = (data) => {
        BanksStore.addData(data).then(() => {
            vsmNotify.success({
                message: vsmBankAccount.add
            })
            close()
        }).catch(e => {
            if (e.errors) { form.setFields(e.errors) }
        }).finally(() => setSaving(false))
    }

    const close = () => {
        props.close()
        setSaving(false)
        setIsDisabled(false)
    }

    return (
        <Drawer
            visible={props.visible}
            onClose={close}
            placement="right"
            width={"50%"}
            destroyOnClose={true}
            zIndex={AppsAuthStore.list_fullscreen ? 1011 : 1001}
            title="Add Bank"
            footer={[
                <div className="text-center" key={1}>
                    <Button
                        form="addEditBankForm"
                        className="mr-10"
                        loading={saving}
                        htmlType="submit"
                        type="primary"
                        disabled={isDisabled}
                    >
                        Save
                    </Button>
                    <Button onClick={close}>Cancel</Button>
                </div>
            ]}
        >
            <FormComponent
                form={form}
                setDisabled={setIsDisabled}
                handleSubmit={handleSubmit}
                fetchBankNames={fetchBankNames}
                setFetchBankNames={setFetchBankNames}
                fetchCurrencies={fetchCurrencies}
                setFetchCurrencies={setFetchCurrencies}
                fetchBankTypes={fetchBankTypes}
                setFetchBankTypes={setFetchBankTypes}
                currentTab={currentTab}
                setCurrentTab={setCurrentTab}
            />
        </Drawer>
    )
})

export default AddComponent;