import Axios from 'axios'
import { action, decorate, observable } from 'mobx'
import LocalGridConfig from '../../config/LocalGridConfig'
import { vsmNotify } from '../../config/Originator/AppsMessage'

export default class BanksStore {
    per_page = LocalGridConfig.options.paginationPageSize
    current_page = 1
    total = 0
    agGrid = null
    bank_list = null
    bankNameList = null
    dropdown_bank_type_list = null
    dropdown_currency_list = null
    readData = null

    // change page size, default page size is GridConfig.options.paginationPageSize
    setPageSize = (page = LocalGridConfig.options.paginationPageSize) => {
        this.per_page = page
        this.agGrid.api.paginationSetPageSize(parseInt(page))
    }

    handleReset = () => {
        localStorage.removeItem('setting_bank_list')
        this.agGrid.api.setFilterModel(null)
        this.agGrid.api.setSortModel(null)
        this.agGrid.columnApi.resetColumnState()
        this.agGrid.api.onFilterChanged(null)
    }

    // Set column width after resizing colums
    onGridChanged = (params) => {
        localStorage.setItem('setting_bank_list', JSON.stringify(params.columnApi.getColumnState()))
    }

    // Setup grid and set column size to autosize

    setupGrid = (params) => {
        this.agGrid = params
        let columnConfig = localStorage.getItem('setting_bank_list')
        if (this.agGrid && this.agGrid.columnApi && columnConfig) {
            this.agGrid.columnApi.applyColumnState({ state: JSON.parse(columnConfig) })
        }
    }

    // Filter function for no record found message
    onFilterChanged = (params) => {
        if (this.agGrid && this.agGrid.api.rowModel.rowsToDisplay.length > 0) {
            this.agGrid.api.hideOverlay()
        }
    }

    getList = (payload = {}) => {
        if (this.agGrid) {
            var filter = this.agGrid.api.getFilterModel(filter)
            var sort = this.agGrid.columnApi.getColumnState()
        }
        return Axios.post(`/originator/bankaccount/list`, payload).then(({ data }) => {
            if (data.data.length) {
                data.data.forEach((item) => {
                    item.published = item.status ? "Active" : "Deactive"
                    item.bank_type = item.bank_account_type && item.bank_account_type.name ? item.bank_account_type.name : null
                })
            }
            this.bank_list = data.data
            this.total = data.total
            this.current_page = data.current_page
            if (this.agGrid) {
                this.agGrid.api.setFilterModel(filter)
                this.agGrid.columnApi.applyColumnState({ state: sort })
            }
            return data
        })
    }

    // call for bank name list for auto populate
    getBanksNameList = () => {
        return Axios.get(`/global/banks/get`).then(({ data }) => {
            let i
            let arrayObj = data.data
            for (i = 0; i < arrayObj.length; i++) {
                arrayObj[i].value = arrayObj[i]['name']
                delete arrayObj[i].key1
            }
            this.bankNameList = arrayObj
            return data
        }).catch(() => {
            this.bankNameList = null
        })
    }

    // call for bank account types list
    getBankAccountTypeList = () => {
        return Axios.get(`bankaccount/bankAccountType`).then(({ data }) => {
            this.dropdown_bank_type_list = data.data
            return data
        }).catch(() => {
            this.dropdown_bank_type_list = null
        })
    }

    // call for currency list
    getCurrencyList = () => {
        return Axios.get(`globalcurreny/ledger/currency`).then(({ data }) => {
            this.dropdown_currency_list = data.data
            return data
        }).catch(() => {
            this.dropdown_currency_list = null
        })
    }

    // Call add api
    addData = (formdata) => {
        return Axios.post(`/originator/bankaccount/add`, formdata).then(({ data }) => {
            this.getList()
            return data
        }).catch(({ response: { data } }) => {
            var errors = []
            Object.keys(data.errors).forEach(name => {
                errors.push({ name, errors: data.errors[name] })
            })
            data.errors = errors
            return Promise.reject(data)
        })
    }

    readBankDetail = (id) => {
        return Axios.post(`originator/bankaccount/read/${id}`).then(({ data }) => {
            this.readData = data.data
            return data.data
        }).catch(({ response: { data } }) => {
            vsmNotify.error({
                message: data.message
            })
        })
    }

    // Call edit api
    editData = (formdata) => {
        return Axios.post(`/originator/bankaccount/edit/${formdata.id}`, formdata).then(({ data }) => {
            this.getList()
            return data
        }).catch(({ response: { data } }) => {
            var errors = []
            Object.keys(data.errors).forEach(name => {
                errors.push({ name, errors: data.errors[name] })
            })
            data.errors = errors
            return Promise.reject(data)
        })

    }

    changeStatus = (formdata) => {
        return Axios.post(`/originator/bankaccount/status/${formdata.id}`, formdata).then(({ data }) => {
            this.getList()
            return data
        }).catch(({ response: { data } }) => {
            var errors = []
            Object.keys(data.errors).forEach(name => {
                errors.push({ name, errors: data.errors[name] })
            })
            data.errors = errors
            return Promise.reject(data)
        })
    }
}

decorate(BanksStore, {
    per_page: observable,
    current_page: observable,
    total: observable,
    agGrid: observable,
    bank_list: observable,
    bankNameList: observable,
    dropdown_bank_type_list: observable,
    dropdown_currency_list: observable,
    readData: observable,
    getBanksNameList: action,
    getBankAccountTypeList: action,
    getCurrencyList: action,
    addData: action,
    editData: action,
    changeStatus: action,
    readBankDetail: action
})