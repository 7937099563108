import React from 'react'
import { HomeOutlined } from '@ant-design/icons';

export const AppsBreadcrumbConfig = {
	TradeFloor: {
		title: 'Trade List',
		path: [
			{ name: <HomeOutlined />, link: '/apps/dashboard' },
			{ name: 'Trading' },
			{ name: 'Trade List' },
		]
	},
	Credebtors: {
		title: 'All Credebtors',
		path: [
			{ name: <HomeOutlined />, link: '/apps/dashboard' },
			{ name: 'My Credebtors' },
			{ name: 'All Credebtors' },
		]
	},
	Transactions: {
		title: 'Transaction list',
		path: [
			{ name: <HomeOutlined />, link: '/apps/dashboard' },
			{ name: 'Transactions' },
			{ name: 'All Transactions' },
		]
	},
	All_Banks: {
		title: 'All Banks',
		path: [
			{ name: <HomeOutlined />, link: '/apps/dashboard' },
			{ name: 'Settings' },
			{ name: 'All Banks' }
		]
	}
}
