import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import useStore from '../../store';
import { Apps_RouterConfig } from '../../config/RouterConfig';
import { useHistory, useLocation } from 'react-router-dom';
import { Layout, Spin } from 'antd';
import AppsSidebarComponent from './AppsSidebarComponent';
import AppsHeaderComponent from './AppsHeaderComponent';
const { Content } = Layout;

const AppsLayoutComponent = observer(({ children }) => {

    const { AppsAuthStore } = useStore()
    const [collapsed, setCollapsed] = useState(false)
    const [setting, setSetting] = useState(null)
    const [internalLoader, setInternalLoader] = useState(true)

    let history = useHistory()
    let location = useLocation()

    useEffect(() => {
        if (!AppsAuthStore.loading) {
            const apps_redirectDefault = Apps_RouterConfig.find(x => x.default)
            const app_redirectAuthDefault = Apps_RouterConfig.find(x => x.authdefault)

            let path = Apps_RouterConfig.find(x => x.path === location.pathname.trimRight('/'))
            if (path && path.apps_auth && AppsAuthStore.originator_token === null) {
                history.replace(apps_redirectDefault.path)
            } else if (path && !path.apps_auth && AppsAuthStore.originator_token && !path.errorpage) {
                history.replace(app_redirectAuthDefault.path)
            }
        } else {
            setInternalLoader(true)
        }

        let apps_route = Apps_RouterConfig.find(x => x.path === location.pathname.trimRight('/'))
        if (apps_route && apps_route.setting) {
            setSetting(apps_route.setting)
        } else {
            if (setting === null) { setSetting({ header: true, nav: true }) }
        }

        setTimeout(() => {
            setInternalLoader(false)
        }, 500)
    }, [AppsAuthStore.loading, AppsAuthStore.originator_token, history, location.pathname, setting])

    window.onload = function () {
        function receiveMessage(e) {
            if (e.origin !== window.location.origin) {
                localStorage.setItem('app_token', e.data)
                return;
            }
        }
        window.addEventListener('message', receiveMessage);
    }

    // call logout function
    const Logout = () => {
        // doLogout()
        history.replace('/')
    }

    return (AppsAuthStore.loading || internalLoader) ? (
        <div className="fullscreen__spinner">
            <Spin size="large" />
        </div>
    ) : (
        <Layout className="main__layout__wrapper">
            {
                (AppsAuthStore.originator_token && setting && setting.nav && (
                    <AppsSidebarComponent collapsed={collapsed} />
                ))
            }

            <Layout className="site-layout">
                {
                    (AppsAuthStore.originator_token && setting && setting.header && (
                        <AppsHeaderComponent logout={Logout} user={AppsAuthStore.originator_user} collapsed={collapsed} setCollapsed={setCollapsed} />
                    ))
                }
                {
                    (AppsAuthStore.originator_token && setting && (setting.header || setting.nav)) ? (
                        <Content
                            className="site-layout-background"
                            style={{
                                padding: 24,
                                paddingTop: 0,
                                overflowY: 'auto',
                                maxHeight: 'calc(100vh - 65px)',
                                position: 'relative'
                            }}
                        >
                            {children}
                        </Content>
                    ) : (<>{children}</>)
                }
            </Layout>
        </Layout>
    )
})

export default AppsLayoutComponent;