import React, { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Button, Drawer, Form } from 'antd';
import FormComponent from './FormComponent';
import useStore from '../../../../store';
import { vsmCredebtor, vsmNotify } from '../../../../config/Originator/AppsMessage';
import moment from 'moment';

const EditComponent = observer((props) => {
    const [form] = Form.useForm()
    const { CredebtorsStore, UserProfileStore, AppsAuthStore } = useStore()
    const [saving, setSaving] = useState(false)
    const [isDisabled, setDisabled] = useState(true)
    const [currentTab, setCurrentTab] = useState("account")
    const [fileList, updateFileList] = useState([]);
    const [expFileList, updateExpFileList] = useState([]);
    const [bankDetails, setBankDetails] = useState(false)
    const [fetchBankType, setFetchBankType] = useState(true)
    const [fetchCountry, setFetchCountry] = useState(true)
    const [fetchStatus, setFetchStatus] = useState(true)
    const [fetchIndustries, setFetchIndustries] = useState(true)
    const [fetctNace, setFetchNace] = useState(true)
    const [fetchCurrency, setFetchCurrency] = useState(true)

    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);

    const handleChange = () => {
        form.validateFields().then((data) => {
            let checkbox = form.getFieldValue("add_address")
            let address = form.getFieldValue("addresses")
            address = address && address.filter(x => x && x)
            if (checkbox && (!address || (address && address.length === 0))) {
                setDisabled(true)
            } else {
                setDisabled(false)
            }
        }).catch(e => {
            if (e.errorFields && e.errorFields.length > 0) {
                setDisabled(true)
            }
        })
    }

    const handleSubmit = (data) => {
        if (data.phone && data.phone.length > 0) {
            data.phone = data.phone.filter(x => x && (x.contact_for && x.contact_value))
            data.phone = data.phone.length > 0 ? JSON.stringify(data.phone) : null
        }

        if (data.email && data.email.length > 0) {
            data.email = data.email.filter(x => x && (x.contact_for && x.contact_value))
            data.email = data.email.length > 0 ? JSON.stringify(data.email) : null
        }

        if (data.addresses && data.addresses.length > 0) {
            data.addresses && data.addresses.forEach((item) => {
                item.city = item.city ? item.city : null
                item.address_type = item.address_type ? item.address_type : null
                item.address_1 = item.address_1 ? item.address_1 : null
                item.address_2 = item.address_2 ? item.address_2 : null
                item.address_3 = item.address_3 ? item.address_3 : null
                item.state_county = item.state_county ? item.state_county : null
                item.postal_code = item.postal_code ? item.postal_code : null
            })
            data.addresses = data.addresses.filter(x => x && x)
            data.addresses = data.addresses.length > 0 ? JSON.stringify(data.addresses) : null
        }

        if (data.banks && data.banks.length > 0) {
            data.banks = data.banks.filter(x => x && x)
            data.banks = data.banks.length > 0 ? JSON.stringify(data.banks) : null
        }

        setSaving(true)
        var formData = CredebtorsStore.JsonToFormData(data)

        formData.append("add_address", data.addresses && data.addresses.length > 0 ? 1 : 0)

        formData.append("add_bank", data.banks && data.banks.length > 0 ? 1 : 0)

        formData.append("counter_party_since", moment(data.counter_party_since).format('YYYY'))

        if (data.logo_url && data.logo_url.fileList[0] && data.logo_url.fileList.length > 0) {
            formData.append("logo_url", data.logo_url.fileList[0].originFileObj)
        } else if (data.logo_url && data.logo_url.fileList.length === 0) {
            formData.append('logo_status', 'deleted')
        }

        if (typeof (data.experience_file) === 'string') {
            formData.delete('experience_file')
        } else {
            if (data.experience_file && data.experience_file.fileList.length > 0) {
                formData.append("experience_file", data.experience_file.fileList[0].originFileObj);
            } else {
                formData.append("experience_file", null);
            }
        }

        CredebtorsStore.EditData(formData, CredebtorsStore.read_data.id).then(() => {
            close()
            vsmNotify.success({
                message: vsmCredebtor.add
            })
        }).catch(e => {
            if (e.errors) { form.setFields(e.errors) }
        }).finally(() => setSaving(false))
    }

    const close = () => {
        setSaving(false)
        setDisabled(false)
        setBankDetails(false)
        setFetchBankType(true)
        updateFileList([])
        updateExpFileList([])
        setCurrentTab('account')
        UserProfileStore.industries_list = null
        UserProfileStore.dropdown_currency_list = null
        CredebtorsStore.initialValues.banks = []
        CredebtorsStore.initialValues.phone = [{ id: null, contact_for: 'Primary' }]
        CredebtorsStore.initialValues.email = [{ id: null, contact_for: 'Primary' }]
        CredebtorsStore.initialValues.adddress = []
        setFetchCountry(true)
        setFetchStatus(true)
        setFetchIndustries(true)
        setFetchNace(true)
        setFetchCurrency(true)
        form.resetFields()
        props.close()
        CredebtorsStore.read_data = null
    }

    const onRoleChange = (e) => {
        if (e === 'Creditor') {
            setBankDetails(true)
            updateExpFileList([])
        } else {
            setBankDetails(false)
        }
    }

    useEffect(() => {
        if (CredebtorsStore.read_data && props.visible) {
            UserProfileStore.naceCode_list = CredebtorsStore.read_data.organisation.global_nace_sections && [CredebtorsStore.read_data.organisation.global_nace_sections]
        }
    }, [props.visible, CredebtorsStore.read_data, UserProfileStore.naceCode_list, UserProfileStore.industries_list])

    useEffect(() => {
        if (props.visible && CredebtorsStore.read_data) {
            updateFileList(CredebtorsStore.read_data.organisation.logo_url ? ([
                {
                    uid: '-1',
                    name: 'image.png',
                    status: 'done',
                    url: CredebtorsStore.read_data.organisation.logo_url,
                },
            ]) : [])

            updateExpFileList(CredebtorsStore.read_data.experience_file ? ([
                {
                    uid: '-1',
                    name: CredebtorsStore.read_data.experience_file_name,
                    status: 'done',
                    url: CredebtorsStore.read_data.experience_file,
                },
            ]) : [])

            CredebtorsStore.read_data.banks && CredebtorsStore.read_data.banks.length > 0 && CredebtorsStore.read_data.banks.map((item) => {
                setCurrentTab((item.iban !== null) ? "iban" : "account")
                item.is_account = item.iban !== null ? false : true
                item.tab = item.iban !== null ? "iban" : "account"

                return item
            })

            form.setFieldsValue({
                organisation_name: CredebtorsStore.read_data.organisation && CredebtorsStore.read_data.organisation.organisation_name,
                role: CredebtorsStore.read_data.role && CredebtorsStore.read_data.role.role_name,
                trade_name: CredebtorsStore.read_data.organisation && CredebtorsStore.read_data.organisation.trade_name,
                registered_country_id: CredebtorsStore.read_data.organisation && CredebtorsStore.read_data.organisation.global_countries && CredebtorsStore.read_data.organisation.global_countries.id,
                registration_number: CredebtorsStore.read_data.organisation && CredebtorsStore.read_data.organisation.registration_number,
                vat_number: CredebtorsStore.read_data.organisation && CredebtorsStore.read_data.organisation.vat_number,
                website: CredebtorsStore.read_data.organisation && CredebtorsStore.read_data.organisation.website,
                phone: CredebtorsStore.read_data.organisation && CredebtorsStore.read_data.organisation.mutiple_phones.length > 0 ? CredebtorsStore.read_data.organisation.mutiple_phones.filter(x => x.contact_type === "phone") : CredebtorsStore.initialValues.phone,
                email: CredebtorsStore.read_data.organisation && CredebtorsStore.read_data.organisation.mutiple_email.length > 0 ? CredebtorsStore.read_data.organisation.mutiple_email.filter(x => x.contact_type === "email") : CredebtorsStore.initialValues.email,
                industry_id: CredebtorsStore.read_data.organisation && CredebtorsStore.read_data.organisation.global_industries && CredebtorsStore.read_data.organisation.global_industries.id,
                nace_section_id: CredebtorsStore.read_data.organisation && CredebtorsStore.read_data.organisation.global_nace_sections && CredebtorsStore.read_data.organisation.global_nace_sections.id,
                currency_id: CredebtorsStore.read_data.organisation && CredebtorsStore.read_data.organisation.global_currency && CredebtorsStore.read_data.organisation.global_currency.id,
                revenue_access_number: CredebtorsStore.read_data.organisation && CredebtorsStore.read_data.organisation.revenue_access_number,
                description: CredebtorsStore.read_data.organisation && CredebtorsStore.read_data.organisation.description,
                counter_party_since: CredebtorsStore.read_data.counter_party_since && moment(CredebtorsStore.read_data.counter_party_since),
                banks: CredebtorsStore.read_data.banks && CredebtorsStore.read_data.banks.length > 0 ? CredebtorsStore.read_data.banks : CredebtorsStore.initialValues.banks,
                addresses: CredebtorsStore.read_data.organisation && CredebtorsStore.read_data.organisation.mutiple_addresses && CredebtorsStore.read_data.organisation.mutiple_addresses.length > 0 ? CredebtorsStore.read_data.organisation.mutiple_addresses : CredebtorsStore.initialValues.adddress,
                credit_notes_issued: CredebtorsStore.read_data.credit_notes_issued,
                predicted_annual_revenue: CredebtorsStore.read_data.predicted_annual_revenue,
                invoice_predicted_per_annum: CredebtorsStore.read_data.invoice_predicted_per_annum,
                average_payment_cycle: CredebtorsStore.read_data.average_payment_cycle,
                total_historic_value: CredebtorsStore.read_data.total_historic_value,
                experience_file: CredebtorsStore.read_data.experience_file,
                agreed_early_payment_discount: CredebtorsStore.read_data.agreed_early_payment_discount,
            })
            onRoleChange(CredebtorsStore.read_data.role && CredebtorsStore.read_data.role.role_name);
        }
        forceUpdate();
    }, [props.visible, CredebtorsStore.read_data, form, CredebtorsStore.initialValues.phone, CredebtorsStore.initialValues.email, CredebtorsStore.initialValues.banks, CredebtorsStore.initialValues.adddress, forceUpdate])

    return CredebtorsStore.read_data && (
        <Drawer
            visible={props.visible}
            onClose={close}
            placement='right'
            width={'50%'}
            destroyOnClose={true}
            zIndex={AppsAuthStore.list_fullscreen ? 1011 : 1001}
            title={`Edit Credebtor - #${CredebtorsStore.read_data && CredebtorsStore.read_data.id} - ${CredebtorsStore.read_data && CredebtorsStore.read_data.organisation && CredebtorsStore.read_data.organisation.organisation_name}`}
            footer={[
                <div className="text-center" key={1}>
                    <Button form='addeditform' className="mr-10" loading={saving} htmlType="submit" type="primary" disabled={isDisabled}>Save</Button>
                    <Button onClick={close}>Cancel</Button>
                </div>
            ]}
        // style={{ left: AppsAuthStore.list_fullscreen ? '0px' : '280px' }}
        >
            <FormComponent
                form={form}
                onChange={handleChange}
                handleSubmit={handleSubmit}
                setDisabled={setDisabled}
                currentTab={currentTab}
                setCurrentTab={setCurrentTab}
                updateFileList={updateFileList}
                fileList={fileList}
                onRoleChange={onRoleChange}
                bankDetails={bankDetails}
                setFetchBankType={setFetchBankType}
                fetchBankType={fetchBankType}
                updateExpFileList={updateExpFileList}
                expFileList={expFileList}
                forceUpdate={forceUpdate}
                setFetchCountry={setFetchCountry}
                setFetchStatus={setFetchStatus}
                setFetchIndustries={setFetchIndustries}
                setFetchNace={setFetchNace}
                setFetchCurrency={setFetchCurrency}
                fetchCountry={fetchCountry}
                fetchStatus={fetchStatus}
                fetchIndustries={fetchIndustries}
                fetctNace={fetctNace}
                fetchCurrency={fetchCurrency}
                activeAddress={CredebtorsStore.read_data.organisation && CredebtorsStore.read_data.organisation.mutiple_addresses && CredebtorsStore.read_data.organisation.mutiple_addresses.length > 0 ? true : false}
                activebanks={CredebtorsStore.read_data && CredebtorsStore.read_data.banks && CredebtorsStore.read_data.banks.length > 0 ? true : false}
            />
        </Drawer>
    );
})

export default EditComponent;