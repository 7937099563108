import React from "react"
import { observer } from "mobx-react"
import { Col, Row, Spin } from "antd"
import InputComponent from "../../../../../components/InputComponent"
import { vsmCredebtor, vsmProfile } from "../../../../../config/Originator/AppsMessage"
import useStore from "../../../../../store"

const BankDetailsSubElements = observer(({ onChange, setCurrency, onTabChanges, currency, field, index, form, setFetchBankType, fetchBankType, currentTab }) => {

    const { UserProfileStore, CredebtorsStore } = useStore()

    return (
        <>
            <Row gutter={10}>
                <Col span={10}>
                    <InputComponent
                        required
                        label="Bank Name"
                        name={[field.name, 'bank_name']}
                        key={[field.key, 'bank_name']}
                        placeholder="Bank Name"
                        onChange={onChange}
                        tooltip="Bank Name (for a Bank Account) is the name of a banking or cash processing organisation that operates a specific bank account."
                        rules={vsmCredebtor.validation.bank_name}
                        maxLength={100}
                    />
                </Col>

                <Col span={7}>
                    <InputComponent
                        required
                        allowClear
                        type="select"
                        label="Bank Account Type"
                        name={[field.name, 'bank_account_type_id']}
                        key={[field.key, 'bank_account_type_id']}
                        placeholder="Select Bank Account Type"
                        onChange={onChange}
                        options={{
                            values: CredebtorsStore.dropdown_bank_type_list || (
                                CredebtorsStore.read_data && CredebtorsStore.read_data.banks && CredebtorsStore.read_data.banks.length > 0 && CredebtorsStore.read_data.banks.map((item) => ({
                                    id: item.bank_account_type_id,
                                    name: item.account_type_name,
                                }))
                            ),
                            value_key: "id",
                            text_key: "name"
                        }}
                        onFocus={() => (fetchBankType && CredebtorsStore.getBankAccountTypeList().then(() => setFetchBankType(false)))}
                        notFoundContent={fetchBankType ? <Spin size="small" /> : "No Record Found."}
                        tooltip="A name or code given to a cash account that indicates the account's purpose."
                        rules={vsmCredebtor.validation.account_type}
                    />
                </Col>
                <Col span={7}>
                    <InputComponent
                        required
                        allowClear
                        type="select"
                        label="Currency"
                        name={[field.name, 'bank_currency_id']}
                        key={[field.key, 'bank_currency_id']}
                        placeholder="Select Currency"
                        onChange={onChange}
                        rules={vsmProfile.validation.currency_id}
                        tooltip="Currency is a medium of exchange for goods and services. Setting Base currency will set this currency as default currency throughout Ledger."
                        onFocus={() => (currency && UserProfileStore.getCurrencyList().then(() => setCurrency(false)))}
                        notFoundContent={currency ? <Spin size="small" /> : "No Record Found."}
                        options={{
                            values: UserProfileStore.dropdown_currency_list || (
                                CredebtorsStore.read_data && CredebtorsStore.read_data.banks && CredebtorsStore.read_data.banks.length > 0 && CredebtorsStore.read_data.banks.map((item) => ({
                                    id: item.bank_currency_id,
                                    currency_name: item.currency_name,
                                }))
                            ),
                            value_key: "id",
                            text_key: "currency_name"
                        }}
                    />
                </Col>
            </Row>

            <Row gutter={10}>
                <Col span={8} style={{ marginTop: 30 }}>
                    <InputComponent
                        type="radio_button"
                        name={[field.name, 'tab']}
                        key={[field.key, 'tab']}
                        // initialValue="account"
                        defaultValue={currentTab}
                        onChange={(e) => { onChange(); onTabChanges(); }}
                        options={{
                            values: [
                                { value: 'account', text: 'Account Number' },
                                { value: 'iban', text: 'IBAN Number' }
                            ]
                        }}
                    />
                </Col>

                {
                    form.getFieldValue("banks") && form.getFieldValue("banks")[index] && form.getFieldValue("banks")[index]["is_account"] ?
                        <>
                            <Col span={6}>
                                <InputComponent
                                    required
                                    label="Account Number"
                                    name={[field.name, 'account_number']}
                                    key={[field.key, 'account_number']}
                                    placeholder="Account Number"
                                    onChange={onChange}
                                    tooltip="Account Number is a short version of the cash account number (alternative to or part of IBAN)."
                                    rules={vsmCredebtor.validation.account_number}
                                    maxLength={50}
                                />
                            </Col>

                            <Col span={6}>
                                <InputComponent
                                    required
                                    label="Sort Code"
                                    name={[field.name, 'sort_code']}
                                    key={[field.key, 'sort_code']}
                                    placeholder="Sort Code"
                                    onChange={onChange}
                                    tooltip="A Sort Code is a number code, which is used by various banks (i.e. in Ireland, United Kingdom, etc.)."
                                    rules={vsmCredebtor.validation.sort_code}
                                    maxLength={50}
                                />
                            </Col>
                        </>
                        :
                        <>
                            <Col span={6}>
                                <InputComponent
                                    required
                                    label="IBAN"
                                    name={[field.name, 'iban']}
                                    key={[field.key, 'iban']}
                                    placeholder="International Bank Account Number"
                                    onChange={onChange}
                                    tooltip="An IBAN, or International Bank Account Number, is a standard international numbering system developed to identify an overseas/foreign bank account."
                                    rules={vsmCredebtor.validation.iba_num}
                                />
                            </Col>

                            <Col span={6}>
                                <InputComponent
                                    required
                                    label="Swift or BIC Code"
                                    name={[field.name, 'swift_bic']}
                                    key={[field.key, 'swift_bic']}
                                    placeholder="Swift or BIC Code"
                                    onChange={onChange}
                                    tooltip="A SWIFT code is an international bank code that identifies particular banks worldwide. It's also known as a Bank Identifier Code (BIC)."
                                    rules={vsmCredebtor.validation.swift_or_bic_code}
                                    maxLength={50}
                                />
                            </Col>
                        </>
                }
            </Row>
        </>
    )
})

export default BankDetailsSubElements