import React, { useEffect, useState } from "react"
import { observer } from "mobx-react"
import { useHistory, useLocation } from 'react-router-dom';
import { AppsBreadcrumbConfig } from "../../../config/BreadcrumbConfig"
import useStore from "../../../store"
import { Button, PageHeader } from "antd"
import ListComponent from "./components/ListComponent"
import AddComponent from "./components/AddComponent"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUndo } from '@fortawesome/free-solid-svg-icons'
import EditComponent from "./components/EditComponent"
import Unauthorized from "../../Unauthorized"
import EnterFullscreen from "../../../components/EnterFullscreen"
import AppsBreadcrumbComponent from "../../../components/Originator/AppsBreadcrumbComponent"
import RecordPerPage from "../../../components/RecordPerPage"
import DeleteComponent from "./components/DeleteComponent"
import ViewComponent from "./components/ViewComponent"

const Credebtors = observer(() => {
    let history = useHistory()
    const { CredebtorsStore, AppsAuthStore } = useStore()
    const [credebtorDrawer, setCredebtorDrawer] = useState(useLocation().search === '?add-credebtor-popup=1')
    const [editCredebtorDrawer, setEditCredebtorDrawer] = useState(false)
    const [deleteCredebtorDrawer, setDeleteCredebtorDrawer] = useState(false)
    const [viewCredebtorDrawer, setViewCredebtorDrawer] = useState(false)
    const [viewType, setViewType] = useState(false)

    history.listen((location, action) => {
        if (location.search === '?add-credebtor-popup=1') {
            setCredebtorDrawer(true)
        } else {
            setCredebtorDrawer(false)
        }
    })

    //open add drawer
    const openCredebtorDrawer = () => {
        setCredebtorDrawer(true)
    }

    //close add drawer
    const closeCredebtorDrawer = () => setCredebtorDrawer(false)

    //open edit drawer
    const openEditCredebtorDrawer = (data) => {
        CredebtorsStore.getRecordDetails(data.id)
        setEditCredebtorDrawer(true)
    }

    //close edit drawer
    const closeEditCredebtorDrawer = () => setEditCredebtorDrawer(false)

    //open edit drawer
    const openDeleteCredebtorDrawer = (data) => {
        CredebtorsStore.setDeleteValues(data)
        setDeleteCredebtorDrawer(true)
    }

    //close edit drawer
    const closeDeleteCredebtorDrawer = () => setDeleteCredebtorDrawer(false)

    //open edit drawer
    const openViewCredebtorDrawer = (data) => {
        setViewType(data.role.role_name)
        CredebtorsStore.getRecordDetails(data.id, 'view')
        setViewCredebtorDrawer(true)
    }

    useEffect(() => {
        return () => localStorage.removeItem('selectedFilter')
    }, [])


    //close edit drawer
    const closeViewCredebtorDrawer = () => setViewCredebtorDrawer(false)
    return (
        <>
            <AppsBreadcrumbComponent items={AppsBreadcrumbConfig.Credebtors.path} />
            {AppsAuthStore.permissions && (!AppsAuthStore.checkPermission(1007, 'list')) ? (<Unauthorized />) : (
                <PageHeader
                    className={AppsAuthStore.fullscreen_class}
                    title={AppsBreadcrumbConfig.Credebtors.title}
                    extra={[
                        (AppsAuthStore.checkPermission(1007, 'add')) && <Button key="1" onClick={openCredebtorDrawer}>Add Credebtor</Button>,
                        <RecordPerPage key="2" defaultValue={CredebtorsStore.per_page + ' per page'} onChange={CredebtorsStore.setPageSize} />,
                        <Button key="3" title="Reset" onClick={CredebtorsStore.handleReset}><FontAwesomeIcon icon={faUndo} /></Button>,
                        <EnterFullscreen key='4' />
                    ]}
                >
                    <ListComponent openEditCredebtorDrawer={openEditCredebtorDrawer} openDeleteCredebtorDrawer={openDeleteCredebtorDrawer} openViewCredebtorDrawer={openViewCredebtorDrawer} />
                    <AddComponent visible={credebtorDrawer} close={closeCredebtorDrawer} />
                    <EditComponent visible={editCredebtorDrawer} close={closeEditCredebtorDrawer} />
                    <DeleteComponent visible={deleteCredebtorDrawer} close={closeDeleteCredebtorDrawer} />
                    <ViewComponent visible={viewCredebtorDrawer} close={closeViewCredebtorDrawer} viewType={viewType} />
                </PageHeader>
            )}
        </>
    )
})

export default Credebtors