import React, { useEffect } from 'react'
import { observer } from 'mobx-react'
import { Col, Divider, Form, Row, Spin } from 'antd';
import InputComponent from '../../../../components/InputComponent';
import useStore from '../../../../store';
import { vsmTransaction } from '../../../../config/Originator/AppsMessage';

const FormComponent = observer(({ form, setDisabled, fetchCurrency, setFetchCurrency, vatRateLoader, setVatRateLoader, credebtorLoader, setCredebtorLoader, tradeLoader, setTradeLoader, handleSubmit, csvColumns, setCsvColumns, type }) => {
	const { TransactionStore: { getCredebtorList, credebtorList, getTradeList, tradeList, getColumns, getCurrencyList, dropdown_currency_list, getVatRateList, vat_rate_list, viewValues } } = useStore()
	const handleChange = () => {
		form.validateFields().then((data) => {
			setDisabled(false);
		}).catch(() => {
			setDisabled(true);
		})
	}
	const onFinish = (data) => {
		handleSubmit(data)
	}

	const changeTradeItem = (e) => {
		if (e) {
			getColumns(e).then((data) => {
				setCsvColumns(data.data.field)
			})
		}
		handleChange()
	}

	useEffect(() => {
		form.setFieldsValue({ csv_columns: csvColumns })
	}, [form, csvColumns])

	return (
		<Form
			form={form}
			layout='vertical'
			id="transactionAddForm"
			onFinish={onFinish}
		>
			<Row gutter={[8, 8]}>
				<Col span={6}>
					<InputComponent
						required
						allowClear
						type='select'
						label="Credebtor Name"
						name="credebtor_id"
						placeholder="Credebtor Name"
						onFocus={() => (credebtorLoader && getCredebtorList().then(() => setCredebtorLoader(false)))}
						notFoundContent={credebtorLoader ? <Spin size="small" /> : "No Record Found."}
						options={{
							values: credebtorList || [{
								ledgers_role_mapping_id: viewValues && viewValues.credebtor_id && viewValues.credebtor_id,
								organisation_name: viewValues && viewValues.credebtor_name && viewValues.credebtor_name
							}],
							value_key: "ledgers_role_mapping_id",
							text_key: "organisation_name"
						}}
						tooltip="Name is one of the basic parameter of a Debtor or Creditor"
						autoComplete="off"
						rules={vsmTransaction.validation.credebtor_id}
						onChange={handleChange}
						disabled={type}
					/>
				</Col>

				<Col span={9}>
					<InputComponent
						required
						allowClear
						type="select"
						label="Trade Transaction"
						name="type_field_mapping_id"
						placeholder="Trade Transaction"
						onFocus={() => (tradeLoader && getTradeList().then(() => setTradeLoader(false)))}
						notFoundContent={tradeLoader ? <Spin size='small' /> : "No Record Found"}
						options={{
							values: tradeList || [{
								id: viewValues && viewValues.tte_type_field_mapping_id && viewValues.tte_type_field_mapping_id,
								mapping_name: viewValues && viewValues.tte_type_field_mapping_name && viewValues.tte_type_field_mapping_name
							}],
							value_key: "id",
							text_key: "mapping_name"
						}}
						tooltip="Select Trade Transaction which is combination of Trade Type and Transaction Type. It defines which fields and models will be generated on imported transactions"
						autoComplete="off"
						rules={vsmTransaction.validation.trade_transaction}
						onChange={(e) => changeTradeItem(e)}
						disabled={type}
					/>
				</Col>

				<Col span={5}>
					<InputComponent
						required
						allowClear
						type="select"
						label="Currency"
						name="currency_id"
						placeholder="Select Currency"
						onChange={handleChange}
						tooltip="Currency is a medium of exchange for goods and services. Setting Base currency will set this currency as default currency throughout Ledger."
						onFocus={() => (fetchCurrency && getCurrencyList().then(() => setFetchCurrency(false)))}
						notFoundContent={fetchCurrency ? <Spin size="small" /> : "No Record Found."}
						options={{
							values: dropdown_currency_list || [{
								id: viewValues && viewValues.currency_id && viewValues.currency_id,
								currency_name: viewValues && viewValues.currency_name && viewValues.currency_name
							}],
							value_key: "id",
							text_key: "currency_name"
						}}
						rules={vsmTransaction.validation.currency_id}
						disabled={type}
					/>
				</Col>

				<Col span={4}>
					<InputComponent
						required
						label="Face Value"
						name="face_value"
						placeholder="Face Value"
						onChange={handleChange}
						tooltip="Face Value is one of the fundamental attributes defining any Transaction, that stores a monetary value of the Transaction."
						rules={vsmTransaction.validation.face_value}
						maxLength={10}
						disabled={type}
					/>
				</Col>

				<Col span={4}>
					<InputComponent
						required
						allowClear
						type="select"
						label="Vat Rate"
						name="tax_settings_id"
						placeholder="Select Vat Rate"
						onChange={handleChange}
						tooltip="VAT is levied on sale of goods and services and paid by producers to the government, the actual tax is levied from customers or end users who purchase these goods and services."
						onFocus={() => (vatRateLoader && getVatRateList().then(() => setVatRateLoader(false)))}
						notFoundContent={vatRateLoader ? <Spin size="small" /> : "No Record Found."}
						options={{
							values: vat_rate_list || [{
								id: viewValues && viewValues.ledger_tax_settings_id && viewValues.ledger_tax_settings_id,
								tax_name: viewValues && viewValues.tax_name && viewValues.tax_name
							}],
							value_key: "id",
							text_key: "tax_name"
						}}
						rules={vsmTransaction.validation.vat_rate}
						disabled={type}
					/>
				</Col>

				<Col span={6}>
					<InputComponent
						className='date_wrapper'
						required
						type='date'
						name='posted_on'
						label='Posted Date (YYYY/MM/DD)'
						placeholder='Select date'
						onChange={handleChange}
						tooltip="The posted date is the day, month, and year when the trade transaction is Posted."
						rules={vsmTransaction.validation.posted_date}
						disabled={type}
					/>
				</Col>

				<Col span={6}>
					<InputComponent
						className='date_wrapper'
						required
						type='date'
						name='traded_on'
						label='Traded Date (YYYY/MM/DD)'
						placeholder='Select date'
						onChange={handleChange}
						tooltip="The traded date is the day, month, and year when the trade transaction is Traded."
						rules={vsmTransaction.validation.traded_date}
						disabled={type}
					/>
				</Col>

				<Divider />

				{csvColumns && csvColumns.length ? (
					<Form.List name='csv_columns'>
						{
							(fields, { add, remove }) => {
								return (
									<Row gutter={24}>
										{
											fields && fields.map((field, index) => {
												return (
													<Col span={6} key={index}>
														<InputComponent
															required={csvColumns[field.name].is_required}
															label={csvColumns[field.name].field_name}
															key={[field.key, "default_value"]}
															name={[field.name, "default_value"]}
															placeholder={csvColumns[field.name].field_name}
															disabled={type}
														/>
													</Col>
												)
											})
										}
									</Row>
								)
							}
						}
					</Form.List>
				) : null}
			</Row>
		</Form >
	)
})

export default FormComponent