import React from "react";
import { observer } from "mobx-react";
import { AgGridReact } from '@ag-grid-community/react'
import { AllModules } from "@ag-grid-enterprise/all-modules"
import { ActionRenderer } from './GridComponent'
import GridConfig from "../../../../config/GridConfig";
import { vsmCommon } from "../../../../config/Originator/AppsMessage";
import useStore from "../../../../store";
import { DateComparator } from "../../../../utils/GlobalFunction";

const ListComponent = observer((props) => {
    const { CredebtorsStore } = useStore()
    const { openEditCredebtorDrawer, openDeleteCredebtorDrawer, openViewCredebtorDrawer } = props

    const gridOptions = {
        columnDefs: [{
            headerName: '# ID',
            field: 'entity_id',
            lockPosition: 'left',
            filter: 'agNumberColumnFilter',
            pinned: 'left',
            width: 80
        }, {
            headerName: 'Credebtor Name',
            field: 'organisation.organisation_name',
            tooltipField: 'Credebtor Name',
            width: 185
        }, {
            headerName: 'Trade Name',
            field: 'organisation.trade_name',
            tooltipField: 'Trade Name',
            width: 185
        }, {
            headerName: 'Role',
            field: 'role.role_name',
            tooltipField: 'Role',
            filter: 'agSetColumnFilter',
            filterParams: {
                defaultToNothingSelected: true,
                buttons: ['apply', 'reset'],
                values: ['Debtor', 'Creditor']
            },
            width: 130
        }, {
            headerName: 'Registration Number',
            field: 'organisation.registration_number',
            tooltipField: 'Registration Number',
            width: 185
        }, {
            headerName: 'Country',
            field: 'organisation.global_countries.country_name',
            tooltipField: 'Country',
            filter: 'agSetColumnFilter',
            filterParams: {
                defaultToNothingSelected: true,
                buttons: ['apply', 'reset'],
                values: (params) => {
                    CredebtorsStore.getAllCountries().then((data) => {
                        params.success([...data.data.map(x => x.country_name)]);
                    })
                }
            },
            width: 130
        }, {
            headerName: 'Status',
            field: 'organisation.status.status_name',
            tooltipField: 'Status',
            width: 130
        }, {
            headerName: 'Last Updated On',
            field: 'updated_at',
            valueGetter: (params) => (params.data && params.data.organisation) ? params.data.organisation.updated_at : null,
            tooltipField: 'Last Updated On',
            filter: 'agDateColumnFilter',
            filterParams: {
                buttons: ['reset'],
                inRangeInclusive: true,
                suppressAndOrCondition: true,
                comparator: DateComparator
            },
            width: 185
        }, {
            headerName: 'Actions',
            lockPosition: 'right',
            field: 'actions',
            type: 'actionColumn',
            minWidth: 100,
            filter: false,
            sortable: false,
            pinned: 'right',
            width: 100
        }]
    }

    let columns = []
    let columnConfig = localStorage.getItem('Credebtors_grid')

    if (columnConfig) {
        let data = JSON.parse(columnConfig)
        let cols = gridOptions.columnDefs.find(x => !x.field)
        if (cols) {
            columns.push(cols)
        }
        data.forEach(element => {
            cols = gridOptions.columnDefs.find(x => x.field === element.colId)
            if (cols) {
                columns.push(cols)
            }
        });
    } else {
        columns = gridOptions.columnDefs
    }

    return (
        <div className="ag-theme-alpine grid_wrapper">
            <AgGridReact
                modules={AllModules}
                columnDefs={columns}
                defaultColDef={GridConfig.defaultColDef}
                columnTypes={GridConfig.columnTypes}
                overlayNoRowsTemplate={vsmCommon.noRecord}
                frameworkComponents={{
                    ActionRenderer, openEditCredebtorDrawer, openDeleteCredebtorDrawer, openViewCredebtorDrawer
                }}
                onGridReady={CredebtorsStore.setupGrid}
                onColumnResized={CredebtorsStore.onGridChanged}
                onColumnMoved={CredebtorsStore.onGridChanged}
                onColumnPinned={CredebtorsStore.onGridChanged}
                gridOptions={GridConfig.options}
                suppressDragLeaveHidesColumns={true}
            />
        </div>
    )
})

export default ListComponent