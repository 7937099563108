import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Button, Card, Checkbox, Col, Collapse, Drawer, Form, message, Row, Spin, Upload } from 'antd';
import debounce from 'lodash/debounce'
import PhoneElement from '../elements/PhoneElement';
import EmailElement from '../elements/EmailElement';
import AddressElement from '../elements/AddressElement';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import InputComponent from '../../../../components/InputComponent';
import { vsmNotify, vsmProfile } from '../../../../config/Originator/AppsMessage';
import useStore from '../../../../store';
import { defaultAPIURL } from '../../../../utils/GlobalFunction';
const { Panel } = Collapse;

const EditComponent = observer((props) => {
    const [form] = Form.useForm()
    const { UserProfileStore, AppsAuthStore } = useStore()
    const [isDisabled, setDisabled] = useState(true)
    const [saving, setSaving] = useState(false)
    const [fetchCountry, setFetchCountry] = useState(true)
    const [fetctNace, setFetchNace] = useState(true)
    const [fileList, updateFileList] = useState([]);
    const [isChecked, setChecked] = useState(false)
    const [imgchanged, setImgchanged] = useState(false)
    const [naceCode, setNaceCode] = useState(null)


    const changeNACE = (e) => {
        const naceCode = UserProfileStore.naceCode_list.find(list => list.id === e)
        setNaceCode(naceCode.nace_section)
        form.resetFields(['nace_section_id'])
    }

    const handleSubmit = (data) => {
        if (data.phone && data.phone.length > 0) {
            data.phone = data.phone.filter(x => x && (x.contact_for && x.contact_value))
            data.phone = data.phone.length > 0 ? JSON.stringify(data.phone) : "[]"
        }
        if (data.email && data.email.length > 0) {
            data.email = data.email.filter(x => x && (x.contact_for && x.contact_value))
            data.email = data.email.length > 0 ? JSON.stringify(data.email) : "[]"
        }
        if (data.addresses && data.addresses.length > 0) {
            data.addresses.forEach((item) => {
                item.city = item.city ? item.city : null
                item.address_type = item.address_type ? item.address_type : null
                item.address_1 = item.address_1 ? item.address_1 : null
                item.address_2 = item.address_2 ? item.address_2 : null
                item.address_3 = item.address_3 ? item.address_3 : null
                item.state_county = item.state_county ? item.state_county : null
                item.postal_code = item.postal_code ? item.postal_code : null
            })
            data.addresses = data.addresses.filter(x => x && x)
            data.addresses = data.add_address && data.addresses.length > 0 ? JSON.stringify(data.addresses) : "[]"
        }
        setSaving(true)
        var formData = UserProfileStore.JsonToFormData(data)
        if (fileList.length > 0 && fileList[0] && imgchanged) {
            formData.append('logo_url', fileList[0].hasOwnProperty("originFileObj") ? fileList[0].originFileObj : fileList[0])
        } else if (fileList.length === 0) {
            formData.append('logo_status', 'deleted')
        }
        UserProfileStore.UpdateProfileDetails(formData, AppsAuthStore.originator_user.id).then(() => {
            close()
            vsmNotify.success({
                message: vsmProfile.edit
            })
            AppsAuthStore.initiatAppOptions()
        }).catch(e => {
            if (e.errors) { form.setFields(e.errors) }
        }).finally(() => setSaving(false))
    }

    // check for valid form values then accordingly make save button disable/enable
    const handleChange = debounce(() => {
        form.validateFields().then((data) => {
            let checkbox = form.getFieldValue("add_address")
            let address = form.getFieldValue("addresses")
            address = address && address.filter(x => x && x)
            if (checkbox && (!address || (address && address.length === 0))) {
                setDisabled(true)
            } else {
                setDisabled(false)
            }
        }).catch(e => {
            if (e.errorFields && e.errorFields.length > 0) {
                setDisabled(true)
            }
        })
    }, 200)

    // reset form and close edit form
    const close = () => {
        form.resetFields()
        setDisabled(true)
        setFetchNace(true)
        setImgchanged(false)
        setFetchCountry(true)
        setFetchNace(true)
        updateFileList([])
        setChecked(false)
        setImgchanged(false)
        setNaceCode(null)
        UserProfileStore.dropdown_countries_list = null
        props.close()
    }

    const onCountryChange = (e) => {
        localStorage.setItem('local_apps_org_country', JSON.stringify(e))
    }

    const handleFileChange = (info) => {
        if (info.file.status === 'uploading') {
            return;
        }
    }

    const eventProps = {
        onChange: debounce(({ fileList: newFileList }) => {
            updateFileList(newFileList);
            handleFileChange(newFileList);
        }, 500),
        fileList,
        beforeUpload: file => {
            let isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png';
            let isLt1M = file.size / 1024 / 1024 < 1;

            if (!isJpgOrPng) {
                message.error(`Upload valid image. Only JPG, JPEG or PNG are allowed.`);
                return true;
            } else if (!isLt1M) {
                message.error('File size should not be more than 500px x 500px and 1 MB');
                return true;
            }
            else {
                setImgchanged(true)
                if (file.type.includes("image")) {
                    [file][0]["url"] = URL.createObjectURL(file);
                }
                updateFileList([file])
                return false
            }
        }
    };

    // Handle on remove image
    const onRemoveImage = () => {
        setImgchanged(false)
        form.resetFields(["logo_url"])
        updateFileList([]);
        handleChange();
    }

    // check whether address checkbox is checked or not
    const addAddress = async (e) => {
        var temp = localStorage.getItem('local_apps_org_country')
        if (!e.target.checked) {
            form.resetFields(["postal_code", "address_1", "address_2", "address_3", "city", "state_county"])
        }
        form.setFieldsValue({ addresses: e.target.checked ? [temp ? { country_id: parseInt(temp) } : null] : null })
        if (fetchCountry) {
            UserProfileStore.getAllCountries().then(() => setFetchCountry(false))
        }
        await setChecked(e.target.checked)
        handleChange()
    }

    useEffect(() => {
        if (props.visible && UserProfileStore.profileDetails) {
            // UserProfileStore.naceCode_list = UserProfileStore.profileDetails.orgnisation && UserProfileStore.profileDetails.orgnisation.global_nace_sections && [UserProfileStore.profileDetails.orgnisation.global_nace_sections.get_nace_category]
            setChecked(UserProfileStore.profileDetails.orgnisation.mutiple_addresses.length > 0 ? true : false)
        }
    }, [UserProfileStore, props.visible])

    useEffect(() => {
        if (props.visible && UserProfileStore.profileDetails) {
            updateFileList(UserProfileStore.profileDetails.orgnisation.logo_url ? ([
                {
                    uid: '-1',
                    name: 'image.png',
                    status: 'done',
                    url: defaultAPIURL + UserProfileStore.profileDetails.orgnisation.logo_url,
                },
            ]) : [])
            form.setFieldsValue({
                first_name: UserProfileStore.profileDetails.first_name,
                last_name: UserProfileStore.profileDetails.last_name,
                organisation_name: UserProfileStore.profileDetails.orgnisation.organisation_name,
                registration_number: UserProfileStore.profileDetails.orgnisation.registration_number,
                vat_number: UserProfileStore.profileDetails.orgnisation.vat_number,
                website: UserProfileStore.profileDetails.orgnisation.website,
                nace_section: UserProfileStore.profileDetails.orgnisation.global_nace_sections.get_nace_category.id,
                nace_section_id: UserProfileStore.profileDetails.orgnisation.global_nace_sections.id,
                registered_country_id: UserProfileStore.profileDetails.orgnisation.registered_country_id,
                phone: UserProfileStore.profileDetails.orgnisation.multiple_phone.length > 0 ? UserProfileStore.profileDetails.orgnisation.multiple_phone : UserProfileStore.initialValues.phone,
                email: UserProfileStore.profileDetails.orgnisation.mutiple_email.length > 0 ? UserProfileStore.profileDetails.orgnisation.mutiple_email : UserProfileStore.initialValues.email,
                add_address: (UserProfileStore.profileDetails.orgnisation.mutiple_addresses.length > 0 ? true : false),
                addresses: UserProfileStore.profileDetails.orgnisation.mutiple_addresses.length > 0 ? UserProfileStore.profileDetails.orgnisation.mutiple_addresses : null
            })
        }
    }, [props.visible, UserProfileStore.profileDetails, form, UserProfileStore.initialValues.phone, UserProfileStore.initialValues.email])

    return (
        <Drawer
            visible={props.visible}
            onClose={close}
            placement='right'
            width={'50%'}
            destroyOnClose={true}
            zIndex={AppsAuthStore.list_fullscreen ? 1011 : 1001}
            title='Edit Profile'
            footer={[
                <div className="text-center" key={1}>
                    <Button form='editform' className="mr-10" loading={saving} htmlType="submit" type="primary" disabled={isDisabled}>Save</Button>
                    <Button onClick={close}>Cancel</Button>
                </div>
            ]}
        // style={{ left: AppsAuthStore.list_fullscreen ? '0px' : '280px' }}
        >
            <Form
                form={form}
                id="editform"
                onFinish={handleSubmit}
                // onChange={handleChange}
                layout='vertical'
                initialValues={UserProfileStore.initialValues}
            >
                <Row gutter={[8, 8]} key={1}>
                    <Col span={5}>
                        <InputComponent
                            required
                            label="First Name"
                            name="first_name"
                            placeholder="First Name"
                            onChange={handleChange}
                            tooltip="A personal Name that is given to user/ person at birth or baptism and used before a family name."
                            rules={vsmProfile.validation.first_name}
                            maxLength={100}
                        />
                    </Col>

                    <Col span={5}>
                        <InputComponent
                            label="Last Name"
                            name="last_name"
                            placeholder="Last Name"
                            onChange={handleChange}
                            tooltip="It is the Name of the family to which a person/ user is born."
                            rules={vsmProfile.validation.last_name}
                            maxLength={100}
                        />
                    </Col>

                    <Col span={5}>
                        <InputComponent
                            required
                            label="Organisation Name"
                            name="organisation_name"
                            placeholder="Organisation Name"
                            onChange={handleChange}
                            tooltip="Name is one of the basic parameters of an Organisation, describing primary name of a given Organisation (usually it's the Company's legal name)."
                            rules={vsmProfile.validation.organisation_name}
                            maxLength={100}
                        />
                    </Col>

                    <Col span={5}>
                        <InputComponent
                            required
                            allowClear
                            type="select"
                            label="Registration Country"
                            name="registered_country_id"
                            placeholder="Select Registration Country"
                            onChange={(e) => {
                                handleChange()
                                onCountryChange(e)
                            }}
                            options={{
                                values: UserProfileStore.dropdown_countries_list
                                    || (
                                        UserProfileStore.profileDetails && UserProfileStore.profileDetails.orgnisation && UserProfileStore.profileDetails.orgnisation.global_countries && [{
                                            id: UserProfileStore.profileDetails.orgnisation.global_countries.id,
                                            country_name: UserProfileStore.profileDetails.orgnisation.global_countries.country_name,
                                        }]
                                    ),
                                value_key: 'id',
                                text_key: 'country_name'
                            }}
                            tooltip="A country is a political state, nation, or territory which is controlled."
                            rules={vsmProfile.validation.registered_country_id}
                            onFocus={() => fetchCountry && UserProfileStore.getAllCountries().then(() => setFetchCountry(false))}
                            notFoundContent={fetchCountry ? <Spin size="small" /> : "No Record Found."}
                        />
                    </Col>

                    <Col span={4}>
                        <InputComponent
                            label="Registration Number"
                            name="registration_number"
                            placeholder="Registration Number"
                            onChange={handleChange}
                            tooltip="A number appointed by relevant Company Registration Authorities in a country the organisation was founded in."
                            rules={vsmProfile.validation.registration_number}
                            maxLength={100}
                        />
                    </Col>
                </Row>

                <Row gutter={[8, 8]}>
                    <Col span={6}>
                        <InputComponent
                            label="VAT Number"
                            name="vat_number"
                            placeholder="VAT Number"
                            onChange={handleChange}
                            tooltip="A number appointed by relevant Tax Authorities in a country the organisation was founded in."
                            rules={vsmProfile.validation.vat_number}
                        />
                    </Col>

                    <Col span={6}>
                        <InputComponent
                            label="Website"
                            name="website"
                            placeholder="Website URL"
                            onChange={handleChange}
                            tooltip="A website is a collection of web pages and related content that is identified by a common domain name and published on at least one web server."
                            rules={vsmProfile.validation.website}
                        />
                    </Col>

                    <Col span={6}>
                        <InputComponent
                            required
                            allowClear
                            type="select"
                            label="NACE Category"
                            name="nace_section"
                            placeholder="Select NACE Category"
                            onChange={(e) => { handleChange(); changeNACE(e) }}
                            tooltip="NACE (Nomenclature of Economic Activities) is the European statistical classification of economic activities. NACE groups organisations according to their business activities."
                            onFocus={() => (fetctNace && UserProfileStore.getNaceCode().then(() => setFetchNace(false)))}
                            notFoundContent={fetctNace ? <Spin size="small" /> : "No Record Found."}
                            options={{
                                values: UserProfileStore.naceCode_list || [{
                                    id: UserProfileStore && UserProfileStore.profileDetails && UserProfileStore.profileDetails.orgnisation && UserProfileStore.profileDetails.orgnisation.global_nace_sections && UserProfileStore.profileDetails.orgnisation.global_nace_sections.get_nace_category && UserProfileStore.profileDetails.orgnisation.global_nace_sections.get_nace_category.id,
                                    category_name: UserProfileStore && UserProfileStore.profileDetails && UserProfileStore.profileDetails.orgnisation && UserProfileStore.profileDetails.orgnisation.global_nace_sections && UserProfileStore.profileDetails.orgnisation.global_nace_sections.get_nace_category && UserProfileStore.profileDetails.orgnisation.global_nace_sections.get_nace_category.category_name
                                }],
                                value_key: "id",
                                text_key: "category_name"
                            }}
                        />
                    </Col>

                    <Col span={6}>
                        <InputComponent
                            required
                            allowClear
                            type="select"
                            label="NACE Code"
                            name="nace_section_id"
                            placeholder="Select NACE Code"
                            onChange={handleChange}
                            rules={vsmProfile.validation.nace_code}
                            tooltip="NACE (Nomenclature of Economic Activities) is the European statistical classification of economic activities. NACE groups organisations according to their business activities."
                            options={{
                                values: naceCode || [{
                                    id: UserProfileStore && UserProfileStore.profileDetails && UserProfileStore.profileDetails.orgnisation && UserProfileStore.profileDetails.orgnisation.global_nace_sections && UserProfileStore.profileDetails.orgnisation.global_nace_sections.id,
                                    code: UserProfileStore && UserProfileStore.profileDetails && UserProfileStore.profileDetails.orgnisation && UserProfileStore.profileDetails.orgnisation.global_nace_sections && UserProfileStore.profileDetails.orgnisation.global_nace_sections.code,
                                    section: UserProfileStore && UserProfileStore.profileDetails && UserProfileStore.profileDetails.orgnisation && UserProfileStore.profileDetails.orgnisation.global_nace_sections && UserProfileStore.profileDetails.orgnisation.global_nace_sections.section
                                }],
                                value_key: "id",
                                text_key: { key: ["code", " - ", "section"] }
                            }}
                            disabled={naceCode ? false : true}
                        />
                    </Col>
                </Row>

                <Row gutter={[8, 8]}>
                    <Col span={10}>
                        <Form.Item label="Phone" className="mb-10" tooltip='Phone number can be stored with country code and extension numbers.'>
                            <PhoneElement onChange={handleChange} form={form} />
                        </Form.Item>
                    </Col>

                    <Col span={10}>
                        <Form.Item label="Email" className="mb-10" tooltip='Messages distributed by electronic means from one computer user to one or more recipients via a network.'>
                            <EmailElement onChange={handleChange} form={form} />
                        </Form.Item>
                    </Col>

                    <Col span={4}>
                        <Form.Item label="Logo" tooltip={"Logo is a symbol or other small design adopted by an organisation to identify its products."} extra={[<span key="1" style={{ fontSize: "12px" }}>Only JPG or PNG is allowed, Maximum size : 500px x 500px</span>]}>
                            <Form.Item noStyle name="logo_url">
                                <Upload
                                    accept=".png, .jpg, .jpeg"
                                    fileList={fileList}
                                    className="hideeyeicon"
                                    listType="picture-card"
                                    multiple={false}
                                    showUploadList={true}
                                    {...eventProps}
                                    onChange={(e) => { handleFileChange(e); handleChange() }}
                                    onRemove={onRemoveImage}
                                >
                                    {fileList.length >= 1 ? null :
                                        <div>
                                            <PlusOutlined />
                                        </div>}
                                </Upload>
                            </Form.Item>
                        </Form.Item>
                    </Col>
                </Row>

                <Form.Item className="mb-0" wrapperCol={{ offset: 8 }}>
                    <Form.Item name="add_address" valuePropName="checked">
                        <Checkbox onChange={addAddress}>Add Address</Checkbox>
                    </Form.Item>
                </Form.Item>

                <Collapse
                    className="site-collapse-custom-collapse"
                    expandIconPosition='right'
                    expandIcon={({ isActive }) => isActive ? <MinusOutlined /> : <PlusOutlined />}
                    bordered={false}
                    defaultActiveKey={['1']}
                // defaultActiveKey={(UserProfileStore.profileDetails.orgnisation.mutiple_addresses.length > 0 || form.getFieldValue('add_address')) ? ['1'] : ['0']}
                >
                    <Panel header="Address Details" key="1">
                        <Card title="Address Details:" hidden={!isChecked}>
                            <AddressElement onChange={handleChange} fetchCountry={fetchCountry} setFetchCountry={setFetchCountry} addAddress={addAddress} />
                        </Card>
                    </Panel>
                </Collapse>

            </Form>
        </Drawer>
    );
})

export default EditComponent;