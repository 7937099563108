import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { Button, Form, Input, Popover } from "antd";
import { Link, useHistory } from "react-router-dom";
import useStore from "../../store";
import { vsmChangePass, vsmNotify } from "../../config/Originator/AppsMessage";
import { IoIosLock, IoIosEye, IoIosEyeOff } from "react-icons/io";

const ResetPassword = observer((props) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const { AppsAuthStore } = useStore();
    const [tooltip, setTooltip] = useState(false);
    let history = useHistory();

    // make function call to reset password
    const resetPassword = (data) => {
        setLoading(true);
        AppsAuthStore.doAppsResetPass(data)
            .then(() => {
                history.push("/");
                vsmNotify.success({
                    message: vsmChangePass.add,
                });
            })
            .catch((errors) => {
                form.setFields(errors.errors);
                setLoading(false);
            });
    };

    // password criteria tool tip
    const passwordTooltip = (
        <div>
            <div>at least 1 numeric character</div>
            <div>at least 1 special character</div>
            <div>at least 1 uppercase letter</div>
            <div>at least 8 character</div>
        </div>
    );

    // handle password tool tip visiblility
    const handleChangePassword = (e) => {
        form
            .validateFields(["password"])
            .then(() => {
                setTooltip(false);
            })
            .catch(() => {
                setTooltip(true);
            });
    };

    useEffect(() => {
        if (AppsAuthStore.theme_type === "1") {
            document.documentElement.style.setProperty("--primary", "#2E4369");
            document.documentElement.style.setProperty("--btn_border_radius", "0");
            document.documentElement.style.setProperty("--primaryBG", "#2E4369");
            document.documentElement.style.setProperty("--btn_font_color", "#ffffff");
        } else if (AppsAuthStore.theme_type === "2") {
            document.documentElement.style.setProperty("--primary", "#FF8400");
            document.documentElement.style.setProperty("--btn_border_radius", "30px");
            document.documentElement.style.setProperty("--primaryBG", "#FF8400");
            document.documentElement.style.setProperty("--btn_font_color", "#ffffff");
        } else if (AppsAuthStore.theme_type === "3") {
            document.documentElement.style.setProperty("--btn_border_radius", "0");
            document.documentElement.style.setProperty(
                "--primary",
                `linear-gradient(270deg, #A79765 0%, #EADFA7 51.55%, #DABC6F 100%)`
            );
            document.documentElement.style.setProperty(
                "--primaryBG",
                `linear-gradient(270deg, #A79765 0%, #EADFA7 51.55%, #DABC6F 100%)`
            );
            document.documentElement.style.setProperty("--btn_font_color", "#000000");
        } else {
            document.documentElement.style.setProperty("--primary", "#2E4369");
            document.documentElement.style.setProperty("--btn_border_radius", "0");
            document.documentElement.style.setProperty("--primaryBG", "#2E4369");
            document.documentElement.style.setProperty("--btn_font_color", "#ffffff");
        }
    }, [AppsAuthStore.theme_type]);

    return (
        <div className={`main__reset_pass_wrapper`}>
            <div className="login_card_wrapper">
                <Form form={form} onFinish={resetPassword}>
                    {/* <Card title="Forget Password" className="w400"> */}
                    <Form.Item
                        name="Key"
                        initialValue={props.match.params.id}
                        style={{ display: "none" }}
                    >
                        <Input type="hidden" />
                    </Form.Item>
                    <Popover
                        placement="topRight"
                        content={passwordTooltip}
                        visible={tooltip}
                    >
                        <Form.Item
                            name="password"
                            rules={vsmChangePass.validation.new_password}
                            hasFeedback
                        >
                            <Input.Password
                                prefix={<IoIosLock style={{ color: AppsAuthStore.theme_type === '1' ? '#2E4369' : AppsAuthStore.theme_type === '2' ? '#FF8400' : AppsAuthStore.theme_type === '3' ? 'rgb(175 160 109)' : '#2E4369', fontSize: '30px' }} />}
                                className='input_wrapper'
                                placeholder="New Password"
                                onBlur={() => setTooltip(false)}
                                onChange={handleChangePassword}
                                onFocus={handleChangePassword}
                                iconRender={visible => (visible ? (<IoIosEye style={{ color: AppsAuthStore.theme_type === '1' ? '#2E4369' : AppsAuthStore.theme_type === '2' ? '#FF8400' : AppsAuthStore.theme_type === '3' ? 'rgb(175 160 109)' : '#2E4369', fontSize: '30px' }} />) : (<IoIosEyeOff style={{ color: AppsAuthStore.theme_type === '1' ? '#2E4369' : AppsAuthStore.theme_type === '2' ? '#FF8400' : AppsAuthStore.theme_type === '3' ? 'rgb(175 160 109)' : '#2E4369', fontSize: '30px' }} />))}
                            />
                        </Form.Item>
                    </Popover>
                    <Form.Item
                        name="confirm_password"
                        rules={vsmChangePass.validation.confirm_password}
                        hasFeedback
                    >
                        <Input.Password
                            prefix={<IoIosLock style={{ color: AppsAuthStore.theme_type === '1' ? '#2E4369' : AppsAuthStore.theme_type === '2' ? '#FF8400' : AppsAuthStore.theme_type === '3' ? 'rgb(175 160 109)' : '#2E4369', fontSize: '30px' }} />}
                            className='input_wrapper'
                            placeholder="Confirm Password"
                            onBlur={() => setTooltip(false)}
                            onChange={handleChangePassword}
                            iconRender={visible => (visible ? (<IoIosEye style={{ color: AppsAuthStore.theme_type === '1' ? '#2E4369' : AppsAuthStore.theme_type === '2' ? '#FF8400' : AppsAuthStore.theme_type === '3' ? 'rgb(175 160 109)' : '#2E4369', fontSize: '30px' }} />) : (<IoIosEyeOff style={{ color: AppsAuthStore.theme_type === '1' ? '#2E4369' : AppsAuthStore.theme_type === '2' ? '#FF8400' : AppsAuthStore.theme_type === '3' ? 'rgb(175 160 109)' : '#2E4369', fontSize: '30px' }} />))}
                        />
                    </Form.Item>
                    <div className="text-center">
                        <Button loading={loading} htmlType="submit" block type="primary" className={`login_button_wrapper theme_type${AppsAuthStore.theme_type}`}>
                            Reset Password
                        </Button>
                    </div>
                    <div className="d-flex justify-content-end">
                        <Link to="/" type="link" className="p-0 mt-10">
                            <b>Back to login</b>
                        </Link>
                    </div>
                    {/* </Card> */}
                </Form>
            </div>
        </div>
    );
});

export default ResetPassword;
