import React from 'react'
import { AgGridReact } from '@ag-grid-community/react'
import { AllModules } from "@ag-grid-enterprise/all-modules"
import { observer } from 'mobx-react'
import GridConfig from '../../../../config/GridConfig'
import { vsmCommon } from '../../../../config/Originator/AppsMessage'
import useStore from '../../../../store'
import moment from 'moment'
import { Button } from 'antd'
import { EyeOutlined } from '@ant-design/icons'

const ListComponent = observer((props) => {
	const { openViewTransactionDrawer } = props
	const { AppsAuthStore, TransactionStore } = useStore()
	const ActionRenderer = (props) => {
		return (
			<div className="action-column">
				{AppsAuthStore.permissions && /* AppsAuthStore.checkPermission(1005, 'show') && */
					<Button title="View Trade" type="text" onClick={() => { openViewTransactionDrawer(props.data.id) }}><EyeOutlined /></Button>
				}
			</div>
		)
	}

	const gridOptions = {
		columnDefs: [{
			headerName: 'Created',
			field: 'created_at',
			filter: 'agDateColumnFilter',
			cellRendererFramework: function (data) {
				return (
					<span>{moment(data.data.value).format('YYYY-MM-DD')}</span>
				)
			}
		}, {
			headerName: '# ID',
			field: 'credebtor_id',
			tooltipField: 'credebtor_id',
			filter: 'agNumberColumnFilter',
			width: 100
		}, {
			headerName: 'Credebtor Name',
			field: 'credebtor_name',
			tooltipField: 'credebtor_name',
			width: 185,
		}, {
			headerName: 'Type',
			field: 'type_name',
			tooltipField: 'type_name',
			width: 100,
			filter: 'agSetColumnFilter',
			// filterParams: {
			// 	defaultToNothingSelected: true,
			// },
		}, {
			headerName: 'Transaction Type',
			field: 'tte_type_field_mapping_name',
			tooltipField: 'tte_type_field_mapping_name',
		}, {
			headerName: 'Currency',
			field: 'currency_code',
			tooltipField: 'currency_code',
			width: 100,
			// filter: 'agSetColumnFilter',
			// filterParams: {
			// 	defaultToNothingSelected: true,
			// },
		}, {
			headerName: 'Amount',
			field: 'face_value',
			tooltipField: 'face_value',
			width: 100,
			type: 'rightAligned'
		}, {
			headerName: 'Actions',
			field: 'actions',
			type: 'actionColumn',
			minWidth: 100,
			filter: false,
			sortable: false,
			pinned: 'right',
			lockPosition: 'right',
			width: 100
		}]
	}

	let columns = []
	let columnConfig = localStorage.getItem('transaction_grid')
	if (columnConfig) {
		let data = JSON.parse(columnConfig)
		let cols = gridOptions.columnDefs.find(x => !x.field)
		if (cols) {
			columns.push(cols)
		}
		data.forEach(element => {
			cols = gridOptions.columnDefs.find(x => x.field === element.colId)
			if (cols) {
				columns.push(cols)
			}
		});
	} else {
		columns = gridOptions.columnDefs
	}
	return (
		<div className="ag-theme-alpine grid_wrapper">
			<AgGridReact
				modules={AllModules}
				columnDefs={columns}
				defaultColDef={GridConfig.defaultColDef}
				columnTypes={GridConfig.columnTypes}
				overlayNoRowsTemplate={vsmCommon.noRecord}
				frameworkComponents={{
					ActionRenderer, openViewTransactionDrawer
				}}
				onGridReady={TransactionStore.setupGrid}
				onColumnResized={TransactionStore.onGridChanged}
				onColumnMoved={TransactionStore.onGridChanged}
				onColumnPinned={TransactionStore.onGridChanged}
				gridOptions={GridConfig.options}
				suppressDragLeaveHidesColumns={true}
			/>
		</div>
	)
})

export default ListComponent