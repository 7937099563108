import Axios from 'axios'
import { action, decorate, observable } from 'mobx'
import GridConfig from '../../config/GridConfig'
import { vsmNotify } from '../../config/Originator/AppsMessage'
import { convertError } from '../../utils/GlobalFunction'

export default class TransactionStore {
    agGrid = null
    per_page = GridConfig.options.paginationPageSize
    current_page = 1
    total = 0
    transaction_list = null
    credebtorList = null
    tradeList = null
    fieldsColumn = null
    dropdown_currency_list = null
    vat_rate_list = null
    viewValues = null

    // change page size, default page size is GridConfig.options.paginationPageSize
    setPageSize = (page = GridConfig.options.paginationPageSize) => {
        this.per_page = page
        this.agGrid.api.paginationSetPageSize(parseInt(page))
    }

    handleReset = () => {
        localStorage.removeItem('transaction_grid')
        this.agGrid.api.setFilterModel(null)
        this.agGrid.api.setSortModel(null)
        this.agGrid.columnApi.resetColumnState()
        this.agGrid.api.onFilterChanged(null)
    }

    // Set column width after resizing colums
    onGridChanged = (params) => {
        localStorage.setItem('transaction_grid', JSON.stringify(params.columnApi.getColumnState()))
    }

    // Setup grid and set column size to autosize
    setupGrid = (params) => {
        this.agGrid = params
        const { api } = params
        let datasource = this.createDatasource(GridConfig.options)
        api.setServerSideDatasource(datasource)
    }

    // Create data source to display record in table
    createDatasource = (gridOptions) => {
        return {
            gridOptions,
            getRows: (params) => {
                let columnConfig = localStorage.getItem('transaction_grid')
                if (columnConfig) {
                    this.onGridChanged(params)
                }
                let filter_data = { final_filter: params.request.filterModel, final_sort: params.request.sortModel }

                // if (filter_data.final_filter['etr_type']) {
                //     filter_data.final_filter['etr_type'].values = convertTextToID(filter_data.final_filter['etr_type'], this.etr_type, 'etr_type', 'id')
                // }

                let payload = {
                    filter_data: filter_data.final_filter,
                    sort_data: filter_data.final_sort,
                    per_page: params.request.endRow - params.request.startRow,
                    page: Math.ceil((params.request.startRow + 1) / (params.request.endRow - params.request.startRow)),
                }
                this.getList(payload).then((data) => {
                    if (data.total === 0) { this.agGrid.api.showNoRowsOverlay() }
                    else { this.agGrid.api.hideOverlay() }
                    params.successCallback(data.data, data.total)
                    let columnConfig = localStorage.getItem('transaction_grid')
                    if (this.agGrid && this.agGrid.columnApi && columnConfig) {
                        if (JSON.stringify(this.agGrid.columnApi.getColumnState()) !== columnConfig) {
                            this.agGrid.columnApi.applyColumnState({ state: JSON.parse(columnConfig) })
                        }
                    }
                })
            }
        }
    }

    getList = (payload = {}) => {
        return Axios.post(`/transactions/all-transactions`, payload).then(({ data }) => {
            this.transaction_list = data.data
            this.total = data.total
            this.current_page = data.current_page
            return data
        }).catch((err) => {
            this.transaction_list = null
            vsmNotify.error(err)
        })
    }

    getCredebtorList = () => {
        return Axios.get('get/credebtor').then(({ data }) => {
            this.credebtorList = data.data
            return data
        }).catch(() => {
            this.credebtorList = null
        })
    }

    getTradeList = (id) => {
        return Axios.get('/transactions/mapping/get').then(({ data }) => {
            this.tradeList = data.data
            return data
        }).catch((err) => {
            this.tradeList = null
            vsmNotify.error(err)
        })
    }

    getColumns = (id) => {
        return Axios.get(`transactions/modelandfield/get-csv-column/${id}`).then(({ data }) => {
            this.fieldsColumn = data.data
            return data
        }).catch((err) => {
            this.fieldsColumn = null
        })
    }

    // call for currency list
    getCurrencyList = () => {
        return Axios.get(`globalcurreny/ledger/currency`).then(({ data }) => {
            this.dropdown_currency_list = data.data
            return data
        }).catch((err) => {
            this.dropdown_currency_list = null
            vsmNotify.error(err)
        })
    }

    getVatRateList = () => {
        return Axios.get('transactions/vat-rate/get').then(({ data }) => {
            this.vat_rate_list = data.data
            return data
        }).catch((err) => {
            this.vat_rate_list = null
            vsmNotify.error(err)
        })
    }

    addTransaction = (formData) => {
        return Axios.post('/transactions/new-transaction', formData).then(({ data }) => {
            if (this.setupGrid) {
                this.setupGrid(this.agGrid)
            }
            return data
        }).catch(({ response: { data } }) => {
            var errors = [];
            Object.keys(data.errors).forEach(name => {
                errors.push({ name, errors: data.errors[name] })
            })
            data.errors = convertError(errors)
            return Promise.reject(data)
        })
    }

    readTransaction = (id) => {
        return Axios.get(`/transactions/all-transaction/read/${id}`).then(({ data }) => {
            this.viewValues = data.data
            return data
        }).catch((err) => {
            vsmNotify.error(err)
            this.viewValues = null
        })
    }

}

decorate(TransactionStore, {
    agGrid: observable,
    per_page: observable,
    current_page: observable,
    total: observable,
    transaction_list: observable,
    credebtorList: observable,
    tradeList: observable,
    fieldsColumn: observable,
    dropdown_currency_list: observable,
    vat_rate_list: observable,
    viewValues: observable,
    setPageSize: action,
    handleReset: action,
    onGridChanged: action,
    setupGrid: action,
    createDatasource: action,
    getList: action,
    getCredebtorList: action,
    getTradeList: action,
    getVatRateList: action,
    readTransaction: action
})