import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Card, Col, Form, message, Row, Spin, Upload } from 'antd';
import InputComponent from '../../../../../../components/InputComponent';
import { vsmRSAList } from '../../../../../../config/Originator/AppsMessage'
import useStore from '../../../../../../store';
import AddComponent from '../../../../Credebtors/components/AddComponent';
import { PercentageOutlined, InboxOutlined } from '@ant-design/icons';

const FormComponent = observer(({ form, handleSubmit, onChange, fetchDebtor, setFetchDebtor, fetchCurrency, setFetchCurrency, fileList, setFileList, visible }) => {
    const { TradeFloorStore, AppsAuthStore } = useStore()
    const [credebtorDrawer, setCredebtorDrawer] = useState(false)
    const [currency, setCurrency] = useState({})

    const onFinish = (data) => {
        handleSubmit(data)
    }

    const openCredebtor = () => {
        setCredebtorDrawer(true)
    }

    const closeCredebtorDrawer = () => {
        setCredebtorDrawer(false)
        setFetchDebtor(true)
        TradeFloorStore.debtor_list = null
    }

    const uploadPropsAttachInvoice = {
        name: "file",
        multiple: false,
        fileList: fileList,
    };

    const handleFileUploadChangeAttachInvoice = (info) => {
        const { file } = info;
        let nextState = [];
        let isCSV = file.type === "application/pdf";
        let isLt1M = file.size / 1024 / 1024 < 1;

        switch (info.file.status) {
            case "uploading":
                if (!isCSV) {
                    message.error(
                        `Attach Supporting Document must be in pdf format. File Size should be less then 1 MB.`
                    );
                    setFileList([]);
                    return true;
                } else if (!isLt1M) {
                    message.error(
                        "Attach Supporting Document must be in pdf format. File Size should be less then 1 MB."
                    );
                    setFileList([]);
                    return true;
                } else {
                    nextState = [info.file];
                }
                break;
            case "done":
                if (!isCSV) {
                    message.error(
                        `Attach Supporting Document must be in pdf format. File Size should be less then 1 MB.`
                    );
                    setFileList([]);
                    return true;
                } else if (!isLt1M) {
                    message.error(
                        "Attach Supporting Document must be in pdf format. File Size should be less then 1 MB."
                    );
                    setFileList([]);
                    return true;
                } else {
                    nextState = [info.file];
                }
                break;

            default:
                // error or removed
                nextState = [];
        }
        setFileList(nextState);
        onChange()
    };
    const dummyRequestAttachInvoice = ({ file, fileList, onSuccess }) => {
        setTimeout(() => {
            onSuccess("ok");
        }, 0);
    };

    const setDefaultValueFromDebtor = (e) => {
        if (TradeFloorStore.debtor_list && TradeFloorStore.debtor_list.length) {
            let value = TradeFloorStore.debtor_list.find(item => item.id === e)
            if (value) {
                form.setFieldsValue({
                    term: value.data && value.data.average_payment_cycle,
                    face_value: value.data && value.data.predicted_annual_revenue,
                    currency_id: value && value.currency_id,
                    etr_months: value.data && value.data.invoice_predicted_per_annum
                })
                setCurrency({
                    currency_id: value.currency_id,
                    currency_name: value.currency_name
                })
            }
        }
    }

    useEffect(() => {
        if (visible) {
            form.setFieldsValue({
                discount_price: AppsAuthStore.originator_user && AppsAuthStore.originator_user.agreed_rate,
                buyout_discount_price: AppsAuthStore.originator_user && AppsAuthStore.originator_user.agreed_rate
            })
        }
    }, [form, visible, AppsAuthStore.originator_user])

    return (
        <>
            <Form
                form={form}
                layout='vertical'
                id="RSAAddEditForm"
                onFinish={onFinish}
            >
                <Row gutter={10}>
                    <Col span={5}>
                        <InputComponent
                            required
                            allowClear
                            type="select"
                            label="Open RSA for"
                            name="debtor_id"
                            placeholder="Select RSA for"
                            onChange={(e) => {
                                setDefaultValueFromDebtor(e)
                                onChange()
                            }}
                            options={{
                                values: TradeFloorStore.debtor_list,
                                value_key: 'id',
                                text_key: 'organisation_name'
                            }}
                            tooltip="Select the Debtor that this Invoice is/has been issued too"
                            rules={vsmRSAList.validation.name}
                            onFocus={() => fetchDebtor && TradeFloorStore.getDebtorList('Debtor').then(() => setFetchDebtor(false))}
                            notFoundContent={fetchDebtor ? <Spin size="small" /> : "No Record Found."}
                            note={<div onClick={openCredebtor} key="1" className='extra_note' style={{ color: '#4FC6E0' }}>Add a new counterparty</div>}
                        />
                    </Col>
                    <Col span={5}>
                        <div className='suffixIcon'>
                            <InputComponent
                                required
                                name='term'
                                label="Payment Terms"
                                placeholder="Payment Terms"
                                tooltip="Enter a fixed value to indicate your Debtor’s standard payment terms (e.g 30, 60, 90 days, etc)"
                                onChange={onChange}
                                rules={vsmRSAList.validation.payment_terms}
                                maxLength={3}
                            />
                            <p style={{ marginTop: 30 }}>Days</p>
                        </div>
                    </Col>
                    <Col span={5}>
                        <InputComponent
                            required
                            allowClear
                            type="select"
                            label="Currency"
                            name="currency_id"
                            placeholder="Select Currency"
                            onChange={onChange}
                            tooltip="The currency is pre-set according to currency selection made when creating the Debtor and cannot be changed."
                            onFocus={() => (fetchCurrency && TradeFloorStore.getCurrencyList().then(() => setFetchCurrency(false)))}
                            notFoundContent={fetchCurrency ? <Spin size="small" /> : "No Record Found."}
                            options={{
                                values: TradeFloorStore.dropdown_currency_list || (currency && [{
                                    id: currency.currency_id,
                                    currency_name: currency.currency_name,
                                }]),
                                value_key: "id",
                                text_key: "currency_name"
                            }}
                            disabled
                        />
                    </Col>

                    <Col span={5}>
                        <InputComponent
                            required
                            name='face_value'
                            label="Total Face Value of ETR"
                            placeholder="Total Face Value of ETR"
                            onChange={onChange}
                            tooltip="Enter the total face value of all the Invoices/ETR you will issue to this Debtor during the number of months indicated in the Revolving Offer"
                            note='(the "Revolving ERT Limit")'
                            rules={vsmRSAList.validation.face_value}
                            maxLength={10}
                        />
                    </Col>

                    <Col span={4}>
                        <InputComponent
                            required
                            name='etr_no'
                            label="Number of ETR"
                            placeholder="Number of ETR"
                            onChange={onChange}
                            tooltip="Enter the number of Invoices/ETR you will issue to this Debtor during the number of months indicated in the Revolving Offer"
                            rules={vsmRSAList.validation.num_etr}
                            maxLength={3}
                        />
                    </Col>
                </Row>

                <Row gutter={10}>
                    <Col span={6}>
                        <InputComponent
                            required
                            name='etr_months'
                            label="Revolving Offer Months"
                            placeholder="Number of ETR"
                            onChange={onChange}
                            tooltip="Enter the number of months that this Revolving Sales Agreement (RSA) will remain in effect"
                            note='(the "Revolving ERT Period")'
                            rules={vsmRSAList.validation.offer_month}
                            maxLength={2}
                        />
                    </Col>
                    <Col span={9}>
                        <InputComponent
                            required
                            type="textarea"
                            name="desc"
                            label="Description"
                            placeholder="Add Description"
                            onChange={onChange}
                            maxLength={1000}
                            tooltip={`Enter information in this box that will help us understand your customer and/or the types of invoices you will issue to them`}
                            rules={vsmRSAList.validation.desc}
                        />
                    </Col>

                    <Col span={9}>
                        <Form.Item
                            required
                            label="Attach Supporting Document"
                            className="full-width-dragger"
                            name="file_support"
                            tooltip="If you have a contract, a letter, or any additional document to support the validity of the invoices you will be issuing to this Debtor, attach a PDF document here "
                            rules={vsmRSAList.validation.file_support}
                        >
                            <Upload.Dragger
                                {...uploadPropsAttachInvoice}
                                onChange={handleFileUploadChangeAttachInvoice}
                                customRequest={dummyRequestAttachInvoice}
                                fileList={fileList}
                            >
                                <p className="ant-upload-drag-icon">
                                    <InboxOutlined />
                                </p>
                                <p className="ant-upload-text">
                                    Drag your PDF file here or select by clicking here
                                </p>
                            </Upload.Dragger>
                        </Form.Item>
                    </Col>
                </Row>

                {/* <Divider className='devider_wrapper' /> */}

                <Card bordered={false} title='Your Trade Terms'>
                    <Row gutter={10}>
                        <Col span={4}>
                            <InputComponent
                                required
                                label="Monthly Discount"
                                name="discount_price"
                                placeholder="Monthly Discount"
                                onChange={onChange}
                                tooltip="The Monthly Discount Percentage is pre-set according to your RSA agreement with Credebt Exchange"
                                rules={vsmRSAList.validation.discount_price}
                                addonAfter={<PercentageOutlined />}
                                disabled
                            />
                        </Col>

                        <Col span={4}>
                            <InputComponent
                                required
                                label="Buy Out Discount"
                                name="buyout_discount_price"
                                placeholder="Buy Out Discount"
                                onChange={onChange}
                                tooltip="Enter a higher Monthly Discount Percentage than your current RSA agreement with Credebt Exchange for faster trading"
                                rules={vsmRSAList.validation.buyout_discount_price}
                                addonAfter={<PercentageOutlined />}
                                disabled
                            />
                        </Col>
                    </Row>
                </Card>
            </Form>
            <AddComponent visible={credebtorDrawer} close={closeCredebtorDrawer} />
        </>
    );
})

export default FormComponent;