import React, { useState } from 'react';
import { observer } from 'mobx-react';
import useStore from '../../../../store';
import { Button, Col, Form, Modal, Row } from 'antd';
import { vsmCredebtor, vsmNotify } from '../../../../config/Originator/AppsMessage';
import { WarningFilled } from '@ant-design/icons'

const DeleteComponent = observer((props) => {
    const [form] = Form.useForm()
    const { AppsAuthStore, CredebtorsStore } = useStore()
    const [saving, setSaving] = useState(false)

    const close = () => {
        props.close()
        form.resetFields()
        setSaving(false)
    }

    // Make function call to delete existing record
    const handleSubmit = () => {
        setSaving(true)
        CredebtorsStore.Deletedata(CredebtorsStore.delete_values.id).then(() => {
            close()
            vsmNotify.success({
                message: vsmCredebtor.delete
            })
        }).catch(e => {
            if (e.errors) { form.setFields(e.errors) }
        }).finally(() => {
            setSaving(false)
        })
    }

    return CredebtorsStore.delete_values && (
        <Modal
            centered
            title={`Delete Credebtors - #${CredebtorsStore.delete_values.id} - ${CredebtorsStore.delete_values.organisation.organisation_name}`}
            visible={props.visible}
            onCancel={close}
            cancelButtonProps={{ style: { display: 'none' } }}
            okButtonProps={{ style: { display: 'none' } }}
            zIndex={AppsAuthStore.list_fullscreen ? 1011 : 1001}
            footer={[
                <Button key="1" form='deleteform' loading={saving} htmlType="submit" type="primary" danger >Delete</Button>,
                <Button key="2" htmlType="button" onClick={close}>Cancel</Button>
            ]}
        >
            <Form form={form} id="deleteform" onFinish={handleSubmit}>
                {
                    <Row align="middle">
                        <Col span={4}><WarningFilled style={{ fontSize: 45, color: '#ff4d4f' }} /></Col>
                        <Col span={20}>
                            <h3>Are you sure you want to delete the Credebtor?</h3>
                        </Col>
                    </Row>
                }
            </Form>
        </Modal>
    );
})

export default DeleteComponent;