import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import useStore from '../../../../../store';
import { Button, Drawer, Spin } from 'antd';
import { defaultAPIURL, etr_type } from '../../../../../utils/GlobalFunction'
import EditComponent from './EditComponent';
import { EditOutlined } from '@ant-design/icons'
import moment from 'moment';

const ViewComponent = observer((props) => {
    const { TradeFloorStore, TradeFloorStore: { readETRvalue, getTermLoan }, AppsAuthStore } = useStore()
    const [editTradeDrawer, setEditTradeDrawer] = useState(false)
    const [loading, setLoading] = useState(true)
    const [subDrawer, setOpenSubDrawer] = useState(false)
    const [viewValue, setViewValue] = useState(true)
    const [subID, setSubId] = useState(null);

    const close = () => {
        TradeFloorStore.readETRvalue = null
        TradeFloorStore.apicalled = false
        props.close()
        setSubId(null)
    }

    const openEditTradeDrawer = () => {
        setEditTradeDrawer(true)
        getTermLoan(TradeFloorStore.readETRvalue?.etr_type)
        if (![1, 2, 5].includes(TradeFloorStore.readETRvalue?.etr_type)) {
            let payload = {
                debtor_id: TradeFloorStore.readETRvalue?.debtor?.id,
                etr_type: TradeFloorStore.readETRvalue?.etr_type,
                trade_id: TradeFloorStore.readETRvalue?.id
            }
            TradeFloorStore.getRemainingValues(payload)
        }
    }
    //close edit drawer
    const closeEditTradeDrawer = () => {
        setEditTradeDrawer(false)
        TradeFloorStore.readTrade(viewValue.id)
    }

    const openSubDrawer = (id) => {
        setOpenSubDrawer(true)
        TradeFloorStore.readETRvalue = null
        setSubId(id)
    }

    const closeSubDrawer = () => {
        TradeFloorStore.readETRvalue = null
        setSubId(null)
        setLoading(true)
        setOpenSubDrawer(false)
    }

    useEffect(() => {
        if (props.visible && !readETRvalue && !TradeFloorStore.apicalled) {
            TradeFloorStore.readTrade(subID ? subID : props.id).then((data) => {
                if (data) {
                    setViewValue(data);
                } else {
                    TradeFloorStore.apicalled = true; // Temp solution- infinte api call for waste ticket
                }
            })
        }
    }, [TradeFloorStore, props.id, readETRvalue, subID, props.visible, viewValue])

    useEffect(() => {
        if (viewValue) {
            setLoading(false)
        }
    }, [viewValue])

    return viewValue ? (
        <>
            <Drawer
                visible={props.visible}
                className='viewETR'
                onClose={close}
                placement='right'
                width={'50%'}
                destroyOnClose={true}
                zIndex={AppsAuthStore.list_fullscreen ? 1011 : 1001}
                title={`View a Trade #${viewValue && viewValue.id}`}
                closable={false}
                extra={
                    viewValue && viewValue.status === 1 && <Button onClick={openEditTradeDrawer}><EditOutlined /></Button>
                }
                footer={[
                    <div className="text-center" key={1}>
                        <Button onClick={close}>Cancel</Button>
                    </div>
                ]}
            >
                <Spin size="default" spinning={loading} style={{ height: '50%' }}>
                    {viewValue &&
                        <table cellPadding='0' cellSpacing='0' border='0'>
                            <tbody key="tbody">
                                {
                                    (viewValue.etr_type === 1 || viewValue.etr_type === 5) ?
                                        <>
                                            <tr>
                                                <td width="25%">ETR Type</td>
                                                <td width="25%">Select Creditor</td>
                                                <td width="25%">Batch No</td>
                                                <td width="25%">ETR Months</td>
                                            </tr>
                                            <tr>
                                                <th>{etr_type[viewValue.etr_type]}</th>
                                                <th>{viewValue.debtor.organisation_name}</th>
                                                <th>{viewValue.batch_no}</th>
                                                <th>{viewValue.etr_months}</th>
                                            </tr>

                                            <tr>
                                                <td>Currency</td>
                                                <td>Start Date (YYYY/MM/DD)</td>
                                                <td>End Date (YYYY/MM/DD)</td>
                                                <td>Purchase Date (YYYY/MM/DD)</td>
                                            </tr>
                                            <tr>
                                                <th>{viewValue.currency && viewValue.currency.currency_name}</th>
                                                <th>{viewValue.start_date}</th>
                                                <th>{viewValue.end_date}</th>
                                                <th>{moment(viewValue.purchase_date).format('YYYY-MM-DD')}</th>
                                            </tr>
                                            <tr>
                                                <td>Asset Name</td>
                                                <td>Asset Number</td>
                                                <td>Quantity Number</td>
                                                <td>Asset Classfication</td>
                                            </tr>
                                            <tr>
                                                <th>{viewValue.asset_name}</th>
                                                <th>{viewValue.asset_number}</th>
                                                <th>{viewValue.quantity_numbers}</th>
                                                <th >{viewValue.asset_classification}</th>
                                            </tr>
                                            <tr>
                                                <td>Sell Rate</td>
                                                <td>Value</td>
                                                <td>Supplier/Manufacturer</td>
                                                <td>Invoice File/Document</td>
                                            </tr>
                                            <tr>
                                                <th>{viewValue.a_f_sell_rate}</th>
                                                <th>{viewValue.face_value}</th>
                                                <th>{viewValue.supplier_manufacturer}</th>
                                                <th>{viewValue.file_support && <a target={`_blank`} href={defaultAPIURL + viewValue.file_support.url}>{viewValue.file_support.name}</a>}</th>
                                            </tr>
                                            <tr>
                                                <td colSpan={4}>Description</td>
                                            </tr>
                                            <tr>
                                                <th colSpan={4}>{viewValue.desc && viewValue.desc}</th>
                                            </tr>
                                        </>
                                        : (viewValue.etr_type === 2) ?
                                            <>
                                                <>
                                                    <tr>
                                                        <td width="25%">ETR Type</td>
                                                        <td width="25%">Expected Date (YYYY/MM/DD)</td>
                                                        <td width="25%">Friendly Name</td>
                                                        <td width="25%">Description</td>
                                                    </tr>
                                                    <tr>
                                                        <th>{etr_type[viewValue.etr_type]}</th>
                                                        <th>{viewValue.expected_date && viewValue.expected_date}</th>
                                                        <th>{viewValue.friendly_name && viewValue.friendly_name}</th>
                                                        <th>{viewValue.description && viewValue.description}</th>
                                                    </tr>
                                                    <tr>
                                                        <td width="25%">Attach Supporting Document</td>
                                                        <td width="25%">d-ETR Monthly Discount Percentage</td>
                                                        <td width="25%">c-ETR Sell Rate Percentage</td>
                                                    </tr>
                                                    <tr>
                                                        <th>{viewValue.file_support && <a target={`_blank`} href={defaultAPIURL + viewValue.file_support.url}>{viewValue.file_support.name}</a>}</th>
                                                        <th>{viewValue.d_etr_purchase_discount && viewValue.d_etr_purchase_discount}</th>
                                                        <th>{viewValue.c_etr_sell_rate && viewValue.c_etr_sell_rate}</th>
                                                    </tr>
                                                    <tr><td colSpan={4}>Po Details</td></tr>
                                                    <tr>
                                                        <th colSpan={4}>
                                                            {viewValue && viewValue.b_etr_files && viewValue.b_etr_files.map((item, index) =>
                                                                <div key={index} className='mb-10'>
                                                                    <tr>
                                                                        <td width='20%'>Upload PO For</td>
                                                                        <td width='20%'>Currency</td>
                                                                        <td width='20%'>PO Date</td>
                                                                        <td width='20%'>Face Value</td>
                                                                        <td width='20%'>PO Number</td>
                                                                        <td width='20%'>Attach PO File</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>{item.debtor.organisation_name}</th>
                                                                        <th>{item.currency && item.currency.currency_name}</th>
                                                                        <th>{item.po_date && item.po_date}</th>
                                                                        <th>{item.face_value && item.face_value}</th>
                                                                        <th>{item.po_no && item.po_no}</th>
                                                                        <th>{item.po_file && <a target={`_blank`} href={defaultAPIURL + item.po_file.url}>{item.po_file.name}</a>}</th>
                                                                    </tr>
                                                                </div>
                                                            )}
                                                        </th>
                                                    </tr>
                                                </>
                                            </>
                                            : (viewValue.etr_type === 3 || viewValue.etr_type === 4) ?
                                                <>
                                                    <tr>
                                                        <td width="25%">ETR Type</td>
                                                        <td width="25%">Upload Revolving ETR Invoice for</td>
                                                        <td width="25%">Select b-ETR</td>
                                                        {viewValue.etr_type === 4 ? <td width="25%">Deliver NoA to</td> : <td width="25%">Vat Included</td>}
                                                    </tr>
                                                    <tr>
                                                        <th>{viewValue.etr_type && etr_type[viewValue.etr_type]}</th>
                                                        <th>{viewValue.debtor && viewValue.debtor.organisation_name}</th>
                                                        <th>{viewValue.betr ? <a href onClick={() => openSubDrawer(viewValue.betr.id)}>{viewValue.betr.friendly_name}</a> : viewValue.betr_id === 0 ? 'Without b-ETR' : null}</th>
                                                        {viewValue.etr_type === 4 ? <th>{viewValue.deliver_to && viewValue.deliver_to.email}</th> : <th>{viewValue.vat_included && viewValue.vat_included ? 'Yes' : 'No'}</th>}
                                                    </tr>

                                                    <tr>
                                                        <td width="25%">Payment Terms</td>
                                                        <td width="25%">Currency</td>
                                                        <td width="25%">Face Value</td>
                                                        <td width="25%">Invoice Date</td>
                                                    </tr>
                                                    <tr>
                                                        <th>{viewValue.payment_discount_term && viewValue.payment_discount_term}</th>
                                                        <th>{viewValue.currency && viewValue.currency.currency_name}</th>
                                                        <th>{viewValue.face_value && viewValue.face_value}</th>
                                                        <th>{viewValue.invoice_date && viewValue.invoice_date}</th>
                                                    </tr>

                                                    <tr>
                                                        <td width="25%">Expected Date</td>
                                                        <td width="25%">PO Number</td>
                                                        <td width="25%">Attach PoD</td>
                                                        <td width="25%">Invoice No.</td>
                                                    </tr>
                                                    <tr>
                                                        <th>{viewValue.expected_date && viewValue.expected_date}</th>
                                                        <th>{viewValue.po ? viewValue.po.po_no : viewValue.po_no}</th>
                                                        <th>{viewValue.po_attach && <a target={`_blank`} href={defaultAPIURL + viewValue.po_attach.url}>{viewValue.po_attach.name}</a>}</th>
                                                        <th>{viewValue.invoice_no && viewValue.invoice_no}</th>
                                                    </tr>
                                                    <tr>
                                                        <td width="25%">Attach Invoice</td>
                                                    </tr>
                                                    <tr>
                                                        <th>{viewValue.file_support && <a target={`_blank`} href={defaultAPIURL + viewValue.file_support.url}>{viewValue.file_support.name}</a>}</th>
                                                    </tr>
                                                </>
                                                : (viewValue.etr_type === 6) ?
                                                    <>
                                                        <tr>
                                                            <td width="25%">ETR Type</td>
                                                            <td width="25%">Open RSA for</td>
                                                            <td width="25%">Payment Terms</td>
                                                            <td width="25%">Currency</td>
                                                        </tr>
                                                        <tr>
                                                            <th>{etr_type[viewValue.etr_type]}</th>
                                                            <th>{viewValue.debtor && viewValue.debtor.organisation_name}</th>
                                                            <th>{viewValue.payment_discount_term && viewValue.payment_discount_term}</th>
                                                            <th>{viewValue.currency && viewValue.currency.currency_name}</th>
                                                        </tr>

                                                        <tr>
                                                            <td width="25%">Total Face Value of ETR</td>
                                                            <td width="25%">Number of ETR</td>
                                                            <td width="25%">Revolving Offer Months</td>
                                                            <td width="25%">Attach Supporting Document</td>
                                                        </tr>
                                                        <tr>
                                                            <th>{viewValue.face_value && viewValue.face_value}</th>
                                                            <th>{viewValue.etr_no && viewValue.etr_no}</th>
                                                            <th>{viewValue.etr_months && viewValue.etr_months}</th>
                                                            <th>{viewValue.file_support && <a target={`_blank`} href={defaultAPIURL + viewValue.file_support.url}>{viewValue.file_support.name}</a>}</th>
                                                        </tr>

                                                        <tr>
                                                            <td colSpan={4}>Description</td>
                                                        </tr>
                                                        <tr>
                                                            <th colSpan={4}>{viewValue.desc && viewValue.desc}</th>
                                                        </tr>
                                                        <tr><td colSpan={4}>Your Trade Terms</td></tr>
                                                        <tr>
                                                            <th colSpan={2}>
                                                                <tr>
                                                                    <td width='20%'>Monthly Discount</td>
                                                                    <td width='20%'>Buy Out Discount</td>
                                                                </tr>
                                                                <tr>
                                                                    <th>{viewValue.discount_price && viewValue.discount_price}</th>
                                                                    <th>{viewValue.buyout_discount_price && viewValue.buyout_discount_price}</th>
                                                                </tr>
                                                            </th>
                                                        </tr>
                                                    </>
                                                    : null
                                }
                            </tbody>
                        </table>
                    }
                    <ViewComponent visible={subDrawer} close={closeSubDrawer} id={subID} />
                    <EditComponent visible={editTradeDrawer} close={closeEditTradeDrawer} type={'view'} />
                </Spin>
            </Drawer>
        </>
    ) : null;
})

export default ViewComponent;