import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { Button, Drawer, Form, Spin } from 'antd'
import FormComponent from './FormComponent'
import useStore from '../../../../store'
import moment from 'moment'

const ViewComponent = observer((props) => {
    const [form] = Form.useForm()
    const { AppsAuthStore, TransactionStore, TransactionStore: { viewValues } } = useStore()
    const [loading, setLoading] = useState(true)
    const [csvColumns, setCsvColumns] = useState(null)

    const close = () => {
        TransactionStore.viewValues = null
        form.resetFields()
        setLoading(true)
        setCsvColumns(null)
        props.close()
    }

    useEffect(() => {
        if (viewValues) {
            form.setFieldsValue({
                credebtor_id: viewValues.credebtor_id,
                type_field_mapping_id: viewValues.tte_type_field_mapping_id,
                currency_id: viewValues.currency_id,
                face_value: viewValues.face_value,
                tax_settings_id: viewValues.ledger_tax_settings_id,
                posted_on: moment(viewValues.posted_on, 'YYYY-MM-DD'),
                traded_on: moment(viewValues.traded_on, 'YYYY-MM-DD')
            })
            setCsvColumns(viewValues.csv_columns)
            setLoading(false)
        }
    }, [form, viewValues])


    return (
        <Drawer
            visible={props.visible}
            onClose={close}
            placement="right"
            width={"50%"}
            destroyOnClose={true}
            zIndex={AppsAuthStore.list_fullscreen ? 1011 : 1001}
            title={`View Transaction #${viewValues && viewValues.id ? viewValues.id : ''}`}
            footer={[
                <div className="text-center" key={1}>
                    <Button onClick={close}>Cancel</Button>
                </div>
            ]}
        >
            <Spin size="default" spinning={loading}>
                <FormComponent
                    form={form}
                    type="view"
                    csvColumns={csvColumns}
                />
            </Spin>
        </Drawer>
    )
})

export default ViewComponent