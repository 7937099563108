import React, { useEffect, useState } from 'react'
import { Alert, Button, Form, Input, Popover } from 'antd'
import { vsmAuth, vsmNotify } from '../../config/Originator/AppsMessage'
import { Link } from 'react-router-dom'
import { observer } from 'mobx-react'
import { IoIosMail, IoIosLock, IoIosEye, IoIosEyeOff } from "react-icons/io";
import useStore from '../../store'
import loginBG from '../../assets/images/loginBG.jpg'

const OriginatorLogin = observer((props) => {
    const [form] = Form.useForm()
    const { AppsAuthStore } = useStore()
    const [commonError, setCommonError] = useState(null)
    const [tooltip, setTooltip] = useState(false)
    const [loading, setLoading] = useState(false)

    // make function call to login
    const handleSubmit = (data) => {
        setLoading(true)
        // let payload = {
        //     email: "ExtApp1@yopmail.com",
        //     password: "Abc@123456"
        // }
        AppsAuthStore.doAppsLogin(data).then(() => {
            vsmNotify.success({
                message: vsmAuth.success
            })
            setLoading(false)
        }).catch(errors => {
            if (errors && errors.notify) { setCommonError(errors.notify) }
            if (errors && errors.errors) { form.setFields(errors.errors) }
            setLoading(false)
        })
    }

    // password criteria tool tip
    const passwordTooltip = (
        <div>
            <div>at least 1 numeric character</div>
            <div>at least 1 special character</div>
            <div>at least 1 uppercase letter</div>
            <div>at least 8  character</div>
        </div>
    );

    // handle password tool tip visiblility
    const handleChangePassword = (e) => {
        form.validateFields(['password']).then(() => {
            setTooltip(false)
        }).catch(() => {
            setTooltip(true)
        })
    }

    useEffect(() => {
        if (AppsAuthStore.theme_type === '1') {
            document.documentElement.style.setProperty('--primary', '#2E4369');
            document.documentElement.style.setProperty('--btn_border_radius', '0');
            document.documentElement.style.setProperty('--primaryBG', '#2E4369');
            document.documentElement.style.setProperty('--btn_font_color', '#ffffff');
        } else if (AppsAuthStore.theme_type === '2') {
            document.documentElement.style.setProperty('--primary', '#FF8400');
            document.documentElement.style.setProperty('--btn_border_radius', '30px');
            document.documentElement.style.setProperty('--primaryBG', '#FF8400');
            document.documentElement.style.setProperty('--btn_font_color', '#ffffff');
        } else if (AppsAuthStore.theme_type === '3') {
            document.documentElement.style.setProperty('--btn_border_radius', '0');
            document.documentElement.style.setProperty('--primary', `linear-gradient(270deg, #A79765 0%, #EADFA7 51.55%, #DABC6F 100%)`);
            document.documentElement.style.setProperty('--primaryBG', `linear-gradient(270deg, #A79765 0%, #EADFA7 51.55%, #DABC6F 100%)`);
            document.documentElement.style.setProperty('--btn_font_color', '#000000');
        } else {
            document.documentElement.style.setProperty('--primary', '#2E4369');
            document.documentElement.style.setProperty('--btn_border_radius', '0');
            document.documentElement.style.setProperty('--primaryBG', '#2E4369');
            document.documentElement.style.setProperty('--btn_font_color', '#ffffff');
        }
    }, [AppsAuthStore.theme_type])

    return (
        <div className={`main__login_wrapper`} style={{ backgroundImage: `url(${AppsAuthStore.theme_type === '3' ? loginBG : null})` }}>
            <div className='login_card_wrapper'>
                <Form form={form} onFinish={handleSubmit}>
                    {commonError && <Alert style={{ marginBottom: 15 }} message={commonError} type="error" showIcon />}
                    <Form.Item name="email" rules={vsmAuth.validation.email} hasFeedback>
                        <Input
                            prefix={<IoIosMail style={{ color: AppsAuthStore.theme_type === '1' ? '#2E4369' : AppsAuthStore.theme_type === '2' ? '#FF8400' : AppsAuthStore.theme_type === '3' ? 'rgb(175 160 109)' : '#2E4369', fontSize: '30px' }} />}
                            placeholder="Email Address"
                            className='input_wrapper'
                        />
                    </Form.Item>

                    <Popover placement="topRight" content={passwordTooltip} visible={tooltip}>
                        <Form.Item name="password" rules={vsmAuth.validation.password} hasFeedback>
                            <Input.Password
                                prefix={<IoIosLock style={{ color: AppsAuthStore.theme_type === '1' ? '#2E4369' : AppsAuthStore.theme_type === '2' ? '#FF8400' : AppsAuthStore.theme_type === '3' ? 'rgb(175 160 109)' : '#2E4369', fontSize: '30px' }} />}
                                placeholder="Password"
                                onBlur={() => setTooltip(false)}
                                onChange={handleChangePassword}
                                onFocus={handleChangePassword}
                                className='input_wrapper'
                                iconRender={visible => (visible ? (<IoIosEye style={{ color: AppsAuthStore.theme_type === '1' ? '#2E4369' : AppsAuthStore.theme_type === '2' ? '#FF8400' : AppsAuthStore.theme_type === '3' ? 'rgb(175 160 109)' : '#2E4369', fontSize: '30px' }} />) : (<IoIosEyeOff style={{ color: AppsAuthStore.theme_type === '1' ? '#2E4369' : AppsAuthStore.theme_type === '2' ? '#FF8400' : AppsAuthStore.theme_type === '3' ? 'rgb(175 160 109)' : '#2E4369', fontSize: '30px' }} />))}
                            />
                        </Form.Item>
                    </Popover>

                    <div className="text-center">
                        <Button loading={loading} htmlType="submit" block type="primary" className={`login_button_wrapper theme_type${AppsAuthStore.theme_type}`}>LOGIN</Button>
                    </div>
                    <div className="d-flex justify-content-end m-0 p-0">
                        <Link to={{
                            pathname: "/apps/orignator-forget-password"
                        }} type="link" className="p-0 forgot_pass_wrapper"><b>Forgot Password?</b></Link>
                    </div>
                </Form>
            </div>
        </div>
    )
})

export default OriginatorLogin