import React, { useState } from 'react'
import { observer } from "mobx-react"
import { Col, Row, Spin } from 'antd'
import InputComponent from '../../../../../components/InputComponent'
import { vsmProfile } from '../../../../../config/Originator/AppsMessage'
import useStore from '../../../../../store'

const AddressSubElement = observer(({ onChange, field, setFetchCountry, fetchCountry }) => {
	const [fetchCity, setFetchCity] = useState(true)
	const [fetchState, setFetchState] = useState(true)
	const { UserProfileStore } = useStore()

	const onCountryChange = (e) => {
		localStorage.setItem('local_apps_org_country', JSON.stringify(e))
	}

	return (
		<>
			<Row gutter={[8, 8]}>
				<Col span={6}>
					<InputComponent
						label="Type"
						name={[field.name, 'address_type']}
						key={[field.key, 'address_type']}
						placeholder="Address Type"
						onChange={onChange}
						tooltip="The type of the address under which the address can be created.[eg.: Home, Office, Billing Address, Shipping Address]"
						rules={vsmProfile.validation.address_type}
						maxLength={1000}
					/>
				</Col>

				<Col span={6}>
					<InputComponent
						label="Address 1" required
						name={[field.name, 'address_1']}
						key={[field.key, 'address_1']}
						placeholder="Address Line 1"
						onChange={onChange}
						tooltip="A contact point used to send physical forms of communication to the organisation."
						rules={vsmProfile.validation.address_1}
						maxLength={1000}
					/>
				</Col>

				<Col span={6}>
					<InputComponent
						label="Address 2"
						name={[field.name, 'address_2']}
						key={[field.key, 'address_2']}
						placeholder="Address Line 2"
						onChange={onChange}
						tooltip="A contact point used to send physical forms of communication to the organisation."
						rules={vsmProfile.validation.address_2and3}
						maxLength={1000}
					/>
				</Col>

				<Col span={6}>
					<InputComponent
						label="Address 3"
						name={[field.name, 'address_3']}
						key={[field.key, 'address_3']}
						placeholder="Address Line 3"
						onChange={onChange}
						tooltip="A contact point used to send physical forms of communication to the organisation."
						rules={vsmProfile.validation.address_2and3}
						maxLength={1000}
					/>
				</Col>
			</Row>

			<Row gutter={[8, 8]}>
				<Col span={6}>
					<InputComponent
						allowClear
						type="autocomplete"
						label="City"
						name={[field.name, 'city']}
						key={[field.key, 'city']}
						placeholder="City"
						onChange={onChange}
						options={UserProfileStore.cityList}
						tooltip="It is a large human settlement place."
						rules={vsmProfile.validation.city}
						onFocus={() => fetchCity && UserProfileStore.getCityList().then(() => setFetchCity(false))}
						notFoundContent={fetchCity ? <Spin size="small" /> : "No Record Found."}
						autoComplete="off"
					/>
				</Col>

				<Col span={6}>
					<InputComponent
						allowClear
						type="autocomplete"
						label="State/County"
						name={[field.name, 'state_county']}
						key={[field.key, 'state_county']}
						placeholder="State/County"
						onChange={onChange}
						options={UserProfileStore.stateCountyList}
						tooltip="State is defined as a territory with its own government and borders within a larger country."
						rules={vsmProfile.validation.state}
						onFocus={() => fetchState && UserProfileStore.getStateCountyList().then(() => setFetchState(false))}
						notFoundContent={fetchState ? <Spin size="small" /> : "No Record Found."}
						autoComplete="off"
					/>
				</Col>

				<Col span={6}>
					<InputComponent
						label="Postal Code"
						name={[field.name, 'postal_code']}
						key={[field.key, 'postal_code']}
						placeholder="Postal Code"
						onChange={onChange}
						tooltip="Each administrative division maintains its own postal code for mail delivery purposes. Having the correct code is essential to your mails delivery."
						rules={vsmProfile.validation.postal_code}
						maxLength={16}
					/>
				</Col>

				<Col span={6}>
					<InputComponent
						required allowClear
						type="select"
						label="Country" placeholder="Country"
						name={[field.name, 'country_id']}
						key={[field.key, 'country_id']}
						onChange={(e) => {
							onChange()
							onCountryChange(e)
						}}
						options={{
							values: UserProfileStore.dropdown_countries_list || (
								UserProfileStore.profileDetails && UserProfileStore.profileDetails.orgnisation && UserProfileStore.profileDetails.orgnisation.mutiple_addresses.map((item) => ({
									id: item.country_id,
									country_name: item.country_name
								}))
							),
							value_key: 'id',
							text_key: 'country_name'
						}}
						rules={vsmProfile.validation.currency_id}
						tooltip="A country is a political state, nation, or territory which is controlled."
						onFocus={() => fetchCountry && UserProfileStore.getAllCountries().then(() => setFetchCountry(false))}
						notFoundContent={fetchCountry ? <Spin size="small" /> : "No Record Found."}
						autoComplete="off"
					/>
				</Col>
			</Row>
		</>
	)
})

export default AddressSubElement
