import React from 'react'
import { observer } from "mobx-react"
import { Form, Col, Button, Row, Card, Divider } from 'antd'
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import AddressSubElement from './SubElement/AddressSubElement'

const AddressElement = observer(({ onChange, setFetchCountry, fetchCountry }) => {
	return (
		<div className="w-100">
			<Form.List name='addresses'>
				{
					(fields, { add, remove }) => {
						return (
							<>
								{fields.map((field, index) => {
									return (
										<div className="w-100" key={field.key}>
											<div>
												<Row gutter={10} className="align-items-center">
													<Col span={23}>
														<Card style={{ margin: 10 }}>
															<AddressSubElement onChange={onChange} field={field} fetchCountry={fetchCountry} setFetchCountry={setFetchCountry} />
														</Card>
													</Col>
													{
														<Col span={1}><Button type="danger" onClick={async () => {
															await remove(field.name)
															onChange()
														}} icon={<MinusOutlined />}></Button></Col>
													}

												</Row>
											</div>
										</div>
									)
								})}
								<Divider>
									<Button type="primary" onClick={async () => {
										var temp = localStorage.getItem('local_apps_org_country')
										await add({ country_id: temp ? parseInt(temp) : null })
										onChange()
									}} block icon={<PlusOutlined />}>Add Address</Button>
								</Divider>
							</>
						)
					}
				}
			</Form.List >
		</div>
	)
})

export default AddressElement
