import React, { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Button, Drawer, Form } from 'antd';
import FormComponent from './FormComponent';
import useStore from '../../../../store';
import { vsmCredebtor, vsmNotify } from '../../../../config/Originator/AppsMessage';
import moment from 'moment';

const AddComponent = observer((props) => {
    const [form] = Form.useForm()
    const [saving, setSaving] = useState(false)
    const [isDisabled, setDisabled] = useState(true)
    const [currentTab, setCurrentTab] = useState("account")
    const [bankDetails, setBankDetails] = useState(false)
    const [fetchBankType, setFetchBankType] = useState(true)
    const [fileList, updateFileList] = useState([]);
    const [expFileList, updateExpFileList] = useState([]);
    const { CredebtorsStore, DashboardStore, AppsAuthStore } = useStore()
    const [fetchCountry, setFetchCountry] = useState(true)
    const [fetchStatus, setFetchStatus] = useState(true)
    const [fetchIndustries, setFetchIndustries] = useState(true)
    const [fetctNace, setFetchNace] = useState(true)
    const [fetchCurrency, setFetchCurrency] = useState(true)

    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);

    const handleChange = () => {
        form.validateFields().then((data) => {
            let checkbox = form.getFieldValue("add_address")
            let address = form.getFieldValue("addresses")
            address = address && address.filter(x => x && x)
            if (checkbox && (!address || (address && address.length === 0))) {
                setDisabled(true)
            } else {
                setDisabled(false)
            }
        }).catch(e => {
            if (e.errorFields && e.errorFields.length > 0) {
                setDisabled(true)
            }
        })
    }

    const handleSubmit = (data) => {
        if (data.phone && data.phone.length > 0) {
            data.phone = data.phone.filter(x => x && (x.contact_for && x.contact_value))
            data.phone = data.phone.length > 0 ? JSON.stringify(data.phone) : null
        }

        if (data.email && data.email.length > 0) {
            data.email = data.email.filter(x => x && (x.contact_for && x.contact_value))
            data.email = data.email.length > 0 ? JSON.stringify(data.email) : null
        }

        if (data.addresses && data.addresses.length > 0) {
            data.addresses = data.addresses.filter(x => x && x)
            data.addresses = data.addresses.length > 0 ? JSON.stringify(data.addresses) : null
        }

        if (data.banks && data.banks.length > 0) {
            data.banks = data.banks.filter(x => x && x)
            data.banks = data.banks.length > 0 ? JSON.stringify(data.banks) : null
        }

        setSaving(true)
        var formData = CredebtorsStore.JsonToFormData(data)

        formData.append("add_address", data.addresses && data.addresses.length > 0 ? 1 : 0)

        formData.append("add_bank", data.banks && data.banks.length > 0 ? 1 : 0)

        formData.append("counter_party_since", moment(data.counter_party_since).format('YYYY'))

        if (data.logo_url && data.logo_url.fileList[0] && data.logo_url.fileList.length > 0) {
            formData.append("logo_url", data.logo_url.fileList[0].originFileObj)
        } else {
            formData.append("logo_url", '')
        }

        if (expFileList && expFileList.length > 0) {
            formData.append("experience_file", expFileList[0].originFileObj);
        } else {
            formData.append("experience_file", null)
        }

        CredebtorsStore.AddData(formData).then(() => {
            close()
            vsmNotify.success({
                message: vsmCredebtor.add
            })
        }).catch(e => {
            if (e.errors) { form.setFields(e.errors) }
        }).finally(() => setSaving(false))
    }

    const close = () => {
        setDisabled(false)
        setBankDetails(false)
        setFetchBankType(true)
        updateFileList([])
        updateExpFileList([])
        setCurrentTab('account')
        CredebtorsStore.initialValues.banks = []
        CredebtorsStore.initialValues.phone = [{ id: null, contact_for: 'Primary' }]
        CredebtorsStore.initialValues.email = [{ id: null, contact_for: 'Primary' }]
        CredebtorsStore.initialValues.adddress = []
        setFetchCountry(true)
        setFetchStatus(true)
        setFetchIndustries(true)
        setFetchNace(true)
        setFetchCurrency(true)
        if (props.param === 'fromPO') {
            props.fetchPOFor(true)
        }
        form.resetFields()
        props.close()
    }

    const onRoleChange = (e) => {
        if (e === 'Creditor') {
            setBankDetails(true)
        } else {
            setBankDetails(false)
        }
    }

    useEffect(() => {
        if (props.visible && props.params) {
            form.setFieldsValue({
                role: DashboardStore.credebtorType === 'Debtor' ? 'Debtor' : DashboardStore.credebtorType === 'Creditor' ? 'Creditor' : null
            })
            onRoleChange(DashboardStore.credebtorType)
        }
    }, [DashboardStore.credebtorType, form, props.visible, props.params])

    return (
        <Drawer
            visible={props.visible}
            onClose={close}
            placement='right'
            width={'50%'}
            destroyOnClose={true}
            zIndex={AppsAuthStore.list_fullscreen ? 1011 : 1001}
            title='Add Credebtor'
            footer={[
                <div className="text-center" key={1}>
                    <Button form='addeditform' className="mr-10" loading={saving} htmlType="submit" type="primary" disabled={isDisabled}>Save</Button>
                    <Button onClick={close}>Cancel</Button>
                </div>
            ]}
        // style={{ left: AppsAuthStore.list_fullscreen ? '0px' : '280px' }}
        >
            <FormComponent
                form={form}
                onChange={handleChange}
                handleSubmit={handleSubmit}
                setDisabled={setDisabled}
                currentTab={currentTab}
                setCurrentTab={setCurrentTab}
                onRoleChange={onRoleChange}
                bankDetails={bankDetails}
                updateFileList={updateFileList}
                fileList={fileList}
                setFetchBankType={setFetchBankType}
                fetchBankType={fetchBankType}
                updateExpFileList={updateExpFileList}
                expFileList={expFileList}
                forceUpdate={forceUpdate}
                setFetchCountry={setFetchCountry}
                setFetchStatus={setFetchStatus}
                setFetchIndustries={setFetchIndustries}
                setFetchNace={setFetchNace}
                setFetchCurrency={setFetchCurrency}
                fetchCountry={fetchCountry}
                fetchStatus={fetchStatus}
                fetchIndustries={fetchIndustries}
                fetctNace={fetctNace}
                fetchCurrency={fetchCurrency}
            />
        </Drawer>
    );
})

export default AddComponent;