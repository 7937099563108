import { faExpand, faCompress } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from 'antd'
import { observer } from 'mobx-react'
import React from 'react'
import useStore from '../store'

const EnterFullscreen = observer(() => {
  const { AppsAuthStore: { list_fullscreen, toggleListFullscreen } } = useStore()
  if (list_fullscreen) {
    return <Button className="close_fullscreen1" title="Exit Fullscreen" onClick={toggleListFullscreen}><FontAwesomeIcon icon={faCompress} /></Button>
  } else {
    return <Button title="Enter Fullscreen" onClick={toggleListFullscreen}><FontAwesomeIcon icon={faExpand} /></Button>
  }

})


export default EnterFullscreen
