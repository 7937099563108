import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Col, Form, message, Row, Spin, Upload, Divider, Collapse } from 'antd';
import PhoneElement from '../elements/PhoneElement';
import EmailElement from '../elements/EmailElement';
import { PlusOutlined, MinusOutlined, PercentageOutlined, InboxOutlined } from '@ant-design/icons';
import BankDetailsElement from '../elements/BankDetailsElement';
import AddressElement from '../elements/AddressElement';
import InputComponent from '../../../../components/InputComponent';
import { vsmProfile, vsmCredebtor } from '../../../../config/Originator/AppsMessage';
import useStore from '../../../../store';
import debounce from 'lodash/debounce'
import moment from 'moment';

const { Panel } = Collapse;

const FormComponent = observer(({ form, onChange, handleSubmit, currentTab, setCurrentTab, onRoleChange, bankDetails, updateFileList, fileList, setFetchBankType, fetchBankType, updateExpFileList, expFileList, setFetchCountry, setFetchStatus, setFetchIndustries, setFetchNace, setFetchCurrency, fetchCountry, fetchStatus, fetchIndustries, fetctNace, fetchCurrency, activebanks, activeAddress }) => {
    const { CredebtorsStore, UserProfileStore, AppsAuthStore } = useStore()
    const [imgchanged, setImgchanged] = useState(false)
    // const [naceCode, setNaceCode] = useState()

    const [accountType, setAccountType] = useState(true)
    const [currency, setCurrency] = useState(true)

    const onFinish = (data) => {
        data.imgchanged = imgchanged
        handleSubmit(data)
    }

    const onCountryChange = (e) => {
        localStorage.setItem('local_apps_org_country', JSON.stringify(e))
    }

    const handleFileChange = (info) => {
        if (info.file.status === 'uploading') {
            return;
        }
    }

    const eventProps = {
        onChange: debounce(({ fileList: newFileList }) => {
            updateFileList(newFileList);
            handleFileChange(newFileList);
        }, 500),
        fileList,
        beforeUpload: file => {
            let isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
            let isLt1M = file.size / 1024 / 1024 < 1;

            if (!isJpgOrPng) {
                message.error(`Upload valid image. Only JPG, JPEG or PNG are allowed.`);
                return true;
            } else if (!isLt1M) {
                message.error('File size should not be more than 500px x 500px and 1 MB');
                return true;
            }
            else {
                setImgchanged(true)
                if (file.type.includes("image")) {
                    [file][0]["url"] = URL.createObjectURL(file);
                }
                updateFileList([file])
                return false
            }
        }
    };

    // Handle on remove image
    const onRemoveImage = () => {
        setImgchanged(false)
        form.resetFields(["logo_url"])
        updateFileList([]);
        onChange();
    }

    // check whether address checkbox is checked or not
    const addAddress = async (e) => {
        var temp /*= localStorage.getItem('local_apps_org_country')*/
        form.setFieldsValue({ addresses: e.target.checked ? [temp ? { country_id: parseInt(temp) } : null] : null })
        if (fetchCountry) {
            UserProfileStore.getAllCountries().then(() => setFetchCountry(false))
        }
        onChange()
    }

    const disabledDate = (current) => {
        const tooLate = current && current <= moment().endOf('years').year('1950');;
        const tooEarly = current && current > moment().endOf('years');
        return tooLate || tooEarly
    }

    const onDownload = (info) => {
        window.open(info.url, '_blank');
    }

    const uploadProps = {
        name: 'file',
        multiple: false,
        fileList: expFileList,
    };

    const handleFileUploadChange = (info) => {
        const { file } = info;
        let nextState = [];
        let isCSV = file.type === 'text/csv' || file.type === 'application/vnd.ms-excel' || file.type === '' || file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        let isLt1M = file.size / 1024 / 1024 < 1;

        switch (info.file.status) {
            case "uploading":
                if (!isCSV) {
                    message.error(`You can only upload CSV, XLS or XLSX file!`);
                    updateExpFileList([])
                    return true;
                } else if (!isLt1M) {
                    message.error('Please upload CSV, XLS or XLSX file with size of less then 1 MB.');
                    updateExpFileList([])
                    return true;
                }
                else {
                    nextState = [info.file];
                }
                break;
            case "done":
                if (!isCSV) {
                    message.error(`You can only upload CSV, XLS or XLSX file!`);
                    updateExpFileList([])
                    return true;
                } else if (!isLt1M) {
                    message.error('Please upload CSV, XLS or XLSX file with size of less then 1 MB.');
                    updateExpFileList([])
                    return true;
                }
                else {
                    nextState = [info.file];
                }
                break;

            default:
                // error or removed
                nextState = [];
        }
        updateExpFileList(nextState)
        onChange();
    }
    const dummyRequest = ({ file, fileList, onSuccess }) => {
        setTimeout(() => {
            onSuccess("ok");
        }, 0);
    };

    // const changeNACE = (e) => {
    //     const naceCode = UserProfileStore.naceCode_list.find(list => list.id === e)
    //     setNaceCode(naceCode.nace_section)
    // }

    useEffect(() => {
        form.setFieldsValue({
            related_organisation: AppsAuthStore.originator_user.orgnisation && AppsAuthStore.originator_user.orgnisation.id
        })
    }, [form, AppsAuthStore.originator_user.orgnisation])

    return (
        <Form
            form={form}
            layout='vertical'
            id="addeditform"
            onFinish={onFinish}
            initialValues={CredebtorsStore.initialValues}
        >
            <Row gutter={10}>
                <Col span={4}>
                    <InputComponent
                        required
                        allowClear
                        type="select"
                        label="Choose Role"
                        name="role"
                        placeholder="Select Choose Role"
                        onChange={(e) => { onChange(); onRoleChange(e); }}
                        options={{
                            values: [
                                { value: 'Creditor', text: 'Creditor' }, { value: 'Debtor', text: 'Debtor' }
                            ],
                        }}
                        tooltip="Choose Role - Debtor/Creditor"
                        autoComplete="off"
                        rules={vsmCredebtor.validation.role}
                        disabled={CredebtorsStore.read_data && CredebtorsStore.read_data.associted_etr_count ? true : false}
                    />
                </Col>

                <Col span={5}>
                    <InputComponent
                        required
                        label="Credebtor Name"
                        name="organisation_name"
                        placeholder="Credebtor Name"
                        onChange={onChange}
                        tooltip="Name is one of the basic parameter of a Debtor or Creditor"
                        rules={vsmCredebtor.validation.credebtor_name}
                        maxLength={100}
                    />
                </Col>

                <Col span={5}>
                    <InputComponent
                        label="Trade Name"
                        name="trade_name"
                        placeholder="Trade Name"
                        onChange={onChange}
                        tooltip="Organisation Trade name is a trading name commonly used by an Organisation to perform business activities."
                        rules={vsmProfile.validation.trade_name}
                        maxLength={100}
                    />
                </Col>

                <Col span={5}>
                    <InputComponent
                        required
                        allowClear
                        type="select"
                        label="Registration Country"
                        name="registered_country_id"
                        placeholder="Select Registration Country"
                        onChange={(e) => {
                            onChange()
                            onCountryChange(e)
                        }}
                        options={{
                            values: UserProfileStore.dropdown_countries_list || [{
                                id: CredebtorsStore.read_data && CredebtorsStore.read_data.organisation && CredebtorsStore.read_data.organisation.global_countries && CredebtorsStore.read_data.organisation.global_countries.id,
                                country_name: CredebtorsStore.read_data && CredebtorsStore.read_data.organisation && CredebtorsStore.read_data.organisation.global_countries && CredebtorsStore.read_data.organisation.global_countries.country_name,
                            }],
                            value_key: 'id',
                            text_key: 'country_name'
                        }}
                        tooltip="A company register is a register of legal entities in the jurisdiction they operate under, for the purpose of protection, accountability and control of legal entities."
                        rules={vsmProfile.validation.registered_country_id}
                        onFocus={() => fetchCountry && UserProfileStore.getAllCountries().then(() => setFetchCountry(false))}
                        notFoundContent={fetchCountry ? <Spin size="small" /> : "No Record Found."}
                    />
                </Col>

                <Col span={5}>
                    <InputComponent
                        label="Registration Number"
                        name="registration_number"
                        placeholder="Registration Number"
                        onChange={onChange}
                        tooltip="A number appointed by relevant Company Registration Authorities in a country the organisation was founded in."
                        rules={vsmProfile.validation.registration_number}
                        maxLength={100}
                    />
                </Col>
            </Row>

            <Row gutter={10}>
                <Col span={4}>
                    <InputComponent
                        label="VAT Number"
                        name="vat_number"
                        placeholder="VAT Number"
                        onChange={onChange}
                        tooltip="A number appointed by relevant Tax Authorities in a country the organisation was founded in."
                        rules={vsmProfile.validation.vat_number}
                    />
                </Col>
                <Col span={5}>
                    <InputComponent
                        label="Website"
                        name="website"
                        placeholder="Website URL"
                        onChange={onChange}
                        tooltip="A website is a collection of web pages and related content that is identified by a common domain name and published on at least one web server."
                        rules={vsmProfile.validation.website}
                    />
                </Col>

                <Col span={5}>
                    <InputComponent
                        required
                        allowClear
                        type="select"
                        label="Currency"
                        name="currency_id"
                        placeholder="Select Currency"
                        onChange={onChange} rules={vsmProfile.validation.currency_id}
                        tooltip="Currency is a medium of exchange for goods and services. Setting Base currency will set this currency as default currency throughout Ledger."
                        onFocus={() => (fetchCurrency && UserProfileStore.getCurrencyList().then(() => setFetchCurrency(false)))}
                        notFoundContent={fetchCurrency ? <Spin size="small" /> : "No Record Found."}
                        options={{
                            values: UserProfileStore.dropdown_currency_list || [{
                                id: CredebtorsStore.read_data && CredebtorsStore.read_data.organisation && CredebtorsStore.read_data.organisation.global_currency && CredebtorsStore.read_data.organisation.global_currency.id,
                                currency_name: CredebtorsStore.read_data && CredebtorsStore.read_data.organisation && CredebtorsStore.read_data.organisation.global_currency.currency_name,
                            }],
                            value_key: "id",
                            text_key: "currency_name"
                        }}
                    />
                </Col>

                <Col span={6}>
                    <InputComponent
                        label="Tax Clearance Access Number"
                        name="revenue_access_number"
                        placeholder="Tax Clearance Access Number"
                        onChange={onChange}
                        rules={vsmProfile.validation.tax_clearence}
                        maxLength={100}
                        tooltip="When Revenue issues a Tax Clearance Certificate, they will also give a Tax Clearance Access Number (TCAN)."
                    />
                </Col>
            </Row>

            <Row gutter={[8, 8]}>
                <Col span={20}>
                    <Row gutter={[8, 8]}>
                        <Col span={12}>
                            <Form.Item label="Phone" className="mb-10" tooltip='Phone number can be stored with country code and extension numbers.'>
                                <PhoneElement onChange={onChange} form={form} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Email" className="mb-10" tooltip='Messages distributed by electronic means from one computer user to one or more recipients via a network.'>
                                <EmailElement onChange={onChange} form={form} />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <InputComponent
                                required
                                type='textarea'
                                name='description'
                                label='Organisation Description'
                                placeholder='Organisation Description'
                                onChange={onChange}
                                maxLength={32000}
                                rules={vsmCredebtor.validation.description}
                                tooltip={`The purpose of describing the organisation is to provide; an understanding of mission, goals, & objectives, and clarify the focus and importance of the organisation's effort.`}
                            />
                        </Col>
                    </Row>
                </Col>
                <Col span={4}>
                    <Form.Item label="Logo" tooltip={"A symbol or emblem that acts as a trademark or a means of identification of an institution or other entity."} extra={[<span key="1" style={{ fontSize: "12px" }}>Only JPG or PNG is allowed, Maximum size : 500px x 500px</span>]}>
                        <Form.Item noStyle name="logo_url">
                            <Upload
                                accept=".png, .jpeg"
                                fileList={fileList}
                                className="hideeyeicon"
                                listType="picture-card"
                                multiple={false}
                                showUploadList={true}
                                {...eventProps}
                                onRemove={onRemoveImage}
                                onChange={(e) => { handleFileChange(e); onChange() }}
                            >
                                {fileList.length >= 1 ? null :
                                    <div>
                                        <PlusOutlined />
                                    </div>}
                            </Upload>
                        </Form.Item>
                    </Form.Item>
                </Col>
            </Row>

            <Divider className='devider_wrapper' />

            <Row gutter={[8, 8]}>
                <Col span={4}>
                    <InputComponent
                        required
                        type='date'
                        name='counter_party_since'
                        label='Counterparty Since'
                        placeholder='Select Year'
                        onChange={onChange}
                        tooltip="Select the year when the company first became the customer of your company."
                        rules={vsmCredebtor.validation.counterparty_since}
                        picker="year"
                        className='date_wrapper'
                        disabledDate={disabledDate}
                    />
                </Col>

                <Col span={4}>
                    <InputComponent
                        label="Total Historic Value"
                        name="total_historic_value"
                        placeholder="Total Historic Value"
                        onChange={onChange}
                        tooltip="Enter the total value of business over the years that you have done with this company"
                        rules={vsmCredebtor.validation.historic_value}
                        maxLength={100}
                    />
                </Col>

                <Col span={5}>
                    <InputComponent
                        required
                        label="Payment Terms"
                        name="average_payment_cycle"
                        placeholder="Payment Terms"
                        onChange={onChange}
                        tooltip="Enter a fixed value to indicate your standard (30 , 60 ,90 days, etc)"
                        rules={vsmCredebtor.validation.payment_cycle}
                        maxLength={3}
                    />
                </Col>

                <Col span={5}>
                    <InputComponent
                        label="Credit Notes issued"
                        name="credit_notes_issued"
                        placeholder="Credit Notes issued"
                        onChange={onChange}
                        tooltip="How many credit notes have you issued to this customer over the years"
                        rules={vsmCredebtor.validation.notes_issued}
                        maxLength={100}
                    />
                </Col>

                <Col xl={12} xxl={6}>
                    <InputComponent
                        required
                        label="Invoices Predicated Per Annum"
                        name="invoice_predicted_per_annum"
                        placeholder="Invoices Predicated Per Annum"
                        onChange={onChange}
                        tooltip="What quantity of invoices do you issue to this Debtor company every year?"
                        rules={vsmCredebtor.validation.invoice_predicted}
                        maxLength={2}
                    />
                </Col>
            </Row>

            <Row gutter={10}>
                <Col xl={12} xxl={6}>
                    <InputComponent
                        required
                        label="Predicted Annual Revenue"
                        name="predicted_annual_revenue"
                        placeholder="Predicted Annual Revenue"
                        onChange={onChange}
                        tooltip="What is the total value of the business you invoice this company for each year?"
                        rules={vsmCredebtor.validation.invoice_predicted}
                        maxLength={10}
                    />
                </Col>
                {(bankDetails) ? (
                    <Col xl={12} xxl={6}>
                        <InputComponent
                            required
                            label="Agreed Early Payment Discount"
                            name="agreed_early_payment_discount"
                            placeholder="Agreed Early Payment Discount"
                            onChange={onChange}
                            tooltip="Enter the counterparty’s Agreed Early Payment Discount "
                            rules={vsmCredebtor.validation.payment_dis}
                            addonAfter={<PercentageOutlined />}
                        />
                    </Col>
                ) : (
                    <Col xl={12} lg={24}>
                        {/* <div className="text-right mr-25"><a>Download Sample File</a></div> */}
                        <Form.Item label='Experience File' className="full-width-dragger w-100" name='experience_file' required
                            extra={[
                                <div>
                                    <label>Make sure file contain account number in start to automatically map it with bank account</label>
                                </div>
                            ]}
                            tooltip='Upload the experience file'
                            rules={vsmCredebtor.validation.experience_file}
                        >
                            <Upload.Dragger
                                {...uploadProps}
                                onChange={handleFileUploadChange}
                                customRequest={dummyRequest}
                                showUploadList={{ showDownloadIcon: true }}
                                onDownload={onDownload}
                                fileList={expFileList}
                            >
                                <p className="ant-upload-drag-icon">
                                    <InboxOutlined />
                                </p>
                                <p className="ant-upload-text">Drag your CSV file here or select by clicking here</p>
                            </Upload.Dragger>
                        </Form.Item>
                    </Col>
                )}
            </Row>

            {(bankDetails) ? (
                <>
                    <Divider className='devider_wrapper' />

                    <Collapse
                        defaultActiveKey={activebanks ? ['1'] : null}
                        className="site-collapse-custom-collapse"
                        expandIconPosition='right'
                        expandIcon={({ isActive }) => isActive ? <MinusOutlined /> : <PlusOutlined />}
                        bordered={false}
                    >
                        <Panel header="Bank Details" key="1">
                            <BankDetailsElement onChange={onChange} setAccountType={setAccountType} accountType={accountType} setCurrency={setCurrency} currency={currency} currentTab={currentTab} setCurrentTab={setCurrentTab} form={form} setFetchBankType={setFetchBankType} fetchBankType={fetchBankType} />
                        </Panel>
                    </Collapse>
                </>
            ) : null}

            <Divider className='devider_wrapper' />

            <Collapse
                defaultActiveKey={activeAddress ? ['1'] : null}
                className="site-collapse-custom-collapse"
                expandIconPosition='right'
                expandIcon={({ isActive }) => isActive ? <MinusOutlined /> : <PlusOutlined />}
                bordered={false}
            >
                <Panel header="Address Details" key="1">
                    <AddressElement onChange={onChange} fetchCountry={fetchCountry} setFetchCountry={setFetchCountry} addAddress={addAddress} />
                </Panel>
            </Collapse>

        </Form>
    );
})

export default FormComponent;