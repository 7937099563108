import React from 'react';
import { Button, Dropdown, Layout, Menu } from 'antd'; //Card
import { observer } from 'mobx-react';
import { MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons'; //SearchOutlined, BellOutlined, SettingOutlined, 
// import InputComponent from '../InputComponent';
import useStore from '../../store';
import { useHistory } from 'react-router-dom';
import UserOutlined from '../../assets/images/Vector.png'
const { Header } = Layout;

const AppsHeaderComponent = observer((props) => {
    const { AppsAuthStore } = useStore()
    let history = useHistory()

    // call logout function
    const Logout = () => {
        AppsAuthStore.doLogOut()
        history.replace('/')
    }

    const userMenu = (
        <Menu className="header_user_ledger_menu">
            <Menu.Item key="logout" onClick={Logout}>
                Logout
            </Menu.Item>
        </Menu>
    )
    return (
        <>
            <Header className="site-layout-background site-header">
                <div className="d-flex align-items-center">
                    {(props.collapsed) ? (
                        <MenuUnfoldOutlined className="trigger" onClick={() => { props.setCollapsed(false) }} />
                    ) : (
                        <MenuFoldOutlined className="trigger" onClick={() => { props.setCollapsed(true) }} />
                    )}
                </div>
                <div className="d-flex align-items-center m-0">
                    {/* <InputComponent
                        name='global_search'
                        placeholder='Search'
                        className='global_search_input_wrapper'
                        prefix={<SearchOutlined />}
                    />

                    <Card className='card_wrapper'>
                        <BellOutlined />
                    </Card>
                    <Card className='card_wrapper'>
                        <SettingOutlined />
                    </Card> */}
                    <Dropdown overlay={userMenu}>
                        <Button type={"primary"} className='profile_card_wrapper'>
                            {/* <UserOutlined /> */}
                            <img src={UserOutlined} alt='' />
                            {AppsAuthStore.originator_user && <span style={{ color: '#ffffff', fontWeight: 'bold', marginLeft: 10 }}>{AppsAuthStore.originator_user.first_name} {AppsAuthStore.originator_user.last_name}</span>}
                        </Button>
                    </Dropdown>
                </div>
            </Header>
        </>
    )
})

export default AppsHeaderComponent;