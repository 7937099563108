import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import useStore from '../../../../../store';
import { Button, Drawer, Form, Spin } from 'antd';
import FormComponent from './FormComponent';
import { vsmNotify } from '../../../../../config/Originator/AppsMessage';
import { vsmTradeFloor } from '../../../../../config/Originator/AppsMessage';
import moment from 'moment';
import { defaultAPIURL } from '../../../../../utils/GlobalFunction';

const EditComponent = observer((props) => {
    const [form] = Form.useForm()
    const [loading, setLoading] = useState(true)
    const [saving, setSaving] = useState(false)
    const [savingAgree, setsavingAgree] = useState(false)
    const [savingAgreeSubmit, setsavingAgreeSubmit] = useState(false)
    const [isDisabled, setDisabled] = useState(true)
    const [fetchCurrency, setFetchCurrency] = useState(true);
    const [attachPoD, updateAttachPoD] = useState([]);
    const [attachInvoice, updateAttachInvoice] = useState([]);
    const { AppsAuthStore, TradeFloorStore } = useStore()
    const [position, setPosition] = useState(null)
    const [type, setType] = useState(null)
    const [formDataList, setFormDataList] = useState([])
    const [addAnother, setAddAnother] = useState(false)
    const [invoiceLoader, setInvoiceLoader] = useState(true)
    const [betrLoader, setbetrLoader] = useState(true)
    const [vatIncluded, setVatIncluded] = useState(false)
    const [ISOcodeAlpha2, setISOcodeAlpha2] = useState(false)
    const defaultFormDataList = []
    // b-ETR
    const [POFor, fetchPOFor] = useState(true)
    const [bETRCurrency, fetchbETRCurrency] = useState(true)
    const [supportingFile, updateSupportingFile] = useState([])
    const [poFile, updatePoFile] = useState([]);

    const setSubmit = (type, button_posistion) => {
        setPosition(button_posistion)
        setType(type)
    }

    const handleSubmit = (data) => {
        if (data) {
            if (![1, 5].includes(data.etr_type)) {
                data.expected_date = typeof (data.expected_date) === 'string' ? data.expected_date : moment(data.expected_date && data.expected_date).format("YYYY-MM-DD")
                if (![2].includes(data.etr_type)) {
                    data.invoice_date = typeof (data.invoice_date) === 'string' ? data.invoice_date : moment(data.invoice_date && data.invoice_date).format("YYYY-MM-DD")
                }
            } else {
                data.start_date = moment(data.start_date).format("YYYY-MM-DD")
                data.end_date = moment(data.end_date).format("YYYY-MM-DD")
                data.purchase_date = moment(data.purchase_date).format("YYYY-MM-DD")
            }
            if (position === 1) {
                setsavingAgree(true)
            } else if (position === 2) {
                setSaving(true)
            } else {
                setsavingAgreeSubmit(true)
            }
            if (data.vat_included || data.vat_included === 0) {
                data.vat_included = (vatIncluded === true) ? 1 : 0;
            }
            if (data.deliver_to === false) {
                delete data.deliver_to
            }
            var formData = TradeFloorStore.JsonToFormData(data)
            formData.append('status', type)
            if (![1, 5].includes(data.etr_type)) {
                formData.append("po_details", data.invoice_po && data.invoice_po.length > 0 ? 1 : 0)
            }
            if (attachPoD && attachPoD.length > 0 && attachPoD[0].originFileObj) {
                formData.append("po_attach", attachPoD[0].originFileObj);
            } else {
                formData.delete("po_attach")
            }

            if ([1, 5].includes(data.etr_type)) {
                if (supportingFile && supportingFile.length > 0) {
                    formData.append("file_support", supportingFile[0].originFileObj);
                } else {
                    formData.delete("file_support")
                }
            } else if (data.etr_type !== 2) {
                if (attachInvoice && attachInvoice.length > 0 && attachInvoice[0].originFileObj) {
                    formData.append("file_support", attachInvoice[0].originFileObj);
                } else {
                    formData.delete("file_support")
                }
            } else {
                if (supportingFile && supportingFile.length > 0 && supportingFile[0].originFileObj) {
                    formData.append("file_support", supportingFile[0].originFileObj);
                } else {
                    formData.delete("file_support")
                }
            }

            data.invoice_po && data.invoice_po.length > 0 && data.invoice_po.forEach((item, index) => {
                if (typeof (item.po_file) === 'string') {
                    formData.delete(`invoice_po[${index}][po_file]`)
                } else {
                    if (item.po_file.fileList && item.po_file.fileList.length > 0) {
                        formData.append(`invoice_po[${index}][po_file]`, item.po_file.fileList[0].originFileObj)
                    } else {
                        formData.delete(`invoice_po[${index}][po_file]`)
                    }
                }
                formData.append(`invoice_po[${index}][id]`, item.id ? item.id : '')
                formData.append(`invoice_po[${index}][debtor_id]`, item.debtor_id)
                formData.append(`invoice_po[${index}][currency_id]`, item.currency_id)
                formData.append(`invoice_po[${index}][po_face_value]`, item.po_face_value)
                formData.append(`invoice_po[${index}][po_date]`, moment(item.po_date).format('YYYY-MM-DD'))
                formData.append(`invoice_po[${index}][po_no]`, item.po_no)
            })

            if (addAnother) {
                TradeFloorStore.AddTrade(formData).then(() => {
                    setVatIncluded(false)
                    if (position === 3) {
                        form.resetFields()
                        vsmNotify.success({
                            message: data.etr_type === 1 ? 'a' + vsmTradeFloor.submitETR : data.etr_type === 2 ? 'b' + vsmTradeFloor.submitETR : data.etr_type === 3 ? 'c' + vsmTradeFloor.submitETR : data.etr_type === 4 ? 'd' + vsmTradeFloor.submitETR : data.etr_type === 5 ? 'f' + vsmTradeFloor.submitETR : null
                        })
                    } else if (position === 2) {
                        vsmNotify.success({
                            message: data.etr_type === 1 ? 'a' + vsmTradeFloor.addETR : data.etr_type === 2 ? 'b' + vsmTradeFloor.addETR : data.etr_type === 3 ? 'c' + vsmTradeFloor.addETR : data.etr_type === 4 ? 'd' + vsmTradeFloor.addETR : data.etr_type === 5 ? 'f' + vsmTradeFloor.addETR : null
                        })
                        close()
                    } else {
                        vsmNotify.success({
                            message: data.etr_type === 1 ? 'a' + vsmTradeFloor.submitETR : data.etr_type === 2 ? 'b' + vsmTradeFloor.submitETR : data.etr_type === 3 ? 'c' + vsmTradeFloor.submitETR : data.etr_type === 4 ? 'd' + vsmTradeFloor.submitETR : data.etr_type === 5 ? 'f' + vsmTradeFloor.submitETR : null
                        })
                        close()
                    }
                }).catch(e => {
                    if (e.errors) { form.setFields(e.errors) }
                }).finally(() => {
                    setSaving(false)
                    setsavingAgree(false)
                    setsavingAgreeSubmit(false)
                    setDisabled(true)
                })
            } else {
                TradeFloorStore.EditTrade(formData).then(() => {
                    setVatIncluded(false)
                    vsmNotify.success({
                        message: TradeFloorStore.readETRvalue.etr_type === 1 ? 'a' + vsmTradeFloor.editETR : TradeFloorStore.readETRvalue.etr_type === 2 ? 'b' + vsmTradeFloor.editETR : TradeFloorStore.readETRvalue.etr_type === 3 ? 'c' + vsmTradeFloor.editETR : TradeFloorStore.readETRvalue.etr_type === 4 ? 'd' + vsmTradeFloor.editETR : TradeFloorStore.readETRvalue.etr_type === 5 ? 'f' + vsmTradeFloor.editETR : null
                    })
                    if (position === 3) {
                        setsavingAgree(false);
                        setAddAnother(true)
                        form.resetFields();
                    } else {
                        close()
                    }
                }).catch(e => {
                    if (e.errors) { form.setFields(e.errors) }
                }).finally(() => {
                    setDisabled(true)
                    setSaving(false)
                    setsavingAgree(false)
                    setsavingAgreeSubmit(false)
                })
            }
        }
        // setSaving(false)
    }

    const close = () => {
        form.resetFields()
        updateAttachInvoice([])
        updateAttachPoD([])
        updateSupportingFile([])
        updatePoFile([])
        setFetchCurrency(true)
        setDisabled(true)
        setSaving(false);
        setsavingAgree(false)
        setsavingAgreeSubmit(false)
        TradeFloorStore.remainingValues = null
        TradeFloorStore.readETRvalue = null
        props.close()
        setInvoiceLoader(true)
        setLoading(true)
    }

    useEffect(() => {
        var po_attach = []
        var attachInvoice = []
        var tempObj = []
        var files = []
        if (props.visible && TradeFloorStore.readETRvalue) {
            po_attach.push({
                uid: '-1',
                name: TradeFloorStore.readETRvalue?.po_attach?.name,
                status: 'done',
                url: defaultAPIURL + TradeFloorStore.readETRvalue?.po_attach?.url,
            })
            attachInvoice.push({
                uid: '-1',
                name: TradeFloorStore.readETRvalue?.file_support?.name,
                status: 'done',
                url: defaultAPIURL + TradeFloorStore.readETRvalue?.file_support?.url,
            })

            if (TradeFloorStore.readETRvalue.vat_included) {
                setVatIncluded(TradeFloorStore.readETRvalue.vat_included === 1 ? true : false)
            }

            if (TradeFloorStore.readETRvalue.b_etr_files && TradeFloorStore.readETRvalue.b_etr_files.length > 0) {
                TradeFloorStore.readETRvalue.b_etr_files.forEach((item, index) => {
                    tempObj.push({
                        po_date: moment(item.po_date),
                        currency_id: item.currency.id,
                        currency_object: item.currency,
                        debtor_id: item.debtor_id,
                        po_face_value: item.po_face_value,
                        po_no: item.po_no,
                        po_file: item.po_file,
                        id: item.id,
                    })
                    files.push({
                        index,
                        files: {
                            uid: '-1',
                            name: item.po_file?.name,
                            status: 'done',
                            url: defaultAPIURL + item.po_file?.url,
                        }
                    })
                })
            }

            form.setFieldsValue({
                etr_type: TradeFloorStore.readETRvalue.etr_type,
                face_value: TradeFloorStore.readETRvalue?.face_value,
                term: TradeFloorStore.readETRvalue?.payment_discount_term,
                payment_discount: TradeFloorStore.readETRvalue?.payment_discount_term,
                debtor_id: TradeFloorStore.readETRvalue?.debtor?.id,
                creditor_id: TradeFloorStore.readETRvalue?.creditor?.id,
                b_etr: TradeFloorStore.readETRvalue?.betr_id,
                betr_id: TradeFloorStore.readETRvalue?.betr_id,
                deliver_to: TradeFloorStore.readETRvalue?.deliver_to && TradeFloorStore.readETRvalue?.deliver_to.id,
                currency_id: TradeFloorStore.readETRvalue?.currency_id,
                invoice_date: moment(TradeFloorStore.readETRvalue?.invoice_date, 'YYYY-MMM-DD'),
                expected_date: moment(TradeFloorStore.readETRvalue?.expected_date),
                po_no: TradeFloorStore.readETRvalue?.po_no,
                invoice_no: TradeFloorStore.readETRvalue?.invoice_no,
                po_attach: TradeFloorStore.readETRvalue.po_attach?.url,
                file_support: TradeFloorStore.readETRvalue.file_support?.url,
                c_etr_term: TradeFloorStore.readETRvalue?.c_etr_term,
                root_invoice_id: TradeFloorStore.readETRvalue?.root_invoice_id,
                po_id: TradeFloorStore.readETRvalue.po && TradeFloorStore.readETRvalue?.po.po_id,
                vat_included: TradeFloorStore.readETRvalue && TradeFloorStore.readETRvalue?.vat_included,
                friendly_name: TradeFloorStore.readETRvalue && TradeFloorStore.readETRvalue.friendly_name,
                description: TradeFloorStore.readETRvalue && TradeFloorStore.readETRvalue.description,
                c_etr_sell_rate: TradeFloorStore.readETRvalue && TradeFloorStore.readETRvalue.c_etr_sell_rate,
                d_etr_purchase_discount: TradeFloorStore.readETRvalue && TradeFloorStore.readETRvalue.d_etr_purchase_discount,
                batch_no: TradeFloorStore.readETRvalue && TradeFloorStore.readETRvalue.batch_no,
                etr_months: TradeFloorStore.readETRvalue && TradeFloorStore.readETRvalue.etr_months,
                a_f_sell_rate: TradeFloorStore.readETRvalue && TradeFloorStore.readETRvalue.a_f_sell_rate,
                start_date: TradeFloorStore.readETRvalue && moment(TradeFloorStore.readETRvalue.start_date, 'YYYY-MM-DD'),
                end_date: TradeFloorStore.readETRvalue && moment(TradeFloorStore.readETRvalue.end_date, 'YYYY-MM-DD'),
                purchase_date: TradeFloorStore.readETRvalue && moment(TradeFloorStore.readETRvalue.purchase_date, 'YYYY-MM-DD'),
                asset_name: TradeFloorStore.readETRvalue && TradeFloorStore.readETRvalue.asset_name,
                asset_number: TradeFloorStore.readETRvalue && TradeFloorStore.readETRvalue.asset_number,
                a_f_value: TradeFloorStore.readETRvalue && TradeFloorStore.readETRvalue.a_f_value,
                quantity_numbers: TradeFloorStore.readETRvalue && TradeFloorStore.readETRvalue.quantity_numbers,
                value: TradeFloorStore.readETRvalue && TradeFloorStore.readETRvalue.face_value,
                asset_classification: TradeFloorStore.readETRvalue && TradeFloorStore.readETRvalue.asset_classification,
                supplier_manufacturer: TradeFloorStore.readETRvalue && TradeFloorStore.readETRvalue.supplier_manufacturer,
                desc: TradeFloorStore.readETRvalue && TradeFloorStore.readETRvalue.desc,
                invoice_po: tempObj
            })
            setFormDataList(Object.keys(TradeFloorStore.readETRvalue))
            updateAttachPoD(po_attach ? po_attach : [])
            updatePoFile(files ? files : [])
            updateAttachInvoice(attachInvoice ? attachInvoice : [])
            setLoading(false)
        }

    }, [TradeFloorStore.readETRvalue, form, props.visible])

    return props.visible ? (
        <Drawer
            visible={props.visible}
            onClose={close}
            placement='right'
            width={'50%'}
            destroyOnClose={true}
            zIndex={AppsAuthStore.list_fullscreen ? 1011 : 1001}
            title={!addAnother ? 'Edit a Trade' : 'Open a Trade'}
            footer={[
                <div className="text-center" key={1}>
                    <Button form='tradeAddEditForm' className="mr-10" loading={savingAgree} htmlType="submit" type="primary" disabled={isDisabled} onClick={() => { setSubmit('2', 1); handleSubmit() }}>I Agree & Submit</Button>
                    <Button form='tradeAddEditForm' className="mr-10" loading={saving} htmlType="submit" type="primary" disabled={isDisabled} onClick={() => { setSubmit('1', 2); handleSubmit() }}>Save</Button>
                    <Button form='tradeAddEditForm' className="mr-10" loading={savingAgreeSubmit} htmlType="submit" type="primary" disabled={isDisabled} onClick={() => { setSubmit('2', 3); handleSubmit() }}>Submit & Add Another</Button>
                    <Button onClick={close}>Cancel</Button>
                </div>
            ]}
        // style={{ left: AppsAuthStore.list_fullscreen ? '0px' : '280px' }}
        >
            <Spin size="default" spinning={loading}>
                <FormComponent
                    form={form}
                    handleSubmit={handleSubmit}
                    setDisabled={setDisabled}
                    setFetchCurrency={setFetchCurrency}
                    fetchCurrency={fetchCurrency}
                    updateAttachPoD={updateAttachPoD}
                    attachPoD={attachPoD}
                    updateAttachInvoice={updateAttachInvoice}
                    attachInvoice={attachInvoice}
                    defaultFormDataList={defaultFormDataList}
                    formDataList={formDataList}
                    setFormDataList={setFormDataList}
                    formType={!addAnother ? 'Edit' : ''}
                    invoiceLoader={invoiceLoader}
                    setInvoiceLoader={setInvoiceLoader}
                    betrLoader={betrLoader}
                    setbetrLoader={setbetrLoader}
                    vatIncluded={vatIncluded}
                    setVatIncluded={setVatIncluded}
                    ISOcodeAlpha2={ISOcodeAlpha2}
                    setISOcodeAlpha2={setISOcodeAlpha2}
                    // b-ETR
                    updateSupportingFile={updateSupportingFile}
                    supportingFile={supportingFile}
                    bETRCurrency={bETRCurrency}
                    fetchbETRCurrency={fetchbETRCurrency}
                    fetchPOFor={fetchPOFor}
                    POFor={POFor}
                    updatePoFile={updatePoFile}
                    poFile={poFile}
                    activeKey={true}
                />
            </Spin>
        </Drawer>
    ) : null;
})

export default EditComponent;