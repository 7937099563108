import { createContext, useContext } from "react"
import AppsAuthStore from "./AppsAuthStore"
import CredebtorsStore from "./Credebtors/CredebtorsStore"
import DashboardStore from "./DashboardStore"
import BanksStore from "./settings/BankStore"
import TradeFloorStore from "./TradeFloor/TradeFloorStore"
import TransactionStore from "./Transactions/TransactionStore"
import UserProfileStore from "./UserProfile/UserProfileStore"

const AppContext = createContext({
	AppsAuthStore: new AppsAuthStore(),
	UserProfileStore: new UserProfileStore(),
	CredebtorsStore: new CredebtorsStore(),
	DashboardStore: new DashboardStore(),
	TradeFloorStore: new TradeFloorStore(),
	TransactionStore: new TransactionStore(),
	BanksStore: new BanksStore()
})

const useStore = () => useContext(AppContext)

export default useStore
