import React from 'react';
import { observer } from 'mobx-react';
import { AgGridReact } from '@ag-grid-community/react'
import { AllModules } from "@ag-grid-enterprise/all-modules"
import { Button } from 'antd';
import { EditOutlined, EyeOutlined } from '@ant-design/icons'
import GridConfig from '../../../../../config/GridConfig';
import { vsmCommon } from '../../../../../config/Originator/AppsMessage';
import useStore from '../../../../../store';
import { DateComparator, globalStatus } from '../../../../../utils/GlobalFunction';

const ListComponent = observer((props) => {
    const { openEditTradeDrawer, openViewTradeDrawer } = props
    const { TradeFloorStore, AppsAuthStore } = useStore()
    const ActionRenderer = (props) => {
        return (
            <div className="action-column">
                {AppsAuthStore.permissions &&
                    <Button
                        title={(AppsAuthStore.checkPermission(1005, 'edit') && (props.data.status === 1)) ? 'Edit Trade' : 'This Trade can not be edited.'}
                        type="text"
                        onClick={() => { openEditTradeDrawer(props.data.id) }}
                        disabled={(AppsAuthStore.checkPermission(1005, 'edit') && (props.data.status === 1) ? false : true)}
                    >
                        <EditOutlined />
                    </Button>
                }
                {AppsAuthStore.permissions && AppsAuthStore.checkPermission(1005, 'show') &&
                    <Button title="View Trade" type="text" onClick={() => { openViewTradeDrawer(props.data.id) }}><EyeOutlined /></Button>
                }
            </div>
        )
    }

    const gridOptions = {
        columnDefs: [{
            headerName: '# ID',
            field: 'id',
            filter: 'agNumberColumnFilter',
            pinned: 'left',
            lockPosition: 'left',
            width: 80
        }, {
            headerName: 'Credebtor ID',
            field: 'debtor.id',
            filter: 'agNumberColumnFilter',
            tooltipField: 'debtor.id',
            width: 110
        }, {
            headerName: 'Credebtor Name',
            field: 'debtor.debtor_name',
            tooltipField: 'debtor.debtor_name',
            width: 185,
        }, {
            headerName: 'Friendly name',
            field: 'friendly_name',
            tooltipField: 'friendly_name',
            width: 185,
        }, {
            headerName: 'Role',
            field: 'type',
            width: 100,
            filter: 'agSetColumnFilter',
            filterParams: {
                defaultToNothingSelected: true,
                buttons: ['apply', 'reset'],
                values: ['Creditor', 'Debtor']
            },
            cellRendererFramework: function (data) {
                return (
                    <span>{data.data.etr_type === "d-ETR" ? 'Debtor' : data.data.etr_type === "c-ETR" ? 'Creditor' : ''}</span>
                )
            }
        }, {
            headerName: 'ETR Type',
            field: 'etr_type',
            tooltipField: 'Type',
            width: 100,
            filter: 'agSetColumnFilter',
            filterParams: {
                defaultToNothingSelected: true,
                buttons: ['apply', 'reset'],
                values: ['a-ETR', 'b-ETR', 'c-ETR', 'd-ETR', 'f-ETR', 'RSA']
            },
        }, {
            headerName: 'Discount',
            field: 'd_etr_purchase_discount',
            tooltipField: 'Discount',
            filter: 'agNumberColumnFilter',
            cellRendererFramework: function (data) {
                return (
                    <div>{data.data.d_etr_purchase_discount ? `${data.data.d_etr_purchase_discount} %` : null}</div>
                )
            },
            type: 'rightAligned',
            width: 120
        }, {
            headerName: 'Sell rate',
            field: 'c_etr_sell_rate',
            tooltipField: 'Sell rate',
            filter: 'agNumberColumnFilter',
            cellRendererFramework: function (data) {
                return (
                    <div>{data.data.c_etr_sell_rate ? `${data.data.c_etr_sell_rate} %` : null}</div>
                )
            },
            type: 'rightAligned',
            width: 120
        }, {
            headerName: 'Number of ETR',
            field: 'etr_no',
            filter: 'agNumberColumnFilter',
            tooltipField: 'Number of ETR',
            width: 120,
            type: 'rightAligned'
        }, {
            headerName: 'Revolving Offer Months',
            field: 'etr_months',
            filter: 'agNumberColumnFilter',
            tooltipField: 'Revolving Offer Months',
            width: 120,
            type: 'rightAligned'
        }, {
            headerName: 'Invoice ID',
            field: 'invoice_no',
            filter: 'agNumberColumnFilter',
            tooltipField: 'Invoice ID',
            width: 120,
            type: 'rightAligned'
        }, {
            headerName: 'POD',
            field: 'po_no',
            filter: 'agNumberColumnFilter',
            tooltipField: 'PO ID',
            width: 120,
            type: 'rightAligned'
        }, {
            headerName: 'Face Value',
            field: 'face_value',
            tooltipField: 'Face Value',
            width: 110,
            type: 'rightAligned'
        }, {
            headerName: 'Currency',
            field: 'currency.currency_code',
            tooltipField: 'Currency',
            width: 100
        }, {
            headerName: 'Invoiced Date',
            field: 'invoice_date',
            tooltipField: 'Invoiced Date',
            filter: 'agDateColumnFilter',
            filterParams: {
                buttons: ['reset'],
                inRangeInclusive: true,
                suppressAndOrCondition: true,
                comparator: DateComparator
            },
            width: 130
        }, {
            headerName: 'Created Date',
            field: 'created_at',
            tooltipField: 'Created Date',
            filter: 'agDateColumnFilter',
            filterParams: {
                buttons: ['reset'],
                inRangeInclusive: true,
                suppressAndOrCondition: true,
                comparator: DateComparator
            },
            width: 150
        }, {
            headerName: 'Status',
            field: 'status',
            tooltipField: 'Status',
            filter: 'agSetColumnFilter',
            filterParams: {
                defaultToNothingSelected: true,
                buttons: ['apply', 'reset'],
                values: ['Draft', 'Awaiting Approval', 'Approved', 'Rejected']
            },
            cellRendererFramework: function (data) {
                return (
                    <span>{globalStatus('trade_list_status', 'key', data.data.status)}</span>
                )
            },
            width: 130
        }, {
            headerName: 'Actions',
            field: 'actions',
            type: 'actionColumn',
            minWidth: 100,
            filter: false,
            sortable: false,
            pinned: 'right',
            lockPosition: 'right',
            width: 100
        }]
    }

    let columns = []
    let columnConfig = localStorage.getItem('trade_floor_grid')
    if (columnConfig) {
        let data = JSON.parse(columnConfig)
        let cols = gridOptions.columnDefs.find(x => !x.field)
        if (cols) {
            columns.push(cols)
        }
        data.forEach(element => {
            cols = gridOptions.columnDefs.find(x => x.field === element.colId)
            if (cols) {
                columns.push(cols)
            }
        });
    } else {
        columns = gridOptions.columnDefs
    }

    return (
        <div className="ag-theme-alpine grid_wrapper">
            <AgGridReact
                modules={AllModules}
                columnDefs={columns}
                defaultColDef={GridConfig.defaultColDef}
                columnTypes={GridConfig.columnTypes}
                overlayNoRowsTemplate={vsmCommon.noRecord}
                frameworkComponents={{
                    ActionRenderer, openEditTradeDrawer, openViewTradeDrawer
                }}
                onGridReady={TradeFloorStore.setupGrid}
                onColumnResized={TradeFloorStore.onGridChanged}
                onColumnMoved={TradeFloorStore.onGridChanged}
                onColumnPinned={TradeFloorStore.onGridChanged}
                gridOptions={GridConfig.options}
                suppressDragLeaveHidesColumns={true}
            />
        </div>
    );
})

export default ListComponent;