import React, { useState } from "react";
import { observer } from "mobx-react";
import { Button, Drawer, Form } from "antd";
import FormComponent from "./FormComponent";
import useStore from "../../../../../store";
import { vsmNotify, vsmTradeFloor } from "../../../../../config/Originator/AppsMessage";
import moment from "moment";

const AddComponent = observer((props) => {
    const [form] = Form.useForm();
    const [saving, setSaving] = useState(false);
    const [savingAgree, setsavingAgree] = useState(false)
    const [savingAgreeSubmit, setsavingAgreeSubmit] = useState(false)
    const [isDisabled, setDisabled] = useState(true);
    const [fetchCurrency, setFetchCurrency] = useState(true);
    const [attachPoD, updateAttachPoD] = useState([]);
    const [attachInvoice, updateAttachInvoice] = useState([]);
    const [position, setPosition] = useState(null)
    const [type, setType] = useState(null)
    const { AppsAuthStore, TradeFloorStore } = useStore();
    const [formDataList, setFormDataList] = useState([])
    const [invoiceLoader, setInvoiceLoader] = useState(true)
    const [betrLoader, setbetrLoader] = useState(true)
    const [vatIncluded, setVatIncluded] = useState(false)
    const [ISOcodeAlpha2, setISOcodeAlpha2] = useState(false)
    const defaultFormDataList = []
    // b-ETR
    const [POFor, fetchPOFor] = useState(true)
    const [bETRCurrency, fetchbETRCurrency] = useState(true)
    const [supportingFile, updateSupportingFile] = useState([])
    const [poFile, updatePoFile] = useState([]);

    const setSubmit = (type, button_posistion) => {
        setPosition(button_posistion)
        setType(type)
    }

    const handleSubmit = (data) => {
        if (data) {
            if (![1, 5].includes(data.etr_type)) {
                data.expected_date = typeof (data.expected_date) === 'string' ? data.expected_date : moment(data.expected_date && data.expected_date).format("YYYY-MM-DD")
                if (![2].includes(data.etr_type)) {
                    data.invoice_date = typeof (data.invoice_date) === 'string' ? data.invoice_date : moment(data.invoice_date && data.invoice_date).format("YYYY-MM-DD")
                }
            } else {
                data.start_date = moment(data.start_date).format("YYYY-MM-DD")
                data.end_date = moment(data.end_date).format("YYYY-MM-DD")
                data.purchase_date = moment(data.purchase_date).format("YYYY-MM-DD")
            }
            if (position === 1) {
                setsavingAgree(true)
            } else if (position === 2) {
                setSaving(true)
            } else {
                setsavingAgreeSubmit(true)
            }
            if (ISOcodeAlpha2) {
                data.vat_included = (vatIncluded === true) ? 1 : 0;
            }
            if (data.deliver_to === false) {
                delete data.deliver_to
            }
            var formData = TradeFloorStore.JsonToFormData(data)
            formData.append('status', type)

            if (![1, 5].includes(data.etr_type)) {
                formData.append("po_details", data.invoice_po && data.invoice_po.length > 0 ? 1 : 0)
            }

            if (attachPoD && attachPoD.length > 0) {
                formData.append("po_attach", attachPoD[0].originFileObj);
            } else {
                formData.delete("po_attach")
            }
            if ([1, 5].includes(data.etr_type)) {
                if (supportingFile && supportingFile.length > 0) {
                    formData.append("file_support", supportingFile[0].originFileObj);
                } else {
                    formData.delete("file_support")
                }
            } else if (data.etr_type !== 2) {
                if (attachInvoice && attachInvoice.length > 0) {
                    formData.append("file_support", attachInvoice[0].originFileObj);
                } else {
                    formData.delete("file_support")
                }
            } else {
                if (supportingFile && supportingFile.length > 0) {
                    formData.append("file_support", supportingFile[0].originFileObj);
                } else {
                    formData.delete("file_support")
                }
            }
            data.invoice_po && data.invoice_po.length > 0 && data.invoice_po.forEach((item, index) => {
                formData.append(`invoice_po[${index}][debtor_id]`, item.debtor_id)
                formData.append(`invoice_po[${index}][currency_id]`, item.currency_id)
                formData.append(`invoice_po[${index}][po_file]`, item.po_file.fileList[0].originFileObj)
                formData.append(`invoice_po[${index}][po_face_value]`, item.po_face_value)
                formData.append(`invoice_po[${index}][po_date]`, moment(item.po_date).format('YYYY-MM-DD'))
                formData.append(`invoice_po[${index}][po_no]`, item.po_no)
            })

            TradeFloorStore.AddTrade(formData).then(() => {
                TradeFloorStore.remainingValues = null
                setVatIncluded(false)
                if (position === 3) {
                    form.resetFields()
                    vsmNotify.success({
                        message: data.etr_type === 1 ? 'a' + vsmTradeFloor.submitETR : data.etr_type === 2 ? 'b' + vsmTradeFloor.submitETR : data.etr_type === 3 ? 'c' + vsmTradeFloor.submitETR : data.etr_type === 4 ? 'd' + vsmTradeFloor.submitETR : data.etr_type === 5 ? 'f' + vsmTradeFloor.submitETR : null
                    })
                } else if (position === 2) {
                    vsmNotify.success({
                        message: data.etr_type === 1 ? 'a' + vsmTradeFloor.addETR : data.etr_type === 2 ? 'b' + vsmTradeFloor.addETR : data.etr_type === 3 ? 'c' + vsmTradeFloor.addETR : data.etr_type === 4 ? 'd' + vsmTradeFloor.addETR : data.etr_type === 5 ? 'f' + vsmTradeFloor.addETR : null
                    })
                    close()
                } else {
                    vsmNotify.success({
                        message: data.etr_type === 1 ? 'a' + vsmTradeFloor.submitETR : data.etr_type === 2 ? 'b' + vsmTradeFloor.submitETR : data.etr_type === 3 ? 'c' + vsmTradeFloor.submitETR : data.etr_type === 4 ? 'd' + vsmTradeFloor.submitETR : data.etr_type === 5 ? 'f' + vsmTradeFloor.submitETR : null
                    })
                    close()
                }
            }).catch(e => {
                if (e.errors) { form.setFields(e.errors) }
            }).finally(() => {
                setSaving(false)
                setsavingAgree(false)
                setsavingAgreeSubmit(false)
                setDisabled(true)
            })
        }
    };

    const close = () => {
        form.resetFields();
        updateAttachInvoice([])
        updateSupportingFile([])
        updatePoFile([])
        updateAttachPoD([])
        setFetchCurrency(true)
        setDisabled(false)
        setSaving(false);
        setsavingAgree(false)
        setsavingAgreeSubmit(false)
        props.close();
        setInvoiceLoader(true)
        TradeFloorStore.uploadFor_list = null
    };

    return (
        <Drawer
            visible={props.visible}
            onClose={close}
            placement="right"
            width={"50%"}
            destroyOnClose={true}
            zIndex={AppsAuthStore.list_fullscreen ? 1011 : 1001}
            title="Open a Trade"
            footer={[
                <div className="text-center" key={1}>
                    <Button
                        form="tradeAddEditForm"
                        className="mr-10"
                        loading={savingAgree}
                        htmlType="submit"
                        type="primary"
                        disabled={isDisabled}
                        onClick={() => { setSubmit('2', 1); handleSubmit() }}
                    >
                        I Agree & Submit
                    </Button>
                    <Button
                        form="tradeAddEditForm"
                        className="mr-10"
                        loading={saving}
                        htmlType="submit"
                        type="primary"
                        disabled={isDisabled}
                        onClick={() => { setSubmit('1', 2); handleSubmit() }}
                    >
                        Save
                    </Button>
                    <Button
                        form="tradeAddEditForm"
                        className="mr-10"
                        loading={savingAgreeSubmit}
                        htmlType="submit"
                        type="primary"
                        disabled={isDisabled}
                        onClick={() => { setSubmit('2', 3); handleSubmit() }}
                    >
                        Submit & Add Another
                    </Button>
                    <Button onClick={close}>Cancel</Button>
                </div>,
            ]}
        // style={{ left: AppsAuthStore.list_fullscreen ? '0px' : '280px' }}
        >
            <FormComponent
                form={form}
                handleSubmit={handleSubmit}
                setDisabled={setDisabled}
                setFetchCurrency={setFetchCurrency}
                fetchCurrency={fetchCurrency}
                updateAttachPoD={updateAttachPoD}
                attachPoD={attachPoD}
                updateAttachInvoice={updateAttachInvoice}
                attachInvoice={attachInvoice}
                defaultFormDataList={defaultFormDataList}
                formDataList={formDataList}
                setFormDataList={setFormDataList}
                formType='Add'
                invoiceLoader={invoiceLoader}
                setInvoiceLoader={setInvoiceLoader}
                betrLoader={betrLoader}
                setbetrLoader={setbetrLoader}
                vatIncluded={vatIncluded}
                setVatIncluded={setVatIncluded}
                ISOcodeAlpha2={ISOcodeAlpha2}
                setISOcodeAlpha2={setISOcodeAlpha2}
                // b-ETR
                updateSupportingFile={updateSupportingFile}
                supportingFile={supportingFile}
                bETRCurrency={bETRCurrency}
                fetchbETRCurrency={fetchbETRCurrency}
                fetchPOFor={fetchPOFor}
                POFor={POFor}
                updatePoFile={updatePoFile}
                poFile={poFile}
                activeKey={true}
            />
        </Drawer>
    );
});

export default AddComponent;
