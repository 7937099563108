import Axios from "axios"
import { action, decorate, observable } from "mobx"
import GridConfig from "../../config/GridConfig"
import { vsmNotify } from "../../config/Originator/AppsMessage";
import { convertError, convertTextToID, etr_type, cETRwithoutBETR } from "../../utils/GlobalFunction";

export default class TradeFloorStore {
    per_page = GridConfig.options.paginationPageSize
    current_page = 1
    total = 0
    agGrid = null
    uploadFor_list = null
    btrList = null
    readETRvalue = null
    ETR_loan_term = null
    remainingValues = null
    dropdown_currency_list = null
    debtor_list = null
    permissionETRs = []
    apicalled = false
    initialValues = {
        payment_discount: 0.000,
        invoice_po: [{}]
    }
    etr_type = [
        { id: 1, etr_type: 'a-ETR' },
        { id: 2, etr_type: 'b-ETR' },
        { id: 3, etr_type: 'c-ETR' },
        { id: 4, etr_type: 'd-ETR' },
        { id: 5, etr_type: 'f-ETR' },
        { id: 6, etr_type: 'RSA' }
    ]
    filter_status = [
        { id: 1, status_name: 'Draft' },
        { id: 2, status_name: 'Awaiting Approval' },
        { id: 3, status_name: 'Approved' },
        { id: 4, status_name: 'Rejected' },
    ]

    ETRList = (ETRs) => {
        return ([
            ETRs.includes('a-ETR') && { id: 1, etr_type: 'a-ETR' },
            ETRs.includes('b-ETR') && { id: 2, etr_type: 'b-ETR' },
            ETRs.includes('c-ETR') && { id: 3, etr_type: 'c-ETR' },
            ETRs.includes('d-ETR') && { id: 4, etr_type: 'd-ETR' },
            ETRs.includes('f-ETR') && { id: 5, etr_type: 'f-ETR' }
        ])
    }

    // change page size, default page size is GridConfig.options.paginationPageSize
    setPageSize = (page = GridConfig.options.paginationPageSize) => {
        this.per_page = page
        this.agGrid.api.paginationSetPageSize(parseInt(page))
    }

    handleReset = () => {
        localStorage.removeItem('trade_floor_grid')
        this.agGrid.api.setFilterModel(null)
        this.agGrid.api.setSortModel(null)
        this.agGrid.columnApi.resetColumnState()
        this.agGrid.api.onFilterChanged(null)
    }

    // Set column width after resizing colums
    onGridChanged = (params) => {
        localStorage.setItem('trade_floor_grid', JSON.stringify(params.columnApi.getColumnState()))
    }

    // Setup grid and set column size to autosize

    setupGrid = (params) => {
        this.agGrid = params
        const { api } = params
        let statusFilter;
        statusFilter = {
            "etr_type":
            {
                "values": localStorage.getItem('selectedFilter') ? [localStorage.getItem('selectedFilter')] : [],
                "filterType": "set",
            }
        }
        const filter = { ...statusFilter };
        this.agGrid.api.setFilterModel(filter);
        var datasource = this.createDatasource(GridConfig.options)
        api.setServerSideDatasource(datasource)
    }

    // Create data source to display record in table
    createDatasource = (gridOptions) => {
        return {
            gridOptions,
            getRows: (params) => {
                let columnConfig = localStorage.getItem('trade_floor_grid')
                if (columnConfig) {
                    this.onGridChanged(params)
                }
                var filter_data = { final_filter: params.request.filterModel, final_sort: params.request.sortModel }

                if (filter_data.final_filter['etr_type']) {
                    filter_data.final_filter['etr_type'].values = convertTextToID(filter_data.final_filter['etr_type'], this.etr_type, 'etr_type', 'id')
                }

                if (filter_data.final_filter['status']) {
                    filter_data.final_filter['status'].values = convertTextToID(filter_data.final_filter['status'], this.filter_status, 'status_name', 'id')
                }

                var payload = {
                    filter_data: filter_data.final_filter,
                    sort_data: filter_data.final_sort,
                    per_page: params.request.endRow - params.request.startRow,
                    page: Math.ceil((params.request.startRow + 1) / (params.request.endRow - params.request.startRow)),
                    etr_type: [1, 2, 3, 4, 5, 6],
                }
                this.getList(payload).then((data) => {
                    if (data.total === 0) { this.agGrid.api.showNoRowsOverlay() }
                    else { this.agGrid.api.hideOverlay() }
                    params.successCallback(data.data, data.total)
                    let columnConfig = localStorage.getItem('trade_floor_grid')
                    if (this.agGrid && this.agGrid.columnApi && columnConfig) {
                        if (JSON.stringify(this.agGrid.columnApi.getColumnState()) !== columnConfig) {
                            this.agGrid.columnApi.applyColumnState({ state: JSON.parse(columnConfig) })
                        }
                    }
                })
            }
        }
    }

    getList = (payload = {}) => {
        return Axios.post(`trading/trade/list`, payload).then(({ data }) => {
            if (data.data.length) {
                data.data.forEach((item) => {
                    item.etr_type = etr_type[item.etr_type]
                })
            }
            this.list_data = data.data
            this.total = data.total
            this.current_page = data.current_page
            return data
        })
    }

    getUploadFor = (type) => {
        return Axios.post('trading/trade/get/organisations', { type: type }).then(({ data }) => {
            this.uploadFor_list = data.data
            return data
        }).catch(() => {
            this.uploadFor_list = null
        })
    }

    getTermLoan = (etr_type) => {
        let payload = {
            etr_type: etr_type
        }
        return Axios.post('get/etr/loan-agreement', payload).then(data => {
            this.ETR_loan_term = data.data
        }).catch(() => {
            this.ETR_loan_term = null
        })
    }

    getRemainingValues = (payload) => {
        return Axios.post(`trading/trade/get/remain-facevalue/${payload.debtor_id}`, payload).then(({ data }) => {
            this.remainingValues = data.data.remaing_value
            return data.data.remaing_value
        }).catch(() => {
            this.remainingValues = null
        })
    }

    getBETR = () => {
        return Axios.get('get/betr').then(({ data }) => {
            this.btrList = data.data
            this.btrList.push(cETRwithoutBETR)
            return data
        }).catch(() => {
            this.btrList = null
        })
    }

    AddTrade = (formData) => {
        return Axios.post('trading/trade/add', formData).then(({ data }) => {
            if (this.setupGrid) {
                this.setupGrid(this.agGrid)
            }
            return data
        }).catch(({ response: { data } }) => {
            var errors = [];
            Object.keys(data.errors).forEach(name => {
                errors.push({ name, errors: data.errors[name] })
            })
            data.errors = convertError(errors)
            return Promise.reject(data)
        })
    }

    readTrade = (id) => {
        return Axios.get(`trading/trade/read/${id}`).then(({ data }) => {
            this.readETRvalue = data.data
            return data.data
        }).catch(({ response: { data } }) => {
            vsmNotify.error({
                message: data.message
            })
        })
    }

    EditTrade = (data) => {
        return Axios.post(`trading/trade/update/${this.readETRvalue.id}`, data).then(({ data }) => {
            if (this.setupGrid) {
                this.setupGrid(this.agGrid)
            }
            return data
        }).catch(({ response: { data } }) => {
            var errors = [];
            Object.keys(data.errors).forEach(name => {
                errors.push({ name, errors: data.errors[name] })
            })
            data.errors = convertError(errors)
            return Promise.reject(data)
        })
    }

    getCurrencyList = () => {
        return Axios.get(`globalcurreny/ledger/currency`).then(({ data }) => {
            this.dropdown_currency_list = data.data
            return data
        }).catch(() => {
            this.dropdown_currency_list = null
        })
    }

    getDebtorList = (type) => {
        return Axios.post('trading/trade/rsa/get/organisations', { type: type }).then(({ data }) => {
            this.debtor_list = data.data
            return data
        })
    }

    JsonToFormData = (data) => {
        const formData = new FormData();
        Object.keys(data).forEach(key => {
            if (!['status', 'po_attach', 'file_support'].includes(key)) {
                formData.append(key, (data[key] !== undefined && data[key] !== null) ? data[key] : '')
            }
        })
        return formData
    }

    getAFOrg = () => {
        return Axios.get('get/credebtor').then(({ data }) => {
            this.uploadFor_list = data.data
            return data
        }).catch(() => {
            this.uploadFor_list = null
        })
    }
}

decorate(TradeFloorStore, {
    per_page: observable,
    current_page: observable,
    total: observable,
    agGrid: observable,
    readETRvalue: observable,
    uploadFor_list: observable,
    ETR_loan_term: observable,
    remainingValues: observable,
    dropdown_currency_list: observable,
    debtor_list: observable,
    apicalled: observable,
    setPageSize: action,
    handleReset: action,
    setupGrid: action,
    onGridChanged: action,
    readTrade: action,
    EditTrade: action,
    getTermLoan: action,
    getRemainingValues: action,
    getCurrencyList: action,
    getAFOrg: action
})