import { action, decorate, observable } from "mobx"
import Axios from "axios"
import { vsmNotify } from "../config/Originator/AppsMessage"

export default class DashboardStore {
    dashboardData = null
    credebtorType = null

    // set Credebtor Type
    setCredebtorType = (type) => {
        this.credebtorType = type
    }

    getDashboardData = () => {
        Axios.get('get/dashboard').then(({ data }) => {
            this.dashboardData = data.data
        }).catch((err) => {
            vsmNotify.error({ message: err.message })
        })
    }
}

decorate(DashboardStore, {
    dashboardData: observable,
    credebtorType: observable,
    setCredebtorType: action,
    getDashboardData: action
})