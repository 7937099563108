import Axios from "axios";
import { action, decorate, observable } from "mobx";
import GridConfig from "../../config/GridConfig";
import { convertError, convertTextToID } from "../../utils/GlobalFunction";

export default class CredebtorsStore {
    per_page = GridConfig.options.paginationPageSize
    current_page = 1
    total = 0
    agGrid = null
    list_data = null
    read_data = null
    role_dd_list = null
    statusList = null
    dropdown_bank_type_list = null
    delete_values = null
    view_values = null
    industries_list = null
    dropdown_countries_list = null
    initialValues = {
        phone: [{ id: null, contact_for: 'Primary' }],
        email: [{ id: null, contact_for: 'Primary' }],
        banks: [],
        adddress: null,
    }

    // set delete values
    setDeleteValues = (data) => {
        this.delete_values = data
    }

    // change page size, default page size is GridConfig.options.paginationPageSize
    setPageSize = (page = GridConfig.options.paginationPageSize) => {
        this.per_page = page
        this.agGrid.api.paginationSetPageSize(parseInt(page))
    }

    handleReset = () => {
        localStorage.removeItem('Credebtors_grid')
        this.agGrid.api.setFilterModel(null)
        this.agGrid.api.setSortModel(null)
        this.agGrid.columnApi.resetColumnState()
        this.agGrid.api.onFilterChanged(null)
    }

    // call api to get organisations roles list
    getStatusList = () => {
        return Axios.get(`organisations/status/get`).then(({ data }) => {
            this.statusList = data.data
            return data
        })
    }

    // Set column width after resizing colums
    onGridChanged = (params) => {
        localStorage.setItem('Credebtors_grid', JSON.stringify(params.columnApi.getColumnState()))
    }

    // Setup grid and set column size to autosize
    setupGrid = (params) => {
        this.agGrid = this.agGridServer = params
        const { api } = params
        let columnConfig = localStorage.getItem('Credebtors_grid')
        if (this.agGrid && this.agGrid.columnApi && columnConfig) {
            this.agGrid.columnApi.applyColumnState({ state: JSON.parse(columnConfig) })
        }
        let statusFilter;
        statusFilter = {
            "role.role_name":
            {
                "values": localStorage.getItem('selectedFilter') ? [localStorage.getItem('selectedFilter')] : [],
                "filterType": "set",
            }
        }
        const filter = { ...statusFilter };
        this.agGrid.api.setFilterModel(filter);
        var datasource = this.createDatasource(GridConfig.options)
        api.setServerSideDatasource(datasource)
    }

    // Create data source to display record in table
    createDatasource = (gridOptions) => {
        return {
            gridOptions,
            getRows: (params) => {
                let columnConfig = localStorage.getItem('Credebtors_grid')
                if (columnConfig) {
                    this.onGridChanged(params)
                }

                var filter_data = { final_filter: params.request.filterModel, final_sort: params.request.sortModel }
                if (filter_data.final_filter['organisation.global_industries.name']) {
                    filter_data.final_filter['organisation.global_industries.name'].values = convertTextToID(filter_data.final_filter['organisation.global_industries.name'], this.industries_list, 'name', 'id')
                }

                if (filter_data.final_filter['organisation.global_countries.country_name']) {
                    filter_data.final_filter['organisation.global_countries.country_name'].values = convertTextToID(filter_data.final_filter['organisation.global_countries.country_name'], this.dropdown_countries_list, 'country_name', 'id')
                }

                var payload = {
                    filter_data: filter_data.final_filter,
                    sort_data: filter_data.final_sort,
                    per_page: params.request.endRow - params.request.startRow,
                    page: Math.ceil((params.request.startRow + 1) / (params.request.endRow - params.request.startRow))
                }
                this.getList(payload).then((data) => {
                    if (data.total === 0) { this.agGrid.api.showNoRowsOverlay() }
                    else { this.agGrid.api.hideOverlay() }
                    params.successCallback(data.data, data.total)
                    let columnConfig = localStorage.getItem('Credebtors_grid')
                    if (this.agGrid && this.agGrid.columnApi && columnConfig) {
                        if (JSON.stringify(this.agGrid.columnApi.getColumnState()) !== columnConfig) {
                            this.agGrid.columnApi.applyColumnState({ state: JSON.parse(columnConfig) })
                        }
                    }
                })
            }
        }
    }

    getList = (payload = {}) => {
        return Axios.post(`credebtor/all`, payload).then(({ data }) => {
            if (data.data.length) {
                data.data.forEach((item, index) => {
                    item.credebt_class = item.organisation && item.organisation.global_industries && item.organisation.global_industries.name
                    item.status = 'Active'
                })
            }
            this.list_data = data.data
            this.total = data.total
            this.current_page = data.current_page
            return data
        })
    }

    AddData = (formData) => {
        return Axios.post(`credebtor/all/add`, formData).then(({ data }) => {
            if (this.agGrid) {
                this.setupGrid(this.agGrid)
            }
            return data
        }).catch(({ response: { data } }) => {
            var errors = []
            Object.keys(data.errors).forEach(name => {
                errors.push({ name, errors: data.errors[name] })
            })
            data.errors = convertError(errors)
            return Promise.reject(data)
        })
    }

    EditData = (formData, id) => {
        return Axios.post(`credebtor/all/edit/${id}`, formData).then(({ data }) => {
            if (this.agGrid) {
                this.setupGrid(this.agGrid)
            }
            return data
        }).catch(({ response: { data } }) => {
            var errors = []
            Object.keys(data.errors).forEach(name => {
                errors.push({ name, errors: data.errors[name] })
            })
            data.errors = convertError(errors)
            return Promise.reject(data)
        })
    }

    Deletedata = (id) => {
        return Axios.get(`credebtor/all/delete/${id}`).then(({ data }) => {
            if (this.agGrid) {
                this.setupGrid(this.agGrid)
            }
            return data
        }).catch(({ response: { data } }) => {
            var errors = []
            Object.keys(data.errors).forEach(name => {
                errors.push({ name, errors: data.errors[name] })
            })
            data.errors = errors
            return Promise.reject(data)
        })
    }

    getRecordDetails = (id, type) => {
        return Axios.get(`credebtor/all/read/${id}`).then(({ data }) => {
            if (type) {
                this.view_values = data
                this.read_data = data
            } else {
                this.read_data = data
            }
            return data.data
        }).catch(() => {
            this.read_data = null
            this.view_values = null
        })
    }

    // call for bank account types list
    getBankAccountTypeList = () => {
        return Axios.get(`bankaccount/bankAccountType`).then(({ data }) => {
            this.dropdown_bank_type_list = data.data
            return data
        }).catch(() => {
            this.dropdown_bank_type_list = null
        })
    }

    JsonToFormData = (data) => {
        const formData = new FormData();
        Object.keys(data).forEach(key => {
            if (!['logo_url', 'experience_file', 'counter_party_since'].includes(key)) {
                formData.append(key, (data[key] !== undefined && data[key] !== null) ? data[key] : '')
            }
        })
        return formData
    }

    // get Industries list
    getIndustries = () => {
        return Axios.get(`organisations/global/industries`).then(({ data }) => {
            this.industries_list = data.data
            return data
        })
    }

    // call api to get all countries list
    getAllCountries = () => {
        return Axios.get(`organisations/get/countries`).then(({ data }) => {
            this.dropdown_countries_list = data.data
            return data
        })
    }
}

decorate(CredebtorsStore, {
    per_page: observable,
    current_page: observable,
    total: observable,
    role_dd_list: observable,
    initialValues: observable,
    agGrid: observable,
    statusList: observable,
    list_data: observable,
    read_data: observable,
    dropdown_bank_type_list: observable,
    delete_values: observable,
    industries_list: observable,
    dropdown_countries_list: observable,
    view_values: observable,
    setPageSize: action,
    handleReset: action,
    getStatusList: action,
    onGridChanged: action,
    setupGrid: action,
    createDatasource: action,
    getList: action,
    AddData: action,
    EditData: action,
    Deletedata: action,
    JsonToFormData: action,
    getRecordDetails: action,
    getBankAccountTypeList: action,
    setDeleteValues: action,
    getIndustries: action,
    getAllCountries: action,
    setViewValues: action,
})