import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import useStore from '../../store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import logo from '../../img/logo.png';
import logo_bull from '../../img/logo_bull.png';
import { faCircle } from '@fortawesome/free-solid-svg-icons'
import { Layout, Menu } from 'antd';
import Dashboard from '../../assets/Dashboard/Dashboard.svg'
import Trading from '../../assets/Dashboard/Trading.svg'
import Credebtor from '../../assets/Dashboard/Credebtor.svg'
import Transactions from '../../assets/Dashboard/Transactions.svg'
import Reports from '../../assets/Dashboard/Reports.svg'
import Statements from '../../assets/Dashboard/Statements.svg'
import Unallocated_Payments from '../../assets/Dashboard/Unallocated_Payments.svg'
import Outstanding_ETR from '../../assets/Dashboard/Outstanding_ETR.svg'
import Settled_ETR from '../../assets/Dashboard/Settled_ETR.svg'
import Overdue_ETR from '../../assets/Dashboard/Overdue_ETR.svg'
import Reserves_Due from '../../assets/Dashboard/Reserves_Due.svg'
import users from '../../assets/Dashboard/Users.svg'
import Settings from '../../assets/Dashboard/Settings.svg'

const { Sider } = Layout;

const AppsSidebarComponent = observer((props) => {
    const location = useLocation()
    const { AppsAuthStore, AppsAuthStore: { originator_user, apps_ledger_base_currency } } = useStore()
    const [openMenu, setOpenMenu] = useState([])
    let menukey = 1
    const icons = {
        1001: Dashboard,
        1002: Trading,
        1006: Credebtor,
        1011: Transactions,
        1012: Reports,
        1013: Statements,
        1014: Unallocated_Payments,
        1015: Outstanding_ETR,
        1016: Settled_ETR,
        1017: Overdue_ETR,
        1018: Reserves_Due,
        1019: users,
        1022: Settings,
    }

    const AppMenu = (menu_item, open = []) => {
        return menu_item ? Object.keys(menu_item)
            .sort((a, b) => (menu_item[a].sequence_no > menu_item[b].sequence_no) ? 1 : ((menu_item[b].sequence_no > menu_item[a].sequence_no) ? -1 : 0))
            .map((key) => {
                let item = menu_item[key]
                if (item.submenu) {
                    return (
                        <>
                            <Menu.SubMenu key={item.title + menukey} icon={icons[item.id] ? <img src={icons[item.id]} alt='' /> : <FontAwesomeIcon icon={faCircle} size="xs" />} title={item.title}>
                                {AppMenu(item.submenu, [...open, (item.title + menukey++)])}
                            </Menu.SubMenu>
                        </>
                    )
                } else {
                    if (originator_user && originator_user.user_role && originator_user.user_role.user_role_id !== 1 && [].includes(item.path)) {
                        return null
                    }
                    // if (location.pathname.includes(item.path) && openMenu.length <= 0) {
                    if (location.pathname === item.path && openMenu.length <= 0) {
                        if (open.length === 0) {
                            open.push(item.path)
                        }
                        setOpenMenu(open)
                    }
                    return (
                        <>
                            <Menu.Item className='customeClass' key={item.path} icon={icons[item.id] ? <img src={icons[item.id]} alt='' /> : <FontAwesomeIcon icon={faCircle} size="10px" />}>
                                <span style={{ marginLeft: 10 }}>{item.title}</span>
                                {(apps_ledger_base_currency) && <Link to={item.path} />}
                            </Menu.Item>
                        </>
                    )
                }
            }) : null
    }
    return (
        <Sider width="280" trigger={null} collapsible collapsed={props.collapsed} className="sider_area">
            {props.collapsed ? (<Link to="/apps/dashboard" className="logo">
                <img src={logo_bull} alt="" />
            </Link>) : (<Link to="/apps/dashboard" className="logo"><img src={logo} alt="" /></Link>)}

            <Menu theme="dark" mode="inline" defaultOpenKeys={openMenu} selectedKeys={location.pathname}>
                {AppMenu(AppsAuthStore.apps_menu)}
            </Menu>
        </Sider>
    );
})

export default AppsSidebarComponent;