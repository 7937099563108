import React, { useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { observer } from 'mobx-react'
import AppsBreadcrumbComponent from '../../../components/Originator/AppsBreadcrumbComponent'
import { AppsBreadcrumbConfig } from '../../../config/BreadcrumbConfig'
import useStore from '../../../store'
import Unauthorized from '../../Unauthorized'
import RecordPerPage from '../../../components/RecordPerPage'
import EnterFullscreen from '../../../components/EnterFullscreen'
import ListComponent from './components/ListComponent'
import { Button, PageHeader } from 'antd'
import AddTransaction from './components/AddComponent'
import ViewComponent from './components/ViewComponent'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUndo } from '@fortawesome/free-solid-svg-icons'

const Transactions = observer(() => {
	const history = useHistory()
	const { AppsAuthStore, TransactionStore } = useStore()
	const [addTransaction, setAddTransaction] = useState(useLocation().search === '?open-transactions-popup=1')
	const [viewTransaction, setviewTransaction] = useState(false)

	history.listen((location, action) => {
		if (location.search === '?open-transactions-popup=1') {
			setAddTransaction(true)
		} else {
			setAddTransaction(false)
		}
	})

	const exportCSV = () => {
		TransactionStore.agGrid.api.exportDataAsCsv()
	}

	const exportPDF = () => {
		TransactionStore.agGrid.api.exportDataAsExcel()
	}

	const openTransactionDrawer = () => {
		setAddTransaction(true)
	}

	const closeAddTransactionDrawer = () => {
		setAddTransaction(false)
		history.replace(history.location.pathname)
	}

	const openViewTransactionDrawer = (id) => {
		setviewTransaction(true)
		TransactionStore.readTransaction(id)
	}

	const closeViewTransactionDrawer = () => {
		setviewTransaction(false)
	}

	return (
		<>
			<AppsBreadcrumbComponent items={AppsBreadcrumbConfig.Transactions.path} />
			{AppsAuthStore.permissions && (!AppsAuthStore.checkPermission(1026, 'list')) ? (<Unauthorized />) : (
				<PageHeader
					className={AppsAuthStore.fullscreen_class}
					title={AppsBreadcrumbConfig.Transactions.title}
					extra={[
						(AppsAuthStore.checkPermission(1026, 'add')) && <Button key="1" onClick={openTransactionDrawer}>Add New Transaction</Button>,
						(AppsAuthStore.checkPermission(1026, 'list')) && <Button key='2' onClick={exportCSV}>Export CSV</Button>,
						(AppsAuthStore.checkPermission(1026, 'list')) && <Button key='3' onClick={exportPDF}>Export XlSX</Button>,
						<RecordPerPage key="4" defaultValue={TransactionStore.per_page + ' per page'} onChange={TransactionStore.setPageSize} />,
						<Button key="5" title="Reset" onClick={TransactionStore.handleReset}><FontAwesomeIcon icon={faUndo} /></Button>,
						<EnterFullscreen key='6' />
					]}
				>
					<ListComponent openViewTransactionDrawer={openViewTransactionDrawer} />
					{addTransaction && <AddTransaction visible={addTransaction} close={closeAddTransactionDrawer} />}
					{viewTransaction && <ViewComponent visible={viewTransaction} close={closeViewTransactionDrawer} />}
				</PageHeader>
			)}
		</>
	)
})

export default Transactions;