import React, { useState } from 'react';
import { observer } from 'mobx-react';
import InputComponent from '../../../components/InputComponent';
import { Form, Button, Popover, Modal, Row, Col } from 'antd';
import { vsmChangePass, vsmNotify } from '../../../config/Originator/AppsMessage';
import useStore from '../../../store';
import ManWithGlasses from '../../../assets/images/ManWithGlasses.png'

const ChangePassword = observer((props) => {
    const [form] = Form.useForm()
    const { UserProfileStore, AppsAuthStore } = useStore()
    const [saving, setSaving] = useState(false)
    const [currentPassTooltip, setCurrentPassTooltip] = useState(false)
    const [newPassTooltip, setNewPassTooltip] = useState(false)
    const [confmNewPassTooltip, setConfmNewPassTooltip] = useState(false)

    const handleSubmit = (data) => {
        setSaving(true)
        UserProfileStore.changePassword(data).then(() => {
            onCancle()
            vsmNotify.success({
                message: vsmChangePass.add
            })
        }).catch(e => {
            if (e.errors) { form.setFields(e.errors) }
        }).finally(() => setSaving(false))
    }

    // password criteria tool tip
    const passwordTooltip = (
        <div>
            <div>at least 1 numeric character</div>
            <div>at least 1 special character</div>
            <div>at least 1 uppercase letter</div>
            <div>at least 8  character</div>
        </div>
    );

    // handle password tool tip visiblility
    const handleChangeCurrentPassword = (e) => {
        form.validateFields(['current_password']).then(() => {
            setCurrentPassTooltip(false)
        }).catch(() => {
            setCurrentPassTooltip(true)
        })
    }

    // handle password tool tip visiblility
    const handleChangeNewPassword = (e) => {
        form.validateFields(['password']).then(() => {
            setNewPassTooltip(false)
        }).catch(() => {
            setNewPassTooltip(true)
        })
    }

    // handle password tool tip visiblility
    const handleChangeConfirmPassword = (e) => {
        form.validateFields(['confirm_password']).then(() => {
            setConfmNewPassTooltip(false)
        }).catch(() => {
            setConfmNewPassTooltip(true)
        })
    }

    const onCancle = () => {
        form.resetFields()
        props.close()
    }

    return (
        <Modal
            centered
            visible={props.visible}
            onCancel={onCancle}
            destroyOnClose={true}
            cancelButtonProps={{ style: { display: 'none' } }}
            okButtonProps={{ style: { display: 'none' } }}
            width={'45%'}
            className='change_pass_body'
            footer={false}
        >
            <div className='main__change_pass_wrapper'>
                <div className='modal-header'>
                    <span className='modal-header-text'>Change Password</span>
                </div>
                <Row className='change_pass_body_inner' gutter={30}>
                    <Col span={12} className='align-self-center'>
                        <img src={ManWithGlasses} alt='' />
                    </Col>

                    <Col span={12} className='align-self-center'>
                        <Form
                            form={form}
                            layout='vertical'
                            id="editform"
                            onFinish={handleSubmit}
                        >
                            <Popover placement="topRight" content={passwordTooltip} visible={currentPassTooltip} className='mb-3'>
                                <InputComponent
                                    required
                                    label='Current Password'
                                    placeholder='Current Password'
                                    type='password'
                                    name='current_password'
                                    onChange={handleChangeCurrentPassword}
                                    onFocus={handleChangeCurrentPassword}
                                    rules={vsmChangePass.validation.old_password}
                                    onBlur={() => setCurrentPassTooltip(false)}
                                />
                            </Popover>

                            <Popover placement="topRight" content={passwordTooltip} visible={newPassTooltip} className='mb-3'>
                                <InputComponent
                                    required
                                    label='New Password'
                                    placeholder='New Password'
                                    type='password'
                                    name='password'
                                    onChange={handleChangeNewPassword}
                                    onFocus={handleChangeNewPassword}
                                    rules={vsmChangePass.validation.new_password}
                                    onBlur={() => setNewPassTooltip(false)}
                                />
                            </Popover>

                            <Popover placement="topRight" content={passwordTooltip} visible={confmNewPassTooltip} className='mb-3'>
                                <InputComponent
                                    required
                                    label='Confirm New Password'
                                    placeholder='Confirm New Password'
                                    type='password'
                                    name='confirm_password'
                                    onChange={handleChangeConfirmPassword}
                                    rules={vsmChangePass.validation.confirm_password}
                                    onBlur={() => setConfmNewPassTooltip(false)}
                                />
                            </Popover>

                            <div className="text-center mt-15 mb-10" key={1}>
                                {(AppsAuthStore.checkPermission(1021, 'change')) && <Button key="1" htmlType="submit" className='mr-10' type="primary" loading={saving}>Submit</Button>}
                                <Button key="2" htmlType="button" onClick={onCancle}>Cancel</Button>
                            </div>
                        </Form>
                    </Col>
                </Row>
            </div>
        </Modal>
    );
})

export default ChangePassword;