import Axios from "axios"
import { decorate, observable, action } from "mobx"
import { vsmNotify } from "../config/Originator/AppsMessage"

export default class AppsAuthStore {
    originator_user = null
    originator_token = null
    originator_open_login = false
    loading = false
    apps_ledger_base_currency = null
    apps_menu = null
    permissions = null
    list_fullscreen = false
    fullscreen_class = ''
    theme_color = null
    theme_button_color = null
    theme_type = null
    c_etr_sell_rate = null
    d_etr_purchase_discount = null

    toggleListFullscreen = () => {
        this.list_fullscreen = this.list_fullscreen ? false : true
        this.fullscreen_class = this.list_fullscreen ? 'full_screen_wrapper' : ''
    }

    constructor() {
        this.initiatAppOptions()
    }

    // set base url & add interceptors to axios
    setAppAxiosDefaults = () => {
        Axios.defaults.baseURL = 'http://credebt.silicontechnolabs.com:8001/apps/'
    }

    // set base url & add interceptors to axios
    setAppsAxiosInterceptors = () => {
        Axios.interceptors.response.use((data) => {
            return data
        }, (data) => {
            if (data.response) {
                if (data.response.status === 401) {
                    this.clearLocalStorage()
                    this.originator_open_login = true
                } else if (data.response.status === 500) {
                    vsmNotify.error({
                        message: "Something went wrong. For more detail [500]"
                    })
                } else if (data.response.status === 422) {
                    if (data.response.data.errors.general) {
                        data.response.data.errors.general.map((err) => (
                            vsmNotify.error({
                                message: err
                            })
                        ))
                    }
                }
            } else {
                vsmNotify.error({
                    message: "Network Problem."
                })
                data.errors = null
            }
            return Promise.reject(data)
        })
    }

    // set axios default setting for api call
    initiatAppOptions = () => {
        this.loading = true
        this.setAppAxiosDefaults()

        let token = localStorage.getItem('app_token')
        if (token && token !== 'undefined') {
            Axios.defaults.headers = {
                Accept: 'application/json',
                Authorization: 'Bearer ' + token
            }
            this.setUserOptions(token)
        } else {
            this.setOriginatorUser(null, null)
            this.loading = false
        }
    }

    // make a api call to get current user & accessible menu
    setUserOptions = (token) => {
        Axios.post('me').then(({ data }) => {
            this.setOriginatorUser(data.user, token)
            this.apps_ledger_base_currency = data.ledger_base_currency
            this.apps_menu = data.menu
            this.loading = false
            this.permissions = data.permissions
            this.c_etr_sell_rate = data.user.c_etr_sell_rate
            this.d_etr_purchase_discount = data.user.d_etr_purchase_discount
        }).catch(e => {
            this.clearLocalStorage()
            this.initiatAppOptions()
        })
    }

    // set authenticated originator user to mobx store
    setOriginatorUser = (otr_user, otr_token) => {
        this.originator_user = otr_user
        this.originator_token = otr_token
    }

    clearLocalStorage = () => {
        localStorage.removeItem('app_token')
        localStorage.removeItem('params')
    }

    doAppsLogin = (data) => {
        return Axios.post('login', data).then(({ data }) => {
            localStorage.setItem('app_token', data.access_token)
            this.initiatAppOptions()
            this.originator_open_login = false
        }).catch(({ response: { data } }) => {
            var errors = []
            var notify = null
            if (data.errors) {
                Object.keys(data.errors).forEach(name => {
                    if (name === 'both') {
                        notify = data.errors[name][0]
                    } else {
                        errors.push({ name, errors: data.errors[name] })
                    }
                })
            }
            return Promise.reject({ errors, notify })
        })
    }

    checkPermission = (module_id, permission_type) => {
        if (this.originator_user && this.originator_user.id === 1) {
            return true
        } else {
            if (this.permissions && this.permissions[module_id] && this.permissions[module_id].includes(permission_type)) {
                return true
            } else {
                return false
            }
        }
    }

    doAppsForgetPass = (formData) => {
        return Axios.post('/forgot/password', formData).then(({ data }) => {
            return data
        }).catch(({ response: { data } }) => {
            var errors = []
            Object.keys(data.errors).forEach(name => {
                errors.push({ name, errors: data.errors[name] })
            })
            data.errors = errors
            return Promise.reject(data)
        })
    }

    doLogOut = () => {
        this.loading = true
        return Axios.post('logout').then(({ data }) => {
            this.setOriginatorUser(null, null)
            this.loading = false
            Axios.defaults.headers = {
                Accept: 'application/json'
            }
            this.apps_ledger_base_currency = null
            this.apps_menu = null
            this.clearLocalStorage()
        }).catch(e => {
            this.loading = false
            return Promise.reject()
        })
    }

    doAppsResetPass = (formData) => {
        return Axios.post('/reset/password', formData).then(({ data }) => {
            return data
        }).catch(({ response: { data } }) => {
            var errors = []
            Object.keys(data.errors).forEach(name => {
                errors.push({ name, errors: data.errors[name] })
            })
            data.errors = errors
            return Promise.reject(data)
        })
    }
}

decorate(AppsAuthStore, {
    theme_color: observable,
    theme_button_color: observable,
    theme_type: observable,
    originator_user: observable,
    originator_token: observable,
    originator_open_login: observable,
    loading: observable,
    apps_ledger_base_currency: observable,
    permissions: observable,
    list_fullscreen: observable,
    fullscreen_class: observable,
    apps_menu: observable,
    c_etr_sell_rate: observable,
    d_etr_purchase_discount: observable,
    initiatAppOptions: action,
    setAppAxiosDefaults: action,
    setAppsAxiosInterceptors: action,
    setUserOptions: action,
    checkPermission: action,
    toggleListFullscreen: action,
    doAppsForgetPass: action,
    doLogOut: action,
    doAppsResetPass: action,
})
