import React, { useState } from 'react';
import { Button, Card, Col, Row, Typography } from 'antd';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';

const DashboardWidget = observer((props) => {
	const history = useHistory()
	const { Text } = Typography;
	const [DisplayTotal, setDisplayTotal] = useState(props.defaultSelectValue)
	const [active, setActive] = useState(1)

	const handleClick = (_x) => {
		if (_x === "DEBTORS" || _x === "CREDITORS") {
			var credebtorsVal;
			if (_x === "CREDITORS") {
				credebtorsVal = "Creditor"
			}
			else {
				credebtorsVal = "Debtor"
			}
			history.push({ pathname: '/apps/my-credebtors/all-credebtors', state: credebtorsVal });
			localStorage.setItem('selectedFilter', credebtorsVal)
		} else {
			history.push({ pathname: '/apps/trading/trade-list', state: _x });
			localStorage.setItem('selectedFilter', _x)
		}
	}

	const setTotalValue = (data) => {
		setDisplayTotal(data.count)
	}

	const DisplayTotalFunction = (value, active) => {
		setTotalValue(value)
		setActive(active)
	}

	const stirngFormat = (data) => {
		let tempString = data?.split("_")
		if (tempString.length > 1) {
			return tempString[0] + '-' + tempString[1].toUpperCase()
		} else {
			return data.toUpperCase()
		}
	}

	return (
		<Card bordered={false} className={!props.top ? "widget_wrapper" : "widget_wrapper widget_wrapper_top"}>
			{
				props.top ?
					<Row className='align-items-center justify-content-center'>
						<Col span={24} className="mt-5 text-center pr-5 pl-5 pt-10 pb-10">
							<span className='count_wrapper'>0.875%</span>
						</Col>
					</Row>
					:
					<Row className='align-items-center'>
						<Col span={16}>
							<img src={props.img} alt='ETR Graphis' />
							<span onClick={() => handleClick(stirngFormat(props.item[0]))} style={{ cursor: 'pointer' }} className='text_wrapper'>{stirngFormat(props.item[0])}</span>
						</Col>

						<Col span={8}>
							<span className='count_wrapper'>{DisplayTotal}</span>
						</Col>
					</Row>
			}
			<div className='footer_wrapper text-center'>
				{
					props.top ? <Text>{props.footerText}</Text> :
						<div className="text-right">
							<Button onClick={() => DisplayTotalFunction(props.footerData[0][1], 1)} type="link" size="small"><span className={`counter__widget__button ${active === 1 ? 'counter__widget__button_cs_active' : null}`}>{props.footerData[0][0]} Days</span></Button>
							<Button onClick={() => DisplayTotalFunction(props.footerData[1][1], 2)} type="link" size="small"><span className={`counter__widget__button ${active === 2 ? 'counter__widget__button_cs_active' : null}`}>{props.footerData[1][0]} Days</span></Button>
							<Button onClick={() => DisplayTotalFunction(props.footerData[2][1], 3)} type="link" size="small"><span className={`counter__widget__button ${active === 3 ? 'counter__widget__button_cs_active' : null}`}>12 Months</span></Button>
						</div>
				}
			</div>
		</Card>
	)
})

export default DashboardWidget;