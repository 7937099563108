import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { Button, Drawer, Image } from 'antd';
import useStore from '../../../../store';
import { EditOutlined } from '@ant-design/icons'
import EditComponent from './EditComponent';

const ViewComponent = observer((props) => {
    const { CredebtorsStore, AppsAuthStore } = useStore()
    const [editCradeDrawer, setEditCradeDrawer] = useState(false)

    const close = () => {
        props.close()
    }

    const openEditCradeDrawer = () => {
        setEditCradeDrawer(true)
    }

    //close edit drawer
    const closeEditCradeDrawer = () => {
        setEditCradeDrawer(false)
        CredebtorsStore.getRecordDetails(CredebtorsStore.view_values.id, 'view')
    }

    return CredebtorsStore.view_values && (
        <Drawer
            visible={props.visible}
            className='viewETR'
            onClose={close}
            placement='right'
            width={'50%'}
            destroyOnClose={true}
            zIndex={AppsAuthStore.list_fullscreen ? 1011 : 1001}
            title={`View Credebtor - #${CredebtorsStore.view_values && CredebtorsStore.view_values.id} - ${CredebtorsStore.view_values && CredebtorsStore.view_values.organisation && CredebtorsStore.view_values.organisation.organisation_name}`}
            closable={false}
            extra={
                <Button onClick={openEditCradeDrawer}><EditOutlined /></Button>
            }
            footer={[
                <div className="text-center" key={1}>
                    <Button onClick={close}>Cancel</Button>
                </div>
            ]}
        >
            <div className='viewCredebtor'>
                <table cellPadding='0' cellSpacing='0' border='0'>
                    <tbody key="tbody">
                        <tr>
                            <td>Role:</td>
                            <td>Organisation Name:</td>
                            <td>Website</td>
                            <td>Logo</td>
                        </tr>
                        <tr>
                            <th>{CredebtorsStore.view_values && CredebtorsStore.view_values.role && CredebtorsStore.view_values.role.role_name}</th>
                            <th>{CredebtorsStore.view_values && CredebtorsStore.view_values.organisation && CredebtorsStore.view_values.organisation.organisation_name}</th>
                            <th >{CredebtorsStore.view_values && CredebtorsStore.view_values.organisation && CredebtorsStore.view_values.organisation.website}</th>
                            <th rowSpan={3}> {CredebtorsStore.view_values?.organisation?.logo_url && (
                                <Image
                                    preview={false}
                                    src={CredebtorsStore.view_values && CredebtorsStore.view_values.organisation.logo_url}
                                    alt='people image'
                                />
                            )}
                            </th>
                        </tr>
                        <tr>
                            <td>Phone</td>
                            <td>Email:</td>
                            <td>Registration Country:</td>
                        </tr>
                        <tr>
                            <th>{CredebtorsStore.view_values && CredebtorsStore.view_values.organisation && CredebtorsStore.view_values.organisation?.mutiple_phones.map((x, index) => <div key={index}>{x.contact_for + ': ' + x.contact_value}</div>)}</th>
                            <th>{CredebtorsStore.view_values && CredebtorsStore.view_values.organisation && CredebtorsStore.view_values.organisation?.mutiple_email.map((x, index) => <div key={index}>{x.contact_for + ': ' + x.contact_value}</div>)}</th>
                            <th>{CredebtorsStore.view_values && CredebtorsStore.view_values.organisation && CredebtorsStore.view_values.organisation.global_countries.country_name}</th>
                        </tr>
                        <tr>
                            <td>Related Organisation</td>
                            <td>Registration Number:</td>
                            <td>Organisation Currency</td>
                            <td>Credit Notes Issued:</td>
                        </tr>
                        <tr>
                            <th>{AppsAuthStore.originator_user && AppsAuthStore.originator_user.orgnisation && AppsAuthStore.originator_user.orgnisation.organisation_name}</th>
                            <th>{CredebtorsStore.view_values && CredebtorsStore.view_values.organisation && CredebtorsStore.view_values.organisation.registration_number}</th>
                            <th>{CredebtorsStore.view_values && CredebtorsStore.view_values.organisation && CredebtorsStore.view_values.organisation.global_currency.currency_name}</th>
                            <th>{CredebtorsStore.view_values && CredebtorsStore.view_values.credit_notes_issued}</th>
                        </tr>
                        <tr>
                            <td>Trade Name:</td>
                            <td>VAT Number:</td>
                            <td>Tax Clearance Access Number:</td>
                            <td>Counterparty Since:</td>
                        </tr>
                        <tr>
                            <th>{CredebtorsStore.view_values && CredebtorsStore.view_values.organisation && CredebtorsStore.view_values.organisation.trade_name}</th>
                            <th>{CredebtorsStore.view_values && CredebtorsStore.view_values.organisation && CredebtorsStore.view_values.organisation.vat_number}</th>
                            <th>{CredebtorsStore.view_values && CredebtorsStore.view_values.organisation && CredebtorsStore.view_values.organisation.revenue_access_number}</th>
                            <th>{CredebtorsStore.view_values && CredebtorsStore.view_values.counter_party_since}</th>
                        </tr>
                        <tr>
                            <td>Average Payment Cycle:</td>
                            <td>Total Historic Value:</td>
                            <td>Invoice Predicted Per Annum:</td>
                            <td>Predicted Annual Revenue:</td>
                        </tr>
                        <tr>
                            <th>{CredebtorsStore.view_values && CredebtorsStore.view_values.average_payment_cycle}</th>
                            <th>{CredebtorsStore.view_values && CredebtorsStore.view_values.total_historic_value}</th>
                            <th>{CredebtorsStore.view_values && CredebtorsStore.view_values.invoice_predicted_per_annum}</th>
                            <th>{CredebtorsStore.view_values && CredebtorsStore.view_values.predicted_annual_revenue}</th>
                        </tr>
                        <tr>
                            {props.viewType === 'Debtor' ? (
                                <>
                                    <td colSpan={2}>Experience File:</td>
                                    <td colSpan={2}>Address Details:</td>
                                </>
                            ) : (
                                <>
                                    <td>Agreed Early Payment Discount:</td>
                                    <td>Address Details:</td>
                                    <td colSpan={2}>Bank Details:</td>
                                </>
                            )}
                        </tr>
                        <tr>
                            {props.viewType === 'Debtor' ? (
                                <>
                                    <th colSpan={2}><a target={`_blank`} href={CredebtorsStore.view_values && CredebtorsStore.view_values.experience_file}>{CredebtorsStore.view_values && CredebtorsStore.view_values.experience_file}</a></th>
                                    <th colSpan={2}>{CredebtorsStore.view_values && CredebtorsStore.view_values.organisation && CredebtorsStore.view_values.organisation.mutiple_addresses.map((item, index) =>
                                        <div key={index}>
                                            <ul>
                                                <li>
                                                    {(item.address_type ? 'Address Type: ' + item.address_type + ', ' : '') + 'Address Details: ' +
                                                        item.address_1 +
                                                        (item.address_2 ? ', ' + item.address_2 : '') +
                                                        (item.address_3 ? ', ' + item.address_3 : '') +
                                                        (item.city ? ', ' + item.city : '') +
                                                        (item.state_county ? ', ' + item.state_county : '') +
                                                        (item.postal_code ? ', ' + item.postal_code : '') + ', ' +
                                                        item.countries.country_name}
                                                </li>
                                            </ul>
                                        </div>
                                    )}
                                    </th>
                                </>
                            ) : (
                                <>
                                    <th>{CredebtorsStore.view_values && CredebtorsStore.view_values.agreed_early_payment_discount}</th>
                                    <th>{CredebtorsStore.view_values && CredebtorsStore.view_values.organisation && CredebtorsStore.view_values.organisation.mutiple_addresses.map((item, index) =>
                                        <div key={index}>
                                            <ul>
                                                <li>
                                                    {'Address Type: ' + item.address_type + ', Address Details: ' +
                                                        item.address_1 +
                                                        (item.address_2 ? ', ' + item.address_2 : '') +
                                                        (item.address_3 ? ', ' + item.address_3 : '') +
                                                        (item.city ? ', ' + item.city : '') +
                                                        (item.state_county ? ', ' + item.state_county : '') +
                                                        (item.postal_code ? ', ' + item.postal_code : '') + ', ' +
                                                        item.countries.country_name}
                                                </li>
                                            </ul>
                                        </div>
                                    )}
                                    </th>
                                    <th colSpan={2}>
                                        <div style={{ overflowY: 'auto', maxHeight: '115px' }}>
                                            {CredebtorsStore.view_values && CredebtorsStore.view_values.banks.length > 0 && CredebtorsStore.view_values.banks.map((item, index) =>
                                                <div key={index}>
                                                    <ul>
                                                        <li>
                                                            {'Bank Name: ' + item.bank_name +
                                                                (item.account_type_name ? ', Account Type: ' + item.account_type_name : null) +
                                                                (item.currency_name ? ', Currency: ' + item.currency_name : null) +
                                                                (item.account_number ? ', Account Number: ' + item.account_number + ', Sort Code: ' + item.sort_code : ', IBAN: ' + item.iban + ', Swift or BIC Code: ' + item.swift_bic)}
                                                        </li>
                                                    </ul>
                                                </div>
                                            )}
                                        </div>
                                    </th>
                                </>
                            )}
                        </tr>
                        <tr>
                            <td colSpan={4}>Description:</td>
                        </tr>
                        <tr>
                            <th colSpan={4}>{CredebtorsStore.view_values && CredebtorsStore.view_values.organisation && CredebtorsStore.view_values.organisation.description}</th>
                        </tr>
                    </tbody>
                </table>
            </div>
            <EditComponent visible={editCradeDrawer} close={closeEditCradeDrawer} />
        </Drawer >
    );
})

export default ViewComponent;