import Axios from "axios";
import { action, decorate, observable } from "mobx";

export default class UserProfileStore {
    profileDetails = null
    dropdown_countries_list = null
    naceCode_list = null
    dropdown_currency_list = null
    initialValues = {
        phone: [{ id: null, contact_for: 'Primary' }],
        email: [{ id: null, contact_for: 'Primary' }],
        adddress: [],
    }

    getUserProfileDetails = (user_id) => {
        return Axios.get(`user/read/${user_id}`).then(({ data }) => {
            this.profileDetails = data.data

            return data
        })
    }

    changePassword = (formData) => {
        return Axios.post('user/change/password', formData).then((data) => {
            return data
        }).catch(({ response: { data } }) => {
            var errors = []
            Object.keys(data.errors).forEach(name => {
                errors.push({ name, errors: data.errors[name] })
            })
            data.errors = errors
            return Promise.reject(data)
        })
    }

    // call api to get all countries list
    getAllCountries = () => {
        return Axios.get(`organisations/get/countries`).then(({ data }) => {
            this.dropdown_countries_list = data.data
            return data
        })
    }

    // get Industries list
    getIndustries = () => {
        return Axios.get(`organisations/global/industries`).then(({ data }) => {
            this.industries_list = data.data
            return data
        })
    }

    // get NACE code list
    getNaceCode = () => {
        return Axios.get(`organisations/global/nace-sections`).then(({ data }) => {
            this.naceCode_list = data.data
            return data
        })
    }

    // call for currency list
    getCurrencyList = () => {
        return Axios.get(`globalcurreny/ledger/currency`).then(({ data }) => {
            this.dropdown_currency_list = data.data
            return data
        }).catch(() => {
            this.dropdown_currency_list = null
        })
    }

    // call for city list to auto populate
    getCityList = () => {
        return Axios.get(`global/addresses/city/get`).then(({ data }) => {
            var i
            var arrayObj = data.data
            for (i = 0; i < arrayObj.length; i++) {
                arrayObj[i].value = arrayObj[i]['city']
                delete arrayObj[i].key1
            }
            this.cityList = arrayObj
            return data
        }).catch(() => {
            this.cityList = null
        })
    }

    // call for bank name list to auto populate
    getStateCountyList = () => {
        return Axios.get(`global/addresses/state/get`).then(({ data }) => {
            var i
            var arrayObj = data.data
            for (i = 0; i < arrayObj.length; i++) {
                arrayObj[i].value = arrayObj[i]['state_county']
                delete arrayObj[i].key1
            }
            this.stateCountyList = arrayObj
            return data
        }).catch(() => {
            this.stateCountyList = null
        })
    }

    JsonToFormData = (data) => {
        const formData = new FormData();
        Object.keys(data).forEach(key => {
            if (key !== "logo_url") {
                if (key === 'add_address') {
                    formData.append(key, data[key] ? 1 : 0)
                } else {
                    data[key] && formData.append(key, data[key])
                }
            }
        })
        return formData
    }

    UpdateProfileDetails = (formData, user_id) => {
        return Axios.post(`user/update/${user_id}`, formData).then(({ data }) => {
            this.getUserProfileDetails(user_id)
            return data
        }).catch(({ response: { data } }) => {
            var errors = []
            Object.keys(data.errors).forEach(name => {
                errors.push({ name, errors: data.errors[name] })
            })
            data.errors = errors
            return Promise.reject(data)
        })
    }

    changeProfile = (formData) => {
        return Axios.post('user/change/profile', formData).then(({ data }) => {
            return data
        }).catch(({ response: { data } }) => {
            var errors = []
            Object.keys(data.errors).forEach(name => {
                errors.push({ name, errors: data.errors[name] })
            })
            data.errors = errors
            return Promise.reject(data)
        })
    }

}

decorate(UserProfileStore, {
    profileDetails: observable,
    initialValues: observable,
    dropdown_countries_list: observable,
    dropdown_currency_list: observable,
    cityList: observable,
    stateCountyList: observable,
    getUserProfileDetails: action,
    changePassword: action,
    getAllCountries: action,
    getIndustries: action,
    getNaceCode: action,
    getCurrencyList: action,
    getCityList: action,
    getStateCountyList: action,
    JsonToFormData: action,
    changeProfile: action,
})