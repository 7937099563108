import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import useStore from '../../../../../store';
import { Button, Drawer, Form, Spin } from "antd";
import FormComponent from "./FormComponent";
import { vsmNotify, vsmBankAccount } from "../../../../../config/Originator/AppsMessage";

const EditComponent = observer((props) => {
    const [form] = Form.useForm();
    const { AppsAuthStore, BanksStore, BanksStore: { readData, editData } } = useStore()
    const [loading, setLoading] = useState(true)
    const [saving, setSaving] = useState(false)
    const [isDisabled, setIsDisabled] = useState(true)
    const [fetchBankNames, setFetchBankNames] = useState(true)
    const [fetchCurrencies, setFetchCurrencies] = useState(true)
    const [fetchBankTypes, setFetchBankTypes] = useState(true)
    const [currentTab, setCurrentTab] = useState("account")

    const handleSubmit = (data) => {
        setSaving(true)
        data.id = readData.id
        editData(data).then(() => {
            vsmNotify.success({
                message: vsmBankAccount.edit
            })
            close()
        }).catch(e => {
            if (e.errors) { form.setFields(e.errors) }
        }).finally(() => setSaving(false))
    }

    const close = () => {
        setSaving(false)
        setIsDisabled(false)
        setLoading(true)
        BanksStore.readData = null
        props.close()
    }

    useEffect(() => {
        if (readData) {
            form.setFieldsValue({
                bank_name: readData.banks.name,
                bank_account_type_id: readData.bank_account_type_id,
                currency_id: readData.currency_id,
                account_number: readData.account_number,
                sort_code: readData.sort_code,
                iban: readData.iban,
                swift_bic: readData.swift_bic,
                tab: readData.account_number ? "account" : "iban"
            })
            if (readData.account_number) {
                setCurrentTab("account")
            } else {
                setCurrentTab("iban")
            }
            setLoading(false)
        }
    }, [readData, form])

    return props.visible && (
        <Drawer
            visible={props.visible}
            onClose={close}
            placement="right"
            width={"50%"}
            destroyOnClose={true}
            zIndex={AppsAuthStore.list_fullscreen ? 1011 : 1001}
            title={`Edit Bank - #${readData ? readData.id : ''}`}
            footer={[
                <div className="text-center" key={1}>
                    <Button
                        form="addEditBankForm"
                        className="mr-10"
                        loading={saving}
                        htmlType="submit"
                        type="primary"
                        disabled={isDisabled}
                    >
                        Save
                    </Button>
                    <Button onClick={close}>Cancel</Button>
                </div>
            ]}
        >
            <Spin size="default" spinning={loading}>
                <FormComponent
                    form={form}
                    setDisabled={setIsDisabled}
                    handleSubmit={handleSubmit}
                    fetchBankNames={fetchBankNames}
                    setFetchBankNames={setFetchBankNames}
                    fetchCurrencies={fetchCurrencies}
                    setFetchCurrencies={setFetchCurrencies}
                    fetchBankTypes={fetchBankTypes}
                    setFetchBankTypes={setFetchBankTypes}
                    currentTab={currentTab}
                    setCurrentTab={setCurrentTab}
                    type={true}
                />
            </Spin>
        </Drawer>
    )
})

export default EditComponent