import React, { useState } from 'react'

import { observer } from 'mobx-react'
import { Button, Drawer, Form } from 'antd'
import FormComponent from './FormComponent'
import useStore from '../../../../store'
import moment from 'moment'
import { vsmNotify, vsmTransaction } from '../../../../config/Originator/AppsMessage'

const AddTransaction = observer((props) => {
    const [form] = Form.useForm();
    const { AppsAuthStore, TransactionStore } = useStore()
    const [credebtorLoader, setCredebtorLoader] = useState(true)
    const [tradeLoader, setTradeLoader] = useState(true)
    const [fetchCurrency, setFetchCurrency] = useState(true)
    const [vatRateLoader, setVatRateLoader] = useState(true)
    const [saving, setSaving] = useState(false)
    const [isDisabled, setDisabled] = useState(true)
    const [csvColumns, setCsvColumns] = useState(null)

    const close = () => {
        form.resetFields()
        setCredebtorLoader(true)
        setTradeLoader(true)
        setTradeLoader(true)
        setFetchCurrency(true)
        setVatRateLoader(true)
        props.close()
    }

    const handleSubmit = (data) => {
        if (data.posted_on) {
            data.posted_on = moment(data.posted_on).format('YYYY-MM-DD')
        }
        if (data.traded_on) {
            data.traded_on = moment(data.traded_on).format('YYYY-MM-DD')
        }
        TransactionStore.addTransaction(data).then(() => {
            vsmNotify.success({
                message: vsmTransaction.addTransaction
            })
            close()
        }).catch(e => {
            if (e.errors) { form.setFields(e.errors) }
        }).finally(() => {
            setSaving(false)
            setDisabled(true)
        })
    }

    return (
        <Drawer
            visible={props.visible}
            onClose={close}
            placement="right"
            width={"50%"}
            destroyOnClose={true}
            zIndex={AppsAuthStore.list_fullscreen ? 1011 : 1001}
            title="Add Transaction"
            footer={[
                <div className="text-center" key={1}>
                    <Button
                        form="transactionAddForm"
                        className="mr-10"
                        loading={saving}
                        htmlType="submit"
                        type="primary"
                        disabled={isDisabled}
                    >
                        Save
                    </Button>
                    <Button onClick={close}>Cancel</Button>
                </div>,
            ]}
        >
            <FormComponent
                form={form}
                handleSubmit={handleSubmit}
                setDisabled={setDisabled}
                credebtorLoader={credebtorLoader}
                setCredebtorLoader={setCredebtorLoader}
                tradeLoader={tradeLoader}
                setTradeLoader={setTradeLoader}
                fetchCurrency={fetchCurrency}
                setFetchCurrency={setFetchCurrency}
                vatRateLoader={vatRateLoader}
                setVatRateLoader={setVatRateLoader}
                csvColumns={csvColumns}
                setCsvColumns={setCsvColumns}
            />
        </Drawer>
    )
})

export default AddTransaction