import React, { useState } from 'react'
import { observer } from 'mobx-react'
import { Form, Button, Modal, Col, Row } from 'antd'
import useStore from '../../../../../store'
import { vsmNotify, vsmBankAccount } from '../../../../../config/Originator/AppsMessage'

const StatusComponent = observer((props) => {
    const [form] = Form.useForm()
    const [saving, setSaving] = useState()
    const { BanksStore } = useStore()

    const handleSubmit = () => {
        setSaving(true)
        var payload = {
            id: props.visible.id,
            status: props.visible.status ? false : true
        }
        BanksStore.changeStatus(payload).then((data) => {
            close()
            vsmNotify.success({
                message: props.visible.status ? vsmBankAccount.deactivated : vsmBankAccount.activated
            })
            form.resetFields()
        }).catch(e => {
            if (e.errors) { form.setFields(e.errors) }
        }).finally(() => {
            setSaving(false)
        })
    }

    const close = () => {
        form.resetFields()
        props.close()
    }

    return props.visible ? (
        <Modal
            centered
            visible={props.visible}
            onCancel={props.close}
            title={`${props.visible.banks.name} - #${props.visible.id}`}
            footer={[
                <Button key="1" form='statusform' loading={saving} htmlType="submit" type="primary">{props.visible.status ? `Deactivete` : `Activate`}</Button>,
                <Button key="2" htmlType="button" onClick={close}>Cancel</Button>
            ]}
        >
            <Form form={form} id="statusform" onFinish={handleSubmit}>
                <Row align="middle">
                    <Col span={20}>
                        <h3>Are you sure you want to <strong>{props.visible.status ? `Deactivete` : `Activate`}</strong> this bank.</h3>
                    </Col>
                </Row>
            </Form>
        </Modal>
    ) : null
})

export default StatusComponent
