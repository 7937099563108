import React from 'react'
import { Button } from 'antd'
import { EditOutlined, DeleteOutlined, EyeOutlined } from '@ant-design/icons'
import useStore from '../../../../store'

export const ActionRenderer = (props) => {
    const { openEditCredebtorDrawer, openDeleteCredebtorDrawer, openViewCredebtorDrawer } = props.agGridReact.props.frameworkComponents
    const { AppsAuthStore } = useStore()
    return (
        <div className="action-column">
            {AppsAuthStore.permissions && AppsAuthStore.checkPermission(1007, 'show') &&
                <Button type="text" title="View Credebtor" onClick={() => { openViewCredebtorDrawer(props.data) }}><EyeOutlined /></Button>
            }
            {AppsAuthStore.permissions && AppsAuthStore.checkPermission(1007, 'edit') &&
                <Button title="Edit Credebtor" type="text" onClick={() => { openEditCredebtorDrawer(props.data) }}><EditOutlined /></Button>
            }
            {AppsAuthStore.permissions && AppsAuthStore.checkPermission(1007, 'delete') &&
                <Button type="text" title="Delete Credebtor" onClick={() => { openDeleteCredebtorDrawer(props.data) }}><DeleteOutlined /></Button>
            }
        </div>
    )
}