import { Col, Form, Row, Spin } from "antd";
import { observer } from "mobx-react";
import React from "react";
import InputComponent from "../../../../../components/InputComponent";
import { vsmBankAccount } from "../../../../../config/Originator/AppsMessage";
import useStore from "../../../../../store";

const FormComponent = observer(({ form, type, fetchBankNames, fetchBankTypes, setFetchBankTypes, setFetchBankNames, fetchCurrencies, currentTab, setCurrentTab, setFetchCurrencies, setDisabled, handleSubmit }) => {
    const { BanksStore: { bankNameList, getBanksNameList, dropdown_bank_type_list, getBankAccountTypeList, getCurrencyList, dropdown_currency_list, readData } } = useStore()

    const onFinish = (data) => {
        handleSubmit(data)
    }

    const handleChange = () => {
        form.validateFields().then((data) => {
            setDisabled(false)
            if ((data.tab === "account" && data.hasOwnProperty("iban")) || (data.tab === "iban" && data.hasOwnProperty("account_number"))) {
                changeMapwith()
            }
        }).catch(e => {
            if ((e.values.tab === "account" && e.values.hasOwnProperty("iban")) || (e.values.tab === "iban" && e.values.hasOwnProperty("account_number"))) {
                changeMapwith()
            }
            if (e.errorFields && e.errorFields.length > 0) {
                setDisabled(true)
            }
        })
    }

    // set field vaule null based on tab selection
    const changeMapwith = async () => {
        await setCurrentTab(form.getFieldValue("tab"))
        form.setFieldsValue({
            account_number: null,
            sort_code: null,
            iban: null,
            swift_bic: null
        })
        handleChange()
    }

    return (
        <Form
            form={form}
            id="addEditBankForm"
            onFinish={onFinish}
            layout='vertical'
        >
            <Row gutter={[8, 8]}>
                <Col span={10}>
                    <InputComponent
                        required
                        allowClear
                        type="autocomplete"
                        label="Bank Name"
                        name="bank_name"
                        placeholder="Bank Name"
                        options={bankNameList}
                        tooltip="Bank Name (for a Bank Account) is the name of a banking or cash processing organisation that operates a specific bank account."
                        rules={vsmBankAccount.validation.bank_name}
                        onFocus={() => fetchBankNames && getBanksNameList().then(() => setFetchBankNames(false))}
                        notFoundContent={fetchBankNames ? <Spin size="small" /> : "No Record Found."}
                        onChange={handleChange}
                        disabled={type}
                    />
                </Col>
                <Col span={6}>
                    <InputComponent
                        required type="select" allowClear label="Bank Account Type" name="bank_account_type_id" placeholder="Select Bank Account Type"
                        options={{
                            values: dropdown_bank_type_list || [{
                                id: readData && readData.bank_accounts_type && readData.bank_accounts_type.id,
                                name: readData && readData.bank_accounts_type && readData.bank_accounts_type.name
                            }],
                            value_key: 'id',
                            text_key: 'name'
                        }}
                        tooltip="A name or code given to a cash account that indicates the account's purpose."
                        onFocus={() => fetchBankTypes && getBankAccountTypeList().then(() => setFetchBankTypes(false))}
                        notFoundContent={fetchBankTypes ? <Spin size="small" /> : "No Record Found."}
                        rules={vsmBankAccount.validation.bank_ac_type}
                        onChange={handleChange}
                        disabled={type}
                    />
                </Col>
                <Col span={6}>
                    <InputComponent
                        required type="select" allowClear label="Currency" name="currency_id" placeholder="Select Currency"
                        options={{
                            values: dropdown_currency_list || [{
                                id: readData && readData.currency && readData.currency.id,
                                currency_name: readData && readData.currency && readData.currency.currency_code
                            }],
                            value_key: 'id',
                            text_key: 'currency_name'
                        }}
                        tooltip="Currency is a medium of exchange for goods and services."
                        rules={vsmBankAccount.validation.currency}
                        onFocus={() => fetchCurrencies && getCurrencyList().then(() => setFetchCurrencies(false))}
                        notFoundContent={fetchCurrencies ? <Spin size="small" /> : "No Record Found."}
                        onChange={handleChange}
                        disabled={type}
                    />
                </Col>
                <Col span={8}>
                    <InputComponent
                        type="radio_button" name="tab" initialValue="account" onChange={handleChange} disabled={type}
                        options={{
                            values: [
                                { value: 'account', text: 'Account Number' },
                                { value: 'iban', text: 'IBAN Number' }
                            ]
                        }}
                    />
                </Col>
                {
                    currentTab === "account" ?
                        <>
                            <Col span={6}>
                                <InputComponent
                                    required label="Account Number" name="account_number" placeholder="Account Number" onChange={handleChange}
                                    tooltip="Account Number is a short version of the cash account number (alternative to or part of IBAN)."
                                    rules={vsmBankAccount.validation.account_num}
                                    maxLength={50}
                                />
                            </Col>
                            <Col span={6}>
                                <InputComponent
                                    required label="Sort Code" name="sort_code" placeholder="Sort Code" onChange={handleChange}
                                    tooltip="A Sort Code is a number code, which is used by various banks (i.e. in Ireland, United Kingdom, etc.)."
                                    rules={vsmBankAccount.validation.sort_code}
                                    maxLength={50}
                                />
                            </Col>
                        </>
                        :
                        <>
                            <Col span={6}>
                                <InputComponent
                                    required label="IBAN" name="iban" placeholder="International Bank Account Number" onChange={handleChange}
                                    tooltip="An IBAN, or International Bank Account Number, is a standard international numbering system developed to identify an overseas/foreign bank account."
                                    rules={vsmBankAccount.validation.iba_num}
                                />
                            </Col>
                            <Col span={6}>
                                <InputComponent
                                    required label="Swift or BIC Code" name="swift_bic" placeholder="Swift or BIC Code" onChange={handleChange}
                                    tooltip="A SWIFT code is an international bank code that identifies particular banks worldwide. It's also known as a Bank Identifier Code (BIC)."
                                    rules={vsmBankAccount.validation.swift_or_bic_code}
                                    maxLength={50}
                                />
                            </Col>
                        </>
                }
            </Row>
        </Form >
    )
})

export default FormComponent;