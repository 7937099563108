import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { Button, Drawer, Form } from 'antd';
import useStore from '../../../../../../store';
import FormComponent from './FormComponent';
import { vsmNotify, vsmRSAList } from '../../../../../../config/Originator/AppsMessage'

const RSAAddComponent = observer((props) => {
    const [form] = Form.useForm()
    const { AppsAuthStore, TradeFloorStore } = useStore()
    const [saving, setSaving] = useState(false)
    const [isDisabled, setDisabled] = useState(false)
    const [fetchDebtor, setFetchDebtor] = useState(true)
    const [fetchCurrency, setFetchCurrency] = useState(true)
    const [fileList, setFileList] = useState([]);

    const handleSubmit = (data) => {
        data.etr_type = 6
        var formData = TradeFloorStore.JsonToFormData(data)
        formData.append('file_support', fileList && fileList.length && fileList[0].originFileObj)
        formData.append('status', 1)
        setSaving(true)
        TradeFloorStore.AddTrade(formData).then(() => {
            close()
            vsmNotify.success({
                message: vsmRSAList.add
            })
        }).catch(e => {
            if (e.errors) { form.setFields(e.errors) }
        }).finally(() => {
            setSaving(false)
            setDisabled(true)
        })
    }

    const close = () => {
        setSaving(false)
        setDisabled(false)
        setFetchDebtor(true)
        setFetchCurrency(true)
        setFileList([])
        form.resetFields()
        props.close()
    }

    const handleChange = () => {
        form.validateFields().then((data) => {
            setDisabled(false)
        }).catch(e => {
            if (e.errorFields && e.errorFields.length > 0) {
                setDisabled(true)
            }
        })
    }

    return (
        <Drawer
            visible={props.visible}
            onClose={close}
            placement='right'
            width={'50%'}
            destroyOnClose={true}
            zIndex={AppsAuthStore.list_fullscreen ? 1011 : 1001}
            title='Add RSA'
            footer={[
                <div className="text-center" key={1}>
                    <Button form='RSAAddEditForm' className="mr-10" loading={saving} htmlType="submit" type="primary" disabled={isDisabled}>Submit</Button>
                    <Button onClick={close}>Cancel</Button>
                </div>
            ]}
        // style={{ left: AppsAuthStore.list_fullscreen ? '0px' : '280px' }}
        >
            <FormComponent
                form={form}
                onChange={handleChange}
                handleSubmit={handleSubmit}
                fetchDebtor={fetchDebtor}
                setFetchDebtor={setFetchDebtor}
                fetchCurrency={fetchCurrency}
                setFetchCurrency={setFetchCurrency}
                fileList={fileList}
                setFileList={setFileList}
                visible={props.visible}
            />
        </Drawer>
    );
})

export default RSAAddComponent;