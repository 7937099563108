import { notification } from "antd"
import IBAN from "iban"

const vsmAuth = {
	success: "Logged in successful",
	forgot_success: "An email successfully sent to user with link to set new password.",
	reset_success: "Password has been updated successfully.",
	validation: {
		email: [
			{ required: true, message: "Email Address cannot be empty." },
			{ pattern: /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i, message: "Invalid email address, please enter the valid email address" },
		],
		password: [
			{ required: true, message: "Password cannot be empty." },
			{ pattern: /(?=.*[0-9])(?=.*[@$!%*#?&])(?=.*[a-z])(?=.*[A-Z])(?=.{8,})/, message: "Password entry does not meet criteria." },
		],
		confirmpassword: [
			{ required: true, message: "Confirm Password cannot be empty." },
			({ getFieldValue }) => ({
				validator(rule, value) {
					if (!value || getFieldValue('password') === value) {
						return Promise.resolve();
					}
					return Promise.reject('New Password and Confirm Password does not match.');
				},
			}),
		]
	}
}
const vsmNotify = {
	success: (data) => {
		notification.success({ placement: "bottomRight", duration: 3, ...data })
	},
	error: (data) => {
		notification.error({ placement: "bottomRight", duration: 3, ...data })
	}
}

const vsmCommon = {
	noRecord: "No Records Found.",
}

const vsmChangePass = {
	add: "Password has been updated successfully.",
	validation: {
		old_password: [
			{ required: true, message: "Current Password cannot be empty." },
			{ pattern: /(?=.*[0-9])(?=.*[@$!%*#?&])(?=.*[a-z])(?=.*[A-Z])(?=.{8,})/, message: "Password entry does not meet criteria." },
		],
		new_password: [
			{ required: true, message: "New Password cannot be empty." },
			{ pattern: /(?=.*[0-9])(?=.*[@$!%*#?&])(?=.*[a-z])(?=.*[A-Z])(?=.{8,})/, message: "Password entry does not meet criteria." },
		],
		confirm_password: [
			{ required: true, message: "Confirm New Password cannot be empty." },
			({ getFieldValue }) => ({
				validator(rule, value) {
					if (!value || getFieldValue("password") === value) {
						return Promise.resolve();
					}
					return Promise.reject(
						"New Password and Confirm Password does not match."
					);
				},
			}),
		],
	}
}

const vsmProfile = {
	edit: "Profile Updated Successfully",
	validation: {
		first_name: [
			{ required: true, message: "First Name cannot be blank." },
			{ pattern: /^[^\t\n]+$/, message: "First Name should allow for all Unicode characters (UTF-8 encoding for DB storage) with exception of some white-space characters such as: - nonbreaking space - the tab - the hard line break - the carriage return - the hard page break." }
		],
		last_name: [
			{ pattern: /^[^\t\n]+$/, message: "Last Name should allow for all Unicode characters (UTF-8 encoding for DB storage) with exception of some white-space characters such as: - nonbreaking space - the tab - the hard line break - the carriage return - the hard page break." }
		],
		currency_id: [
			{ required: true, message: 'Please select Currency.' }
		],
		organisation_name: [
			{ required: true, message: "Organisation Name cannot be blank." },
			{ pattern: /^[^\t\n]+$/, message: "Organisation Name should allow for all Unicode characters (UTF-8 encoding for DB storage) with exception of some white-space characters such as: - nonbreaking space, the tab, the hard line break, the carriage return, the hard page break." }
		],
		trade_name: [
			{ pattern: /^[^\t\n]+$/, message: "Trade Name should allow for all Unicode characters (UTF-8 encoding for DB storage) with exception of some white-space characters such as: - nonbreaking space, the tab, the hard line break, the carriage return, the hard page break." },
		],
		registered_country_id: [
			{ required: true, message: "Please select Registration Country" },
		],
		registration_number: [
			{ pattern: /^[\000-\377]+$/, message: "ASCII characters are allowed." }
		],
		vat_number: [
			{ min: 3, message: "VAT Number should contain minimum 3 characters." },
			{ pattern: /^[\000-\377]+$/, message: "ASCII characters are allowed." }
		],
		website: [
			{ min: 1, message: "Please enter a valid Website URL and must start with http:// or https://", type: 'url' },
		],
		phone_with_required: [
			{ required: true, message: "Phone Number cannot be empty." },
			{ pattern: /^(\+\d{1,3}\s\d|\d)[\d\s-]{7,12},(\se|e)xt.(\s\d{2,5}|\d{2,5})$|^(\+\d{1,3}\s\d|\d)[\d\s-]{7,12}$/, message: "Phone Number should contain Space, Plus (+), Dash or Numeric character and “ext” keyword only. Other Special characters or Alphabets are not allowed." }
		],
		phone_without_required: [
			{ pattern: /^(\+\d{1,3}\s\d|\d)[\d\s-]{7,12},(\se|e)xt.(\s\d{2,5}|\d{2,5})$|^(\+\d{1,3}\s\d|\d)[\d\s-]{7,12}$/, message: "Phone Number should contain Space, Plus (+), Dash or Numeric character and “ext” keyword only. Other Special characters or Alphabets are not allowed." }
		],
		email_with_required: [
			{ required: true, message: "Email Address cannot be empty." },
			{ pattern: /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i, message: "Invalid email address." },
		],
		email_without_required: [
			{ pattern: /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i, message: "Invalid email address." },
		],
		email_type: [
			{ required: true, message: "Please select Email Type." },
		],
		phone_type: [
			{ required: true, message: "Please select Phone Type." },
		],
		industry: [
			{ required: true, message: "Please select Credebt® Classification." },
		],
		nace_code: [
			{ required: true, message: "Please select NACE Code." },
		],
		country_id: [
			{ required: true, message: "Please select Country." },
		],
		tax_clearence: [
			{ pattern: /^[^\t\n]+$/, message: "Tax Clearance Access Number should allow for all Unicode characters (UTF-8 encoding for DB storage) with exception of some white-space characters such as: - nonbreaking space, the tab, the hard line break, the carriage return, the hard page break." }
		],
		address_type: [
			{ pattern: /^[^\t\n]+$/, message: "Address Type should allow for all Unicode characters (UTF-8 encoding for DB storage) with exception of some white-space characters such as: - nonbreaking space, the tab, the hard line break, the carriage return, the hard page break." }
		],
		address_1: [
			{ required: true, message: "Address cannot be blank." },
			{ pattern: /^[^\t\n]+$/, message: "Address should allow for all Unicode characters (UTF-8 encoding for DB storage) with exception of some white-space characters such as: - nonbreaking space, the tab, the hard line break, the carriage return, the hard page break." }
		],
		address_2and3: [
			{ pattern: /^[^\t\n]+$/, message: "Address should allow for all Unicode characters (UTF-8 encoding for DB storage) with exception of some white-space characters such as: - nonbreaking space, the tab, the hard line break, the carriage return, the hard page break." }
		],
		city: [
			{ max: 100, message: "City should contain maximum 100 characters." },
			{ pattern: /^[^\t\n]+$/, message: "City should allow for all Unicode characters (UTF-8 encoding for DB storage) with exception of some white-space characters such as: nonbreaking space, the tab, the hard line break, the carriage return, the hard page break." }
		],
		state: [
			{ max: 100, message: "State/County should contain maximum 100 characters." },
			{ pattern: /^[^\t\n]+$/, message: "State/County should allow for all Unicode characters (UTF-8 encoding for DB storage) with exception of some white-space characters such as: nonbreaking space, the tab, the hard line break, the carriage return, the hard page break." }
		],
		postal_code: [
			{ min: 1, message: "Postal Code should contain minimum 1 characters." },
			{ pattern: /^[^\t\n]+$/, message: "Postal code should allow for all Unicode characters (UTF-8 encoding for DB storage) with exception of some white-space characters such as: nonbreaking space, the tab, the hard line break, the carriage return, the hard page break." },
		],
	}
}

const vsmCredebtor = {
	add: 'Debtor/Creditor Saved Successfully',
	edit: 'Debtor/Creditor Updated Successfully',
	delete: 'Debtor/Creditor Removed successfully.',
	validation: {
		role: [
			{ required: true, message: "Please select Role." },
		],
		credebtor_name: [
			{ required: true, message: "Credebtor Name cannot be blank." },
			{ pattern: /^[^\t\n]+$/, message: "Credebtor Name should allow for all Unicode characters (UTF-8 encoding for DB storage) with exception of some white-space characters such as: - nonbreaking space, the tab, the hard line break, the carriage return, the hard page break." },
		],
		status: [
			{ required: true, message: "Please select Status" }
		],
		description: [
			{ required: true, message: "Description cannot be blank." },
		],
		counterparty_since: [
			{ required: true, message: "Please select Counterparty since." },
		],
		historic_value: [
			{ pattern: /^((\d+)((\.\d{1,6})?))$/, message: "Total Historic Value should contain Numeric Values & Dot (Decimal upto 6 places). Other special characters and Alphabets are not allowed" }
		],
		payment_cycle: [
			{ required: true, message: "Average Payment Cycle cannot be blank." },
			{ pattern: /^(0|[1-9][0-9]*)$/, message: "Average Payment Cycle should contain only Numeric Values. Other special characters and Alphabets are not allowed" }
		],
		notes_issued: [
			{ pattern: /^(0|[1-9][0-9]*)$/, message: "Credit Notes Issued should contain only Numeric Values. Other special characters and Alphabets are not allowed" }
		],
		invoice_predicted: [
			{ required: true, message: "Invoice Predicted Per Annum cannot be blank." },
			{ pattern: /^(0|[1-9][0-9]*)$/, message: "Invoice Predicted Per Annum should contain only Numeric Values. Other special characters and Alphabets are not allowed" }
		],
		predicted_annual_revenue: [
			{ required: true, message: "Predicted Annual Revenue cannot be blank." },
			{ pattern: /^(0|[1-9][0-9]*)$/, message: "Predicted Annual Revenue should contain only Numeric Values. Other special characters and Alphabets are not allowed" }
		],
		payment_dis: [
			{ required: true, message: "Agreed Early Payment Discount cannot be blank." },
			{ pattern: /^((\d+)((\.\d{1,6})?))$/, message: "Agreed Early Payment Discount should contain Numeric Values & Dot (Decimal upto 6 places). Other special characters and Alphabets are not allowed" },
			({ getFieldValue }) => ({
				validator(rule, value) {
					if (getFieldValue("agreed_early_payment_discount") <= 100) {
						return Promise.resolve();
					} else {
						return Promise.reject("Agreed Early Payment Discount can not greater then 100");
					}
				},
			}),
		],
		experience_file: [
			{ required: true, message: 'Experience File cannot be blank.' }
		],
		bank_name: [
			{ required: true, message: "Bank Name cannot be blank." },
			{ min: 3, message: "Bank Name should contain minimum 3 characters." },
			{ pattern: /^[^\t\n]+$/, message: "Bank Name should allow for all Unicode characters (UTF-8 encoding for DB storage) with exception of some white-space characters such as: - nonbreaking space, the tab, the hard line break, the carriage return, the hard page break." },
		],
		account_type: [
			{ required: true, message: "Please select Bank Account Type." },
		],
		account_number: [
			{ required: true, message: "Account Number cannot be blank." },
			{ min: 3, message: "Account Number should contain minimum 3 characters." },
			{ pattern: /^[A-Za-z0-9]*$/, message: "Account Number should contain Alphabets and Numeric characters only. Special characters are not allowed." },
		],
		iba_num: [
			{ required: true, message: "IBAN cannot be blank." },
			({ getFieldValue }) => ({
				validator(rule, value) {
					let fields = getFieldValue("banks")
					let current_field = rule.field.split("."); // For Getting Current Field Object
					let index = parseInt(current_field[1]); // Index of current object
					if (fields[index].iban !== null) {
						if (!IBAN.isValid(fields[index].iban)) {
							return Promise.reject("IBAN must be provided in valid format and valid IBAN syntax");
						}
					}
					return Promise.resolve();
				}
			})
		],
		sort_code: [
			({ getFieldValue }) => ({
				validator(rule, value) {
					let fields = getFieldValue("banks")
					let current_field = rule.field.split("."); // For Getting Current Field Object
					let index = parseInt(current_field[1]); // Index of current object
					if (fields[index].tab === "account") {
						if (fields[index].sort_code === null || fields[index].sort_code === undefined || fields[index].sort_code === '') {
							return Promise.reject("Sort code cannot be blank as you have added account number.");
						}
					}
					return Promise.resolve();
				}
			}),
			{ min: 3, message: "Sort Code should contain minimum 3 characters." },
			{ pattern: /^[0-9-]*$/, message: "Sort Code should contain Numeric & Dash characters only. Alphabets or Special characters are not allowed." }

		],
		swift_or_bic_code: [
			({ getFieldValue }) => ({
				validator(rule, value) {
					let fields = getFieldValue("banks")
					let current_field = rule.field.split("."); // For Getting Current Field Object
					let index = parseInt(current_field[1]); // Index of current object
					if (fields[index].tab === "iban") {
						if (fields[index].swift_bic === null || fields[index].swift_bic === undefined || fields[index].swift_bic === '') {
							return Promise.reject("Swift or BIC code cannot be blank as you have added IBAN");
						}
					}
					return Promise.resolve();
				}
			}),
			{ min: 3, message: "Swift or BIC Code should contain minimum 3 characters." },
			{ pattern: /^[A-Za-z0-9 -]*$/, message: "Swift or BIC Code should contain Space, Dash, Alphabetical or Numeric characters only. Other Special characters are not allowed." }
		],
	}

}

const vsmTradeFloor = {
	add: 'Debtor/Creditor Saved Successfully',
	edit: 'Debtor/Creditor Updated Successfully',
	delete: 'Debtor/Creditor Removed successfully.',
	addETR: '-ETR Trade Saved Successfully',
	submitETR: '-ETR has been successfully submitted.',
	editETR: '-ETR has been successfully updated.',
	addBETR: 'b-ETR Trade Saved Successfully',
	editBETR: 'b-ETR has been successfully updated.',
	submitBETR: 'b-ETR has been successfully submitted.',
	validation: {
		etr_type: [
			{ required: true, message: "Please select ETR Type." },
		],
		expected_date_po: [
			{ required: true, message: "Expected Date cannot be blank." },
		],
		expected_date: [
			({ getFieldValue }) => ({
				validator(rule, value) {
					if (getFieldValue('expected_date')) {
						return Promise.resolve();
					} else {
						return Promise.reject("Expected Date cannot be blank.");
					}
				}
			})
		],
		friendy_name: [
			{ required: true, message: "Friendly Name cannot be blank." },
			{ pattern: /^[^\t\n]+$/, message: "Friendly Name should allow for all Unicode characters (UTF-8 encoding for DB storage) with exception of some white-space characters such as: - nonbreaking space, the tab, the hard line break, the carriage return, the hard page break." }
		],
		discount_percentage: [
			{ required: true, message: "Agreed Early Payment Discount cannot be blank." },
			{ pattern: /^((\d+)((\.\d{1,6})?))$/, message: "Agreed Early Payment Discount should contain Numeric Values & Dot (Decimal upto 6 places). Other special characters and Alphabets are not allowed" },
			({ getFieldValue }) => ({
				validator(rule, value) {
					if (Number(getFieldValue("d_etr_purchase_discount")) <= 100) {
						return Promise.resolve();
					} else {
						return Promise.reject("Agreed Early Payment Discount can not greater then 100");
					}
				},
			}),
		],
		sell_rate: [
			{ required: true, message: "Agreed Early Payment Discount cannot be blank." },
			{ pattern: /^((\d+)((\.\d{1,6})?))$/, message: "Agreed Early Payment Discount should contain Numeric Values & Dot (Decimal upto 6 places). Other special characters and Alphabets are not allowed" },
			({ getFieldValue }) => ({
				validator(rule, value) {
					if (getFieldValue("c_etr_sell_rate") <= 100) {
						return Promise.resolve();
					} else {
						return Promise.reject("Agreed Early Payment Discount can not greater then 100");
					}
				},
			}),
		],
		po_date: [
			{ required: true, message: "PO Date cannot be blank." },
		],
		face_value: [
			{ required: true, message: "Face Value cannot be blank." },
			{ pattern: /^((\d+)((\.\d{1,2})?))$/, message: "Face Value should contain Numeric Values & Dot (Decimal upto 2 places). Other special characters and Alphabets are not allowed" },
			{ pattern: /^(0*[1-9][0-9]*(\.[0-9]+)?|0+\.[0-9]*[1-9][0-9]*)$/, message: 'Face value must be greater than 0.' },
			({ getFieldValue }) => ({
				validator(rules, value) {
					if (parseFloat(Number(getFieldValue('face_value')).toFixed(2)) > parseFloat(Number(getFieldValue('remaining_value')).toFixed(2))) {
						return Promise.reject('Face value should not exceed more than remaining value.')
					} else {
						return Promise.resolve()
					}
				}
			})
		],
		po_face_value: [
			{ required: true, message: "Face Value cannot be blank." },
			{ pattern: /^((\d+)((\.\d{1,2})?))$/, message: "Face Value should contain Numeric Values & Dot (Decimal upto 2 places). Other special characters and Alphabets are not allowed" },
		],
		term: [
			{ required: true, message: "Term cannot be blank." },
			{ pattern: /^(0|[0-9][0-9]*)$/, message: "Term should contain Numeric Values. Other special characters and Alphabets are not allowed" }
		],
		currency_id: [
			{ required: true, message: "Please select Currency." },
		],
		po_for: [
			{ required: true, message: "Upload PO cannot be blank." },
		],
		po_number: [
			{ required: true, message: "PO Number cannot be blank." },
			{ pattern: /^[ A-Za-z0-9_@.#]*$/, message: "PO Number should contain alphabets, numeric and special characters like hash, underscore , @ , dot , and space. Other special characters are not allowed." }
		],
		po_file: [
			{ required: true, message: "PO File cannot be blank." },
		],
		payment_disc: [
			{ required: true, message: "PO File cannot be blank." },
		],
		remaining_value: [
			{ required: true, message: "Remaining Value cannot be blank." },
		],
		invoice_date: [
			{ required: true, message: "Invoice Date cannot be blank." },
		],
		pod: [
			{ required: true, message: "Purchase Order cannot be blank." },
			{ pattern: /^[ A-Za-z0-9_@.#]*$/, message: "Purchase Order should contain alphabets, numeric and special characters like hash, underscore , @ , dot , and space. Other special characters are not allowed." }
		],
		pod_select: [
			{ required: true, message: "Please select Purchase Order." },
		],
		invoice_no: [
			{ required: true, message: "Invoice Number cannot be blank." },
			{ pattern: /^(0|[1-9][0-9]*)$/, message: "Invoice Number should contain only Numeric Values. Other special characters and Alphabets are not allowed" }
		],
		attach_invoice: [
			{ required: true, message: "Attach Invoice cannot be blank." },
			({ getFieldValue }) => ({
				validator(rule, value) {
					if (getFieldValue('file_support') && getFieldValue('file_support').fileList?.length === 0) {
						return Promise.reject('Attach Invoice cannot be blank.');
					} else {
						return Promise.resolve()
					}
				}
			})
		],
		po_attach: [
			{ required: true, message: "Attach PoD cannot be blank." },
			({ getFieldValue }) => ({
				validator(rule, value) {
					if (getFieldValue('po_attach') && getFieldValue('po_attach').fileList?.length === 0) {
						return Promise.reject('Attach PoD cannot be blank.');
					} else {
						return Promise.resolve()
					}
				}
			})
		],
		b_etr: [
			{ required: true, message: "Select b-ETR cannot be blank." },
		],
		deliver_to: [
			{ required: true, message: "Deliver NoA to cannot be blank." },
		],
		payment_terms: [
			{ required: true, message: "Payment Terms cannot be blank." },
			{ pattern: /^(0|[1-9][0-9]*)$/, message: "Payment Terms should contain only Numeric Values. Other special characters and Alphabets are not allowed" }
		],
		invoice_for: [
			{ required: true, message: "Upload ETR Invoice for cannot be blank." },
		],
		c_etr_term: [
			{ required: true, message: "c-ETR Loan Terms cannot be blank." },
		],
		batch_no: [
			{ required: true, message: 'Batch No. cannot be blank.' },
			{ pattern: /^[^\t\n]+$/, message: "Batch No. should allow for all Unicode characters (UTF-8 encoding for DB storage) with exception of some white-space characters such as: - nonbreaking space, the tab, the hard line break, the carriage return, the hard page break." }
		],
		etr_months: [
			{ required: true, message: 'Months cannot be blank.' },
			{ pattern: /^(0|[0-9][0-9]*)$/, message: "Months should only contain Numeric Values. Other special characters and Alphabets are not allowed" }
		],
		a_f_sell_rate: [
			{ required: true, message: 'Sell Rate discount percentage cannot be blank.' },
			{ pattern: /^((\d+)((\.\d{1,6})?))$/, message: "Sell Rate should contain only numeric values and dot (decimal values up to six digits). Other special characters and Alphabets are not allowed" },
			({ getFieldValue }) => ({
				validator(rules, value) {
					if (Number(getFieldValue('a_f_sell_rate')).toFixed(6) > 100) {
						return Promise.reject('Sell Rate cannot be greater than 100')
					} else {
						return Promise.resolve()
					}
				}
			})
		],
		start_date: [
			{ required: true, message: 'Start Date cannot be blank.' }
		],
		end_date: [
			{ required: true, message: 'End Date cannot be blank.' }
		],
		purchase_date: [
			{ required: true, message: 'Purchase Date cannot be blank.' }
		],
		asset_name: [
			{ required: true, message: 'Asset Name cannot be blank.' },
			{ min: 1, message: 'Asset Name should contain minimum 1 character.' },
			{ pattern: /^[^\t\n]+$/, message: "Asset Name should allow for all Unicode characters (UTF-8 encoding for DB storage) with exception of some white-space characters such as: - nonbreaking space, the tab, the hard line break, the carriage return, the hard page break." }
		],
		asset_number: [
			{ required: true, message: 'Asset Number cannot be blank.' },
			{ min: 1, message: 'Asset Number should contain minimum 1 character.' },
			{ pattern: /^[^\t\n]+$/, message: "Asset Number should allow for all Unicode characters (UTF-8 encoding for DB storage) with exception of some white-space characters such as: - nonbreaking space, the tab, the hard line break, the carriage return, the hard page break." }
		],
		a_f_value: [
			{ required: true, message: 'Value cannot be blank.' },
			{ min: 1, message: 'Value should contain minimum 1 character.' },
			{ pattern: /^((\d+)((\.\d{1,2})?))$/, message: "Value should contain Numeric Values & Dot (Decimal upto 2 places). Other special characters and Alphabets are not allowed." },
			({ getFieldValue }) => ({
				validator(rules, value) {
					if (getFieldValue('value') && Number(getFieldValue('value')) <= 0) {
						return Promise.reject('The value must be greater than 0.')
					} else {
						return Promise.resolve()
					}
				}
			})
		],
		quantity_numbers: [
			{ required: true, message: 'Quantity Numbers cannot be blank.' },
			// { min: 1, message: 'Quantity Numbers should contain minimum 1 character.' },
			{ pattern: /^(0|[0-9][0-9]*)$/, message: "Quantity Numbers should contain Numeric Values . Other special characters and Alphabets are not allowed" }
		],
		asset_classification: [
			{ required: true, message: 'Asset Classification cannot be blank.' },
			{ min: 1, message: 'Asset Classification should contain minimum 1 character.' },
			{ pattern: /^[^\t\n]+$/, message: "Asset Classification should allow for all Unicode characters (UTF-8 encoding for DB storage) with exception of some white-space characters such as: - nonbreaking space, the tab, the hard line break, the carriage return, the hard page break." }

		],
		supplier_manufacturer: [
			{ required: true, message: 'Supplier/Manufacturer cannot be blank.' },
			{ min: 1, message: 'Supplier/Manufacturer should contain minimum 1 character.' },
			{ pattern: /^[^\t\n]+$/, message: "Supplier/Manufacturer should allow for all Unicode characters (UTF-8 encoding for DB storage) with exception of some white-space characters such as: - nonbreaking space, the tab, the hard line break, the carriage return, the hard page break." }
		],
		InvoiceFileDocument: [
			{ required: true, message: "Invoice File/Document cannot be blank." },
		]

	}
}

const vsmRSAList = {
	add: 'RSA has been successfully submitted.',
	validation: {
		name: [
			{ required: true, message: "Open RSA for cannot be blank cannot be blank." },
		],
		currency_id: [
			{ required: true, message: "Please select Currency." },
		],
		payment_terms: [
			{ required: true, message: "Payment Terms cannot be blank." },
			{ pattern: /^[0-9]+$/, message: "Payment Terms should only contain Numeric Values. Other special characters and Alphabets are not allowed." }
		],
		face_value: [
			{ required: true, message: "Face Value cannot be blank." },
			{ pattern: /^((\d+)((\.\d{1,2})?))$/, message: "Face Value should contain Numeric Values & Dot (Decimal upto 2 places). Other special characters and Alphabets are not allowed." },
		],
		num_etr: [
			{ required: true, message: "Number of ETR cannot be blank." },
			{ pattern: /^[0-9]+$/, message: "Number of ETR should only contain Numeric Values. Other special characters and Alphabets are not allowed." }
		],
		offer_month: [
			{ required: true, message: "Revolving Offer Months cannot be blank." },
			{ pattern: /^[0-9]+$/, message: "Revolving Offer Months should only contain Numeric Values. Other special characters and Alphabets are not allowed" },
			({ getFieldValue }) => ({
				validator(rule, value) {
					if (getFieldValue("offer_month") > 99) {
						return Promise.reject("Revolving Offer Months should contain maximum 2 digits only.");
					} else {
						return Promise.resolve();
					}
				},
			}),
		],
		description: [
			{ required: true, message: "Revolving Offer Months cannot be blank." },
		],
		file_support: [
			{ required: true, message: "Attach Supporting Document cannot be blank." },
		],
		discount_price: [
			{ required: true, message: "Monthly Discount cannot be blank." },
			({ getFieldValue }) => ({
				validator(rule, value) {
					if (getFieldValue("discount_price") <= 100) {
						return Promise.resolve();
					} else {
						return Promise.reject("Monthly Discount can not greater then 100");
					}
				},
			}),
		],
		buyout_discount_price: [
			{ required: true, message: "Buy Out Discount cannot be blank." },
			({ getFieldValue }) => ({
				validator(rule, value) {
					if (getFieldValue("buyout_discount_price") <= 100) {
						return Promise.resolve();
					} else {
						return Promise.reject("Buy Out Discount can not greater then 100");
					}
				},
			}),
		],
		desc: [
			{ required: true, message: "Description cannot be blank." },
		]
	}
}

const vsmBankAccount = {
	add: "Bank account Saved successfully.",
	edit: "Bank account Updated successfully.",
	activated: "Bank account activated successfully.",
	deactivated: "Bank account deactivated successfully.",
	validation: {
		bank_name: [
			{ required: true, message: "Bank Name cannot be blank." },
			{ min: 3, message: "Bank Name should contain minimum 3 characters." },
			{ pattern: /^[^\t\n]+$/, message: "Bank Name should allow for all Unicode characters (UTF-8 encoding for DB storage) with exception of some white-space characters such as: nonbreaking space, the tab, the hard line break, the carriage return, the hard page break." }
		],
		account_num: [
			({ getFieldValue }) => ({
				validator(value, rule) {
					if (getFieldValue("tab") === "account") {
						if ((getFieldValue('account_number') === null || getFieldValue('account_number') === undefined || getFieldValue('account_number') === '') &&
							(getFieldValue('iban') === null || getFieldValue('iban') === undefined || getFieldValue('iban') === '')) {
							return Promise.reject('Account Number cannot be blank.');
						}
					}
					return Promise.resolve();
				}
			}),
			{ min: 3, message: "Account Number should contain minimum 3 characters." },
			{ pattern: /^[A-Za-z0-9]*$/, message: "Account Number should contain Alphabets and Numeric characters only. Special characters are not allowed." }
		],
		iba_num: [
			({ getFieldValue }) => ({
				validator(value, rule) {
					if (getFieldValue("tab") === "iban") {
						if ((getFieldValue('account_number') === null || getFieldValue('account_number') === undefined || getFieldValue('account_number') === '') &&
							(getFieldValue('iban') === null || getFieldValue('iban') === undefined || getFieldValue('iban') === '')) {
							return Promise.reject('IBAN cannot be blank.');
						}
						else if (getFieldValue("iban") && !IBAN.isValid(getFieldValue("iban"))) {
							return Promise.reject('IBAN must be provided in valid format and valid IBAN syntax')
						}
					}
					return Promise.resolve();
				}
			})
		],
		sort_code: [
			({ getFieldValue }) => ({
				validator(value, rule) {
					if (getFieldValue("tab") === "account") {
						if ((getFieldValue('sort_code') === null || getFieldValue('sort_code') === undefined || getFieldValue('sort_code') === '') &&
							(getFieldValue('swift_bic') === null || getFieldValue('swift_bic') === undefined || getFieldValue('swift_bic') === '')) {
							return Promise.reject('Sort code cannot be blank as you have added account number.');
						}
					}
					return Promise.resolve();
				}
			}),
			{ min: 3, message: "Sort Code should contain minimum 3 characters." },
			{ pattern: /^[0-9- ]*$/, message: "Sort Code should contain Space, Numeric and Dash characters only. Alphabets or special characters are not allowed." }

		],
		swift_or_bic_code: [
			({ getFieldValue }) => ({
				validator(value, rule) {
					if (getFieldValue("tab") === "iban") {
						if ((getFieldValue('sort_code') === null || getFieldValue('sort_code') === undefined || getFieldValue('sort_code') === '') &&
							(getFieldValue('swift_bic') === null || getFieldValue('swift_bic') === undefined || getFieldValue('swift_bic') === '')) {
							return Promise.reject('Swift or BIC code cannot be blank as you have added IBAN');
						}
					}
					return Promise.resolve();
				}
			}),
			{ min: 3, message: "Swift or BIC Code should contain minimum 3 characters." },
			{ pattern: /^[A-Za-z0-9 -]*$/, message: "Swift or BIC Code should contain Space, Dash, Alphabetical or Numeric characters only. Other Special characters are not allowed." }
		],
		bank_ac_type: [
			{ required: true, message: "Please select Bank Account Type." },
		],
		currency: [
			{ required: true, message: "Please select Currency." }
		]
	}
}

const vsmTransaction = {
	addTransaction: 'Transaction Saved successfully.',
	validation: {
		credebtor_id: [
			{ required: true, message: 'Please select Credebtor Name.' }
		],
		trade_transaction: [
			{ required: true, message: 'Please select Trade Transaction.' }
		],
		currency_id: [
			{ required: true, message: 'Please select Currency.' }
		],
		face_value: [
			{ required: true, message: 'Face Value cannot be blank.' },
			{ pattern: /^((\d+)((\.\d{1,2})?))$/, message: "Face Value should contain Numeric Values & Dot (Decimal upto 2 places). Other special characters and Alphabets are not allowed." },
		],
		vat_rate: [
			{ required: true, message: 'Please select VAT Rate.' }
		],
		posted_date: [
			{ required: true, message: 'Please select Posted Date.' }
		],
		traded_date: [
			{ required: true, message: 'Please select Traded Date.' }
		]
	}
}

export {
	vsmAuth,
	vsmNotify,
	vsmCommon,
	vsmChangePass,
	vsmProfile,
	vsmCredebtor,
	vsmTradeFloor,
	vsmRSAList,
	vsmBankAccount,
	vsmTransaction
}