import OriginatorLogin from '../pages/Originator/OriginatorLogin';
import OriginatorForgotPass from '../pages/Originator/OriginatorForgotPass';
import OriginatorDashboard from '../pages/Originator/Dashboard';
import TradeFloor from '../pages/Originator/TradeFloor/TradeList';
import Profile from '../pages/Originator/UserProfile';
import Credebtors from '../pages/Originator/Credebtors';
import PageNotFound from '../pages/PageNotFound';
import ResetPassword from '../pages/Originator/ResetPassword';
import Bank from '../pages/Originator/Settings/Bank';
import Transactions from '../pages/Originator/Transactions';


export const Apps_RouterConfig = [
	{
		title: 'Originator Login',
		path: '/',
		component: OriginatorLogin,
		default: true
	},
	{
		title: 'Reset Password',
		path: '/reset/:id',
		component: ResetPassword,
		default: true
	},
	{
		title: 'Forgot Password',
		path: '/apps/orignator-forget-password',
		component: OriginatorForgotPass,
		default: true
	},
	{
		title: 'Dashboard',
		path: '/apps/dashboard',
		component: OriginatorDashboard,
		authdefault: true,
		apps_auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'Trade list',
		path: '/apps/trading/trade-list',
		component: TradeFloor,
		authdefault: true,
		apps_auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'All Credebtors',
		path: '/apps/credebtor/all',
		component: Credebtors,
		authdefault: true,
		apps_auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'Transactions',
		path: '/apps/transactions/transactions-list',
		component: Transactions,
		authdefault: true,
		apps_auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'All Banks',
		path: '/apps/settings/bank-list',
		component: Bank,
		authdefault: true,
		apps_auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'My Profile',
		path: '/apps/users/profile',
		component: Profile,
		authdefault: true,
		apps_auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'Page Not Found',
		path: '/',
		component: PageNotFound,
		errorpage: true,
		setting: { header: true, nav: true }
	},
]
