import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { Button, Col, Divider, Form, message, Row, Spin, Upload } from 'antd';
import InputComponent from '../../../../../components/InputComponent';
import { vsmTradeFloor } from '../../../../../config/Originator/AppsMessage';
import useStore from '../../../../../store';
import { PlusOutlined, MinusOutlined, InboxOutlined } from '@ant-design/icons';
import moment from 'moment';
import AddComponent from '../../../Credebtors/components/AddComponent';

const PODetailsElement = observer(({ form, onChange, fetchPOFor, POFor, fetchCurrency, fetchbETRCurrency, updatePoFile, poFile, params }) => {
    const { TradeFloorStore } = useStore()
    const [credebtorDrawer, setCredebtorDrawer] = useState(false)
    const [currencyArray, setCurrencyArray] = useState(null)

    const disabledDate = (current) => {
        return current && current > moment().endOf("day");
    };

    const openCredebtor = () => {
        setCredebtorDrawer(true)
    }

    const closeCredebtorDrawer = () => setCredebtorDrawer(false)

    const onDownload = (info) => {
        window.open(info.url, "_blank");
    };

    const uploadProps = {
        name: "file",
        multiple: false,
        fileList: poFile,
    };

    const handleFileUploadChange = (info, index) => {
        const { file } = info;
        let nextState = [];
        let isCSV = file.type === "application/pdf";
        let isLt1M = file.size / 1024 / 1024 < 1;

        switch (info.file.status) {
            case "uploading":
                if (!isCSV) {
                    message.error(
                        `Attach Supporting Document must be in pdf format. File Size should be less then 1 MB.`
                    );
                    updatePoFile([]);
                    return true;
                } else if (!isLt1M) {
                    message.error(
                        "Attach Supporting Document must be in pdf format. File Size should be less then 1 MB."
                    );
                    updatePoFile([]);
                    return true;
                } else {
                    nextState = [info.file];
                }
                break;
            case "done":
                if (!isCSV) {
                    message.error(
                        `Attach Supporting Document must be in pdf format. File Size should be less then 1 MB.`
                    );
                    updatePoFile([]);
                    return true;
                } else if (!isLt1M) {
                    message.error(
                        "Attach Supporting Document must be in pdf format. File Size should be less then 1 MB."
                    );
                    updatePoFile([]);
                    return true;
                } else {
                    nextState = [info.file];
                }
                break;

            default:
                nextState = [];
        }
        if (info && info.file.status === "uploading") {
            let tempValue = poFile.find(x => x.index === index)

            tempValue = tempValue ? tempValue : null

            if (tempValue) {
                let newFile = nextState[0]
                newFile.status = "done"
                tempValue.files = newFile
                poFile[index] = tempValue
                updatePoFile([...poFile])
            } else {
                let newFile = nextState[0];
                newFile.status = "done"
                updatePoFile([...poFile, { index, files: newFile }]);
            }
        }
        onChange()
    };
    const dummyRequest = ({ file, fileList, onSuccess }) => {
        setTimeout(() => {
            onSuccess("ok");
        }, 0);
    };

    const onRemove = (e, field, index) => {
        let tempObj = e
        tempObj.name = ''
        // form.setFieldsValue({ b_etr_files: tempObj, })
        let tempValue = poFile.map((item) => {
            let curentValue = item;
            if (item.index === index) {
                curentValue.files = []
            }
            return curentValue;
        })
        updatePoFile(tempValue)
        onChange()
        return false
    }

    const onChangeBETR = (e, index) => {
        const fields = form.getFieldsValue()
        const { invoice_po } = fields
        if (e) {
            let selectedOrg = TradeFloorStore.uploadFor_list.find(x => e === x.id)
            setCurrencyArray([{ currency_id: selectedOrg.currency_id, currency_name: selectedOrg.currency_name }])
            Object.assign(invoice_po[index], { currency_id: selectedOrg.currency_id, currency_object: null })
            form.setFieldsValue({ invoice_po })
        } else {
            Object.assign(invoice_po[index], { currency_id: null })
            form.setFieldsValue({ invoice_po })
        }
        onChange()
    }

    return (
        <>
            <div className="w-100">
                <Form.List name='invoice_po'>
                    {
                        (fields, { add, remove }) => {
                            return (
                                <>
                                    {fields.map((field, index) => {
                                        let files = [];
                                        poFile && poFile.forEach(element => {
                                            if (
                                                element.index === index &&
                                                !element.files.length &&
                                                element.files.length !== 0
                                            ) {
                                                files.push(element.files)
                                            }
                                        });
                                        let temp_invoice_po = form?.getFieldValue('invoice_po')
                                        let currency_obj = null
                                        if (temp_invoice_po) {
                                            if (temp_invoice_po[field.key]) {
                                                if (temp_invoice_po[field.key]['currency_object']) {
                                                    currency_obj = temp_invoice_po[field.key]['currency_object']
                                                    let new_obj = {
                                                        currency_id: currency_obj['id'],
                                                        currency_name: currency_obj['currency_name']
                                                    }
                                                    currency_obj = [new_obj]
                                                }
                                            }
                                        }
                                        return (
                                            <div className="w-100" key={field.key}>
                                                <div>
                                                    <Row gutter={10} className="align-items-center">
                                                        <Col span={23}>
                                                            {/* <Card style={{ margin: 10 }}> */}
                                                            <Row gutter={[8, 8]}>
                                                                <Col span={5}>
                                                                    <InputComponent
                                                                        required
                                                                        allowClear
                                                                        type="select"
                                                                        label="Upload PO for"
                                                                        name={[field.name, 'debtor_id']}
                                                                        key={[field.key, 'debtor_id']}
                                                                        placeholder="Select b-ETR"
                                                                        onChange={(e) => { currency_obj = null; onChangeBETR(e, index) }}
                                                                        rules={vsmTradeFloor.validation.po_for}
                                                                        tooltip="Select the Debtor name that appears on the invoice you are about to upload"
                                                                        onFocus={() => (POFor && TradeFloorStore.getUploadFor('Debtor').then(() => fetchPOFor(false)))}
                                                                        notFoundContent={POFor ? <Spin size="small" /> : "No Record Found."}
                                                                        options={{
                                                                            values: TradeFloorStore.uploadFor_list || (
                                                                                TradeFloorStore.readETRvalue && TradeFloorStore.readETRvalue.b_etr_files && TradeFloorStore.readETRvalue.b_etr_files.map((item) => ({
                                                                                    id: item.debtor && item.debtor.id,
                                                                                    organisation_name: item.debtor && item.debtor.organisation_name
                                                                                }))
                                                                            ),
                                                                            value_key: "id",
                                                                            text_key: "organisation_name"
                                                                        }}
                                                                        note={params === 'view' ? null : <div onClick={openCredebtor} key="1" className='extra_note'>Add a new counterparty</div>}
                                                                        disabled={params === 'view'}
                                                                    />
                                                                </Col>

                                                                <Col span={5}>
                                                                    <InputComponent
                                                                        required
                                                                        type="select"
                                                                        label="Currency"
                                                                        name={[field.name, 'currency_id']}
                                                                        key={[field.key, 'currency_id']}
                                                                        placeholder="Select Currency"
                                                                        onChange={onChange}
                                                                        tooltip="The currency is pre-set according to currency selection made when creating the Debtor and cannot be changed."
                                                                        options={{
                                                                            values: currency_obj || (
                                                                                currencyArray && currencyArray.map((item) => ({
                                                                                    currency_id: item.currency_id && item.currency_id,
                                                                                    currency_name: item.currency_name && item.currency_name
                                                                                }))
                                                                            ),
                                                                            // values: (currency_obj) || (TradeFloorStore.readETRvalue && (
                                                                            //     currencyArray && currencyArray.map((item) => ({
                                                                            //         currency_id: item.currency_id && item.currency_id,
                                                                            //         currency_name: item.currency_name && item.currency_name
                                                                            //     }))
                                                                            // )),
                                                                            value_key: "currency_id",
                                                                            text_key: "currency_name"
                                                                        }}
                                                                        disabled
                                                                    />
                                                                </Col>
                                                                <Col span={5}>
                                                                    <InputComponent
                                                                        required
                                                                        type="date"
                                                                        name={[field.name, 'po_date']}
                                                                        key={[field.key, 'date']}
                                                                        label="PO Date (YYYY/MM/DD)"
                                                                        placeholder="Select Date"
                                                                        onChange={onChange}
                                                                        tooltip="Enter the PO date (i.e. not necessarily today’s date)"
                                                                        rules={vsmTradeFloor.validation.po_date}
                                                                        className="date_wrapper"
                                                                        disabledDate={disabledDate}
                                                                        disabled={params === 'view'}
                                                                    />
                                                                </Col>

                                                                <Col span={5}>
                                                                    <InputComponent
                                                                        required
                                                                        label="Face value"
                                                                        name={[field.name, 'po_face_value']}
                                                                        key={[field.key, 'po_face_value']}
                                                                        placeholder="Face value"
                                                                        tooltip="Enter the total invoice value including all delivery charges, support charges, tax, duties, etc."
                                                                        rules={vsmTradeFloor.validation.po_face_value}
                                                                        maxLength={10}
                                                                        onChange={onChange}
                                                                        onBlur={(e) => {
                                                                            const fields = form.getFieldsValue()
                                                                            const { invoice_po } = fields
                                                                            Object.assign(invoice_po[index], { po_face_value: Number(e.target.value).toFixed(2) })
                                                                            form.setFieldsValue({ invoice_po })

                                                                        }}
                                                                        disabled={params === 'view'}
                                                                    />
                                                                </Col>

                                                                <Col span={4}>
                                                                    <InputComponent
                                                                        required
                                                                        label="PO Number"
                                                                        name={[field.name, 'po_no']}
                                                                        key={[field.key, 'po_no']}
                                                                        placeholder="Enter PO Number"
                                                                        onChange={onChange}
                                                                        tooltip="Enter the PO number"
                                                                        rules={vsmTradeFloor.validation.po_number}
                                                                        maxLength={20}
                                                                        disabled={params === 'view'}
                                                                    />
                                                                </Col>
                                                            </Row>

                                                            <Row gutter={[8, 8]}>
                                                                <Col span={12}>
                                                                    <Form.Item
                                                                        required
                                                                        label="Attach PO File"
                                                                        tooltip="Attach a PDF of the purchase order supporting this invoice"
                                                                    >
                                                                        <Form.Item
                                                                            required
                                                                            className="full-width-dragger"
                                                                            name={[field.name, 'po_file']}
                                                                            key={[field.key, 'po_file']}
                                                                            rules={vsmTradeFloor.validation.po_file}
                                                                        >
                                                                            <Upload.Dragger
                                                                                {...uploadProps}
                                                                                onChange={(e) => { handleFileUploadChange(e, index); }}
                                                                                customRequest={dummyRequest}
                                                                                showUploadList={{ showDownloadIcon: true, showRemoveIcon: true }}
                                                                                onDownload={onDownload}
                                                                                fileList={files}
                                                                                onRemove={(e) => { onRemove(e, field, index) }}
                                                                                disabled={params === 'view'}
                                                                            >
                                                                                <p className="ant-upload-drag-icon">
                                                                                    <InboxOutlined />
                                                                                </p>
                                                                                <p className="ant-upload-text">
                                                                                    Drag your PDF file here or select by clicking here
                                                                                </p>
                                                                            </Upload.Dragger>
                                                                        </Form.Item>
                                                                    </Form.Item>
                                                                </Col>
                                                            </Row>
                                                            {/* </Card> */}
                                                        </Col>
                                                        {
                                                            (fields.length > 1) && (
                                                                <Col span={1} style={{ alignItems: 'center' }}>
                                                                    <Button disabled={params === 'view'} type="danger" onClick={async () => {
                                                                        await remove(field.name)
                                                                        onChange()
                                                                    }} icon={<MinusOutlined />}></Button>
                                                                </Col>
                                                            )
                                                        }
                                                        {/* <InputComponent
                                                            required
                                                            type="hidden"
                                                            name="root_invoice_id"

                                                        /> */}
                                                    </Row>
                                                </div>
                                            </div>
                                        )
                                    })}
                                    {
                                        (fields.length <= 11) && (
                                            <Divider>
                                                <Button disabled={params === 'view'} type="primary" onClick={async () => {
                                                    await add()
                                                    onChange()
                                                }} block icon={<PlusOutlined />}>Add More</Button>
                                            </Divider>
                                        )
                                    }
                                </>
                            )
                        }
                    }
                </Form.List>
            </div>
            <AddComponent visible={credebtorDrawer} close={closeCredebtorDrawer} param='fromPO' fetchPOFor={fetchPOFor} />
        </>
    );
})

export default PODetailsElement;