import React, { useCallback, useState } from 'react'
import { observer } from "mobx-react"
import { Form, Col, Button, Row, Divider } from 'antd'
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import BankDetailsSubElements from './SubElement/BankDetailsSubElements';

const BankDetailsElement = observer(({ onChange, setAccountType, setCurrency, accountType, currency, currentTab, setCurrentTab, form, setFetchBankType, fetchBankType }) => {

    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);
    const onTabChanges = () => {
        let add_ons = form.getFieldValue("banks");
        if (add_ons.length > 0) {
            add_ons.map(obj => {
                if (obj.tab === "account") {
                    obj.is_account = true;
                    obj.iban = null
                    obj.swift_bic = null
                }
                else {
                    obj.is_account = false;
                    obj.account_number = null
                    obj.sort_code = null
                }
                return null;
            })
        }
        forceUpdate();
    }
    return (
        <div className="w-100">
            <Form.List name='banks'>
                {
                    (fields, { add, remove }) => {
                        return (
                            <>
                                {fields.map((field, index) => {
                                    return (
                                        <div className="w-100" key={field.key}>
                                            <div>
                                                <Row gutter={10} className="align-items-center">
                                                    <Col span={23}>
                                                        {/* <Card style={{ margin: 10 }}> */}
                                                        <BankDetailsSubElements index={index} onChange={onChange} field={field} onTabChanges={onTabChanges} setAccountType={setAccountType} accountType={accountType} setCurrency={setCurrency} currency={currency} currentTab={currentTab} setCurrentTab={setCurrentTab} form={form} setFetchBankType={setFetchBankType} fetchBankType={fetchBankType} />
                                                        {/* </Card> */}
                                                    </Col>
                                                    {
                                                        <Col span={1}><Button type="danger" onClick={async () => {
                                                            await remove(field.name)
                                                            onChange()
                                                        }} icon={<MinusOutlined />}></Button></Col>
                                                    }

                                                </Row>
                                            </div>
                                        </div>
                                    )
                                })}
                                <Divider>
                                    <Button type="primary" onClick={async () => {
                                        await add({
                                            bank_name: null,
                                            bank_account_type_id: null,
                                            bank_currency_id: null,
                                            account_number: null,
                                            sort_code: null,
                                            iban: null,
                                            swift_bic: null,
                                            tab: 'account',
                                            is_account: true
                                        })
                                        onChange()
                                    }} block icon={<PlusOutlined />}>Add Bank </Button>
                                </Divider>
                            </>
                        )
                    }
                }
            </Form.List >
        </div>
    )
})

export default BankDetailsElement
