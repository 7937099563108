import { Button, PageHeader } from 'antd';
import React, { useEffect, useState } from 'react'
import EnterFullscreen from '../../../../components/EnterFullscreen';
import AppsBreadcrumbComponent from '../../../../components/Originator/AppsBreadcrumbComponent';
import RecordPerPage from '../../../../components/RecordPerPage';
import { AppsBreadcrumbConfig } from '../../../../config/BreadcrumbConfig';
import useStore from '../../../../store';
import Unauthorized from '../../../Unauthorized';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUndo } from '@fortawesome/free-solid-svg-icons'
import { observer } from 'mobx-react';
import ListComponent from './components/ListComponent';
import AddComponent from './components/AddComponent';
import { useHistory, useLocation } from 'react-router-dom';
import StatusComponent from './components/StatusComponent';
import EditComponent from './components/EditComponent';

const Bank = observer(() => {
    const history = useHistory()
    const { AppsAuthStore, BanksStore } = useStore()
    const [openAddBank, setOpenAddBank] = useState(useLocation().search === '?open-bank-popup=1')
    const [openStatusModel, setOpenStatusModel] = useState(false)
    const [openEditDrawer, setOpenEditDrawer] = useState(false)

    history.listen((location, action) => {
        if (location.search === '?open-bank-popup=1') {
            setOpenAddBank(true)
        } else {
            setOpenAddBank(false)
        }
    })

    const openBankDrawer = () => {
        setOpenAddBank(true)
    }

    const closeBankDrawer = () => {
        setOpenAddBank(false)
        history.replace(history.location.pathname)
    }

    const openSwitchModel = (rowData) => {
        setOpenStatusModel(rowData)
    }

    const closeStatusModal = () => {
        setOpenStatusModel(false)
    }

    const openEditBankDrawer = (readID) => {
        setOpenEditDrawer(true)
        BanksStore.readBankDetail(readID)
    }

    const closeEditDrawer = () => {
        setOpenEditDrawer(false)
    }

    useEffect(() => {
        BanksStore.getList()
    }, [BanksStore])

    return (
        <>
            <AppsBreadcrumbComponent items={AppsBreadcrumbConfig.All_Banks.path} />
            {AppsAuthStore.permissions && (!AppsAuthStore.checkPermission(1024, 'list')) ? (<Unauthorized />) : (
                <PageHeader
                    className={AppsAuthStore.fullscreen_class}
                    title={AppsBreadcrumbConfig.All_Banks.title}
                    extra={[
                        (AppsAuthStore.checkPermission(1024, 'add')) && <Button key="1" onClick={openBankDrawer}>Add Bank</Button>,
                        <RecordPerPage key="2" defaultValue={BanksStore.per_page + ' per page'} onChange={BanksStore.setPageSize} />,
                        <Button key="3" title="Reset" onClick={BanksStore.handleReset}><FontAwesomeIcon icon={faUndo} /></Button>,
                        <EnterFullscreen key='4' />
                    ]}
                >
                    <ListComponent openEditBankDrawer={openEditBankDrawer} openSwitchModel={openSwitchModel} />
                    {openAddBank && <AddComponent visible={openAddBank} close={closeBankDrawer} />}
                    {openStatusModel && <StatusComponent visible={openStatusModel} close={closeStatusModal} />}
                    {openEditDrawer && <EditComponent visible={openEditDrawer} close={closeEditDrawer} />}
                </PageHeader>
            )}
        </>
    )
})

export default Bank;