import React from 'react'
import Moment from 'moment'
import NumberFormat from 'react-number-format'
import Axios from 'axios'


export const AppAxios = Axios.create()

export const defaultAPIURL = process.env.React_APP_DOCS_URL ? process.env.React_APP_DOCS_URL : 'http://credebt.silicontechnolabs.com:8001/apps/'

export const DateComparator = (filterLocalDateAtMidnight, cellValue) => {
	//using moment js
	var dateAsString = Moment(cellValue).format('DD/MM/YYYY')
	var dateParts = dateAsString.split("/")
	var cellDate = new Date(Number(dateParts[2]), Number(dateParts[1]) - 1, Number(dateParts[0]))

	if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
		return 0
	}

	if (cellDate < filterLocalDateAtMidnight) {
		return -1
	}

	if (cellDate > filterLocalDateAtMidnight) {
		return 1
	}
}

export function dateFormatter(params) {
	var dateAsString = params.data.expected_date;
	var dateParts = dateAsString.split('-');
	return `${dateParts[2]} - ${dateParts[1]} - ${dateParts[0]}`;
}

export const app_flag = {
	0: "Credebt-Machine",
	1: "Originator app",
	2: "Both"
}

export const dETRwithoutBETR = { bko_invoices_id: 0, friendly_name: "Without b-etr" }
export const cETRwithoutBETR = { id: 0, friendly_name: "Without b-etr" }

export const PO_Status = {
	1: 'Draft',
	2: 'Awaiting Approval',
	3: 'Approved',
	4: 'Rejected'
}

export const etr_type = {
	1: 'a-ETR',
	2: 'b-ETR',
	3: 'c-ETR',
	4: 'd-ETR',
	5: 'f-ETR',
	6: 'RSA'
}


export const globalStatus = (module_name, param_type, param) => {
	let cash_transaction_status = {
		0: 'Pending',
		1: 'Imported / added',
		2: 'Auto Identified',
		3: 'Manually Verified',
		4: 'Problematic / Unidentified',
		5: 'Problematic / Identified'
	}
	let reconcile_status = {
		0: 'Pending(Identified)',
		1: 'Partially Reconciled',
		2: 'Fully Reconciled'
	}
	let cash_import = {
		1: 'Import Success',
		2: 'Partial Import',
		3: 'Pending',
		4: 'In Progress',
		5: 'Failed',
		6: 'Cancel',
		7: 'Delete',
	}
	let cash_import_log = {
		1: 'Imported / added',
		2: 'Auto Identified',
		3: 'Manually Verified',
		4: 'Problematic / Unidentified',
		5: 'Problematic / Identified',
	}
	let reconcile_transaction_status = {
		0: "Pending(Identified)",
		1: "Partially Reconciled",
		2: "Fully Reconciled"
	}
	let ppsn_document_type = {
		1: 'Legal Correspondence',
		2: 'P21 (Tax Balancing Statement)',
		3: 'Tax Assessment',
		4: 'Notice of Tax Credits',
		5: 'Payslip',
		6: 'P60',
		7: 'P45'
	}

	let trade_list_status = {
		1: 'Draft',
		2: 'Awaiting Approval',
		3: 'Approved',
		4: 'Rejected',
	}

	switch (module_name) {
		case 'cash_transaction_status':
			return getStatusValue(cash_transaction_status, param_type, param)
		case 'reconcile_status':
			return getStatusValue(reconcile_status, param_type, param)
		case 'cash_import':
			return getStatusValue(cash_import, param_type, param)
		case 'cash_import_log':
			return getStatusValue(cash_import_log, param_type, param)
		case 'reconcile_transaction_status':
			return getStatusValue(reconcile_transaction_status, param_type, param)

		case 'ppsn_document_type':
			return getStatusValue(ppsn_document_type, param_type, param)

		case 'trade_list_status':
			return getStatusValue(trade_list_status, param_type, param)

		default:
			return null
	}
}

const getStatusValue = (array, param_type, param) => {
	if (param_type === 'key') {
		return array[param]
	} else {
		return parseInt(Object.keys(array).find((x) => array[x] === param ? x : false))
	}
}

export const CurrencyFormat = (prop) => {
	return (
		<NumberFormat value={prop.value} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true} decimalScale={2} />
	)
}

export const convertTextToID = (text_array, main_array, text_key, id_key) => {
	let new_array = []
	if (text_array && text_array.values && text_array.values.length > 0) {
		text_array.values.forEach(x => {
			var temp = main_array.find(y => y[text_key] === x)
			if (x && temp) { new_array.push(temp[id_key]) } else {
				insertAt(new_array, 0, x)
			}
		})
	}
	return new_array
}

function insertAt(array, index, ...elementsArray) {
	array.splice(index, 0, ...elementsArray);
}

// module path
export const relatedOrgPath = "/organisations/all-organisations/role-mapping"
export const relatedPeoplePath = "/people/all-people/role-mapping"
export const orgBanksPath = "/organisations/all-organisations/bank-accounts"
export const peopleBanksPath = "/people/all-people/bank-accounts"
export const allTransactionPath = "/trade-transactions/all-transactions"
export const cashTransactionPath = "/cash-transactions/all-transaction"
export const peoplePhotoURL = process.env.React_APP_API_URL + 'storage/peoplephoto/'

export const convertError = (formatedErrors) => {
	formatedErrors.forEach((x) => {
		if (x.name.indexOf(".") !== -1) {
			x.name = x.name.split(".");
			x.name.forEach((e, i) => {
				if (!isNaN(parseInt(e))) {
					x.name[i] = parseInt(e);
				}
			});
		}
	});
	return formatedErrors
}
