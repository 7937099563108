import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react';
import { AppsBreadcrumbConfig } from '../../../../config/BreadcrumbConfig';
import useStore from '../../../../store';
import { Button, PageHeader } from 'antd';
import ListComponent from './components/ListComponent';
import AddComponent from './components/AddComponent';
import EditComponent from './components/EditComponent';
import Unauthorized from '../../../Unauthorized';
import AppsBreadcrumbComponent from '../../../../components/Originator/AppsBreadcrumbComponent';
import RecordPerPage from '../../../../components/RecordPerPage';
import EnterFullscreen from '../../../../components/EnterFullscreen';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUndo } from '@fortawesome/free-solid-svg-icons'
import ViewComponent from './components/ViewComponent';
import RSAAddComponent from './components/RSAList/AddComponent';

const TradeFloor = observer(() => {
    let history = useHistory()
    const { AppsAuthStore, TradeFloorStore } = useStore()
    const [tradeDrawer, setOpenTradeDrawer] = useState(useLocation().search === '?upload-etr-popup=1')
    const [RSAtradeDrawer, setOpenRSATradeDrawer] = useState(useLocation().search === '?open-trade-popup=1')
    const [editTradeDrawer, setEditTradeDrawer] = useState(false)
    const [viewTradeDrawer, setViewTradeDrawer] = useState(false)
    const [viewData, setViewData] = useState(null)
    const [permissionETRs, setPermissionETRs] = useState([])
    const [viewId, setviewID] = useState(null)

    history.listen((location, action) => {
        if (location.search === '?open-trade-popup=1') {
            setOpenRSATradeDrawer(true)
            setOpenTradeDrawer(false)
        } else if (location.search === '?upload-etr-popup=1') {
            setOpenTradeDrawer(true)
            setOpenRSATradeDrawer(false)
        } else {
            setOpenTradeDrawer(false)
            setOpenRSATradeDrawer(false)
        }
    })

    //open add drawer
    const openTradeDrawer = () => {
        setOpenTradeDrawer(true)
    }

    //close add drawer
    const closeTradeDrawer = () => {
        setOpenTradeDrawer(false)
        history.replace(history.location.pathname)
    }

    const OpenRSATrade = () => {
        setOpenRSATradeDrawer(true)
    }

    const closeRSATradeDrawer = () => {
        setOpenRSATradeDrawer(false)
        history.replace(history.location.pathname)
    }

    //open edit drawer
    const openEditTradeDrawer = (id) => {
        TradeFloorStore.readTrade(id).then(() => {
            TradeFloorStore.getTermLoan(TradeFloorStore.readETRvalue?.etr_type)
            if (![1, 2, 5].includes(TradeFloorStore.readETRvalue?.etr_type)) {
                let payload = {
                    debtor_id: TradeFloorStore.readETRvalue?.debtor?.id,
                    etr_type: TradeFloorStore.readETRvalue?.etr_type,
                    trade_id: TradeFloorStore.readETRvalue?.id
                }
                TradeFloorStore.getRemainingValues(payload)
            }
            setEditTradeDrawer(true)
        })
    }

    //open edit drawer
    const openViewTradeDrawer = (id) => {
        setViewData('view')
        setviewID(id)
        setViewTradeDrawer(true)
    }

    //close edit drawer
    const closeEditTradeDrawer = () => setEditTradeDrawer(false)

    const closeViewTradeDrawer = () => {
        setViewTradeDrawer(false)
        setviewID(null)
    }

    useEffect(() => {
        var ETRPermission = AppsAuthStore.permissions ? AppsAuthStore.permissions[1005] : []
        setPermissionETRs(ETRPermission.filter(function (n) { return !this.has(n) }, new Set(['edit', 'list', 'show', 'RSA'])))
        return () => localStorage.removeItem('selectedFilter')
    }, [AppsAuthStore.permissions])

    return (
        <>
            <AppsBreadcrumbComponent items={AppsBreadcrumbConfig.TradeFloor.path} />
            {AppsAuthStore.permissions && (!AppsAuthStore.checkPermission(1005, 'list')) ? (<Unauthorized />) : (
                <PageHeader
                    className={AppsAuthStore.fullscreen_class}
                    title={AppsBreadcrumbConfig.TradeFloor.title}
                    extra={[
                        (AppsAuthStore.checkPermission(1005, 'RSA')) && <Button key="1" onClick={OpenRSATrade}>Open a Trade</Button>,
                        permissionETRs.length > 0 && <Button key="2" onClick={openTradeDrawer}>Upload ETR</Button>,
                        <RecordPerPage key="3" defaultValue={TradeFloorStore.per_page + ' per page'} onChange={TradeFloorStore.setPageSize} />,
                        <Button key="4" title="Reset" onClick={TradeFloorStore.handleReset}><FontAwesomeIcon icon={faUndo} /></Button>,
                        <EnterFullscreen key='5' />
                    ]}
                >
                    <ListComponent openEditTradeDrawer={openEditTradeDrawer} openViewTradeDrawer={openViewTradeDrawer} />
                    <AddComponent visible={tradeDrawer} close={closeTradeDrawer} />
                    <RSAAddComponent visible={RSAtradeDrawer} close={closeRSATradeDrawer} />
                    <EditComponent visible={editTradeDrawer} close={closeEditTradeDrawer} />
                    <ViewComponent visible={viewTradeDrawer} close={closeViewTradeDrawer} params={viewData} id={viewId} />
                </PageHeader>
            )}
        </>
    );
})

export default TradeFloor;