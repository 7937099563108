import React, { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Card, Col, Collapse, Divider, Form, message, Row, Spin, Tooltip, Upload } from 'antd';
import InputComponent from '../../../../../components/InputComponent';
import { vsmTradeFloor } from '../../../../../config/Originator/AppsMessage';
import { PercentageOutlined, InboxOutlined, QuestionCircleOutlined, PlusOutlined, MinusOutlined } from '@ant-design/icons';
import useStore from '../../../../../store';
import moment from 'moment';
import AddComponent from '../../../Credebtors/components/AddComponent';
import { cETRwithoutBETR, dETRwithoutBETR } from '../../../../../utils/GlobalFunction';
import PODetailsElement from '../elements/PODetailsElement';
const { Panel } = Collapse;

const FormComponent = observer(({ formType, defaultFormDataList, form, handleSubmit, setDisabled, updateAttachPoD, attachPoD, updateAttachInvoice, attachInvoice, formDataList, setFormDataList, invoiceLoader, setInvoiceLoader, betrLoader, setbetrLoader, params, vatIncluded, setVatIncluded, ISOcodeAlpha2, setISOcodeAlpha2, updateSupportingFile, supportingFile, fetchPOFor, POFor, fetchbETRCurrency, bETRCurrency, updatePoFile, poFile, activeKey }) => {
	const { AppsAuthStore, TradeFloorStore } = useStore()
	const [credebtorDrawer, setCredebtorDrawer] = useState(false)
	const [preFieldValues, setPreFieldValues] = useState(null)
	const [selectbETR, setSelectbETR] = useState(null)
	const [currencyArray, setCurrencyArray] = useState(null)
	const [finalbETR, setFinalbETR] = useState(null)
	const [POlist, setPOlist] = useState([])
	const [remainVal, setRemainVal] = useState(null)
	const [tempETRList, setTempETRList] = useState([])
	const [, updateState] = useState();
	const forceUpdate = useCallback(() => updateState({}), []);
	const [loanTerm, setLoanTerm] = useState('')

	const scrollDiv = {
		height: '300px',
		overflowY: 'scroll',
		border: '1px solid #d9d9d9',
		padding: '10px',
		margin: '6px 0'
	};

	const handleChange = async () => {
		await setFormDataList(getFormDataList())
		form.validateFields()
			.then((data) => {
				if (formDataList.includes('cETR') || formDataList.includes('dETR')) {
					if (attachInvoice.length || (form.getFieldValue('betr_id') === 0 && attachPoD.length)) {
						setDisabled(false);
					} else {
						setDisabled(true);
					}
				} else if (formDataList.includes('aETR') || formDataList.includes('fETR')) {
					if (supportingFile.length || attachInvoice.length) {
						setDisabled(false);
					} else {
						setDisabled(true);
					}
				} else {
					setDisabled(false);
				}
			})
			.catch((e) => {
				if (e.errorFields && e.errorFields.length > 0) {
					setDisabled(true);
				}
			});
	};

	const changeETRType = (e) => {
		TradeFloorStore.getTermLoan(e);
		TradeFloorStore.remainingValues = null
		form.resetFields(['face_value', 'term', 'debtor_id', 'b_etr', 'betr_id', 'deliver_to', 'term', 'payment_discount', 'currency_id', 'invoice_date', 'expected_date', 'po_no', 'invoice_no'])
		updateAttachPoD(null)
		updateAttachInvoice(null)
		setInvoiceLoader(true)
		setbetrLoader(true)
		handleChange()
		if (e === 1) { setLoanTerm('a') }
		else if (e === 2) { setLoanTerm('b') }
		else if (e === 3) { setLoanTerm('c') }
		else if (e === 4) { setLoanTerm('d') }
		else if (e === 5) { setLoanTerm('f') }
		else { setLoanTerm('') }
	}

	const getFormDataList = useCallback(() => {
		var FormDataList = defaultFormDataList
		switch (form.getFieldValue('etr_type')) {
			case 1:
				FormDataList = [...FormDataList, 'aETR']
				break;
			case 2:
				FormDataList = [...FormDataList, 'bETR']
				break;
			case 3:
				FormDataList = [...FormDataList, 'cETR']
				break;
			case 4:
				FormDataList = [...FormDataList, 'dETR']
				break;
			case 5:
				FormDataList = [...FormDataList, 'fETR']
				break;
			default:
		}
		return FormDataList
	}, [defaultFormDataList, form])

	const onFinish = (data) => {
		handleSubmit(data)
	}

	const disabledInvoiceDate = (current) => {
		return current && current > moment().endOf("day");
	}

	const disabledExpectedDate = (current) => {
		return current && current < moment().startOf("day");
	}

	const disabledEndDate = (current) => {
		let startDate = moment(form.getFieldValue('start_date')).format("YYYY-MM-DD");
		return current && current < moment(startDate, "YYYY-MM-DD").add(1, 'days');
	}

	const onDownloadPoD = (info) => {
		window.open(info.url, "_blank");
	};

	const uploadPropsPoD = {
		name: "file",
		multiple: false,
		fileList: attachPoD,
	};

	const handleFileUploadChangePoD = (info) => {
		const { file } = info;
		let nextState = [];
		let isCSV = file.type === "application/pdf";
		let isLt1M = file.size / 1024 / 1024 < 1;

		switch (info.file.status) {
			case "uploading":
				if (!isCSV) {
					message.error(
						`Only pdf file is allowed to upload and File size should not be more than 1MB`
					);
					updateAttachPoD([]);
					return true;
				} else if (!isLt1M) {
					message.error(
						"Only pdf file is allowed to upload and File size should not be more than 1MB"
					);
					updateAttachPoD([]);
					return true;
				} else {
					nextState = [info.file];
				}
				break;
			case "done":
				if (!isCSV) {
					message.error(
						`Only pdf file is allowed to upload and File size should not be more than 1MB`
					);
					updateAttachPoD([]);
					return true;
				} else if (!isLt1M) {
					message.error(
						"Only pdf file is allowed to upload and File size should not be more than 1MB"
					);
					updateAttachPoD([]);
					return true;
				} else {
					nextState = [info.file];
				}
				break;

			default:
				nextState = [];
		}
		updateAttachPoD(nextState);
		handleChange();
	};

	const dummyRequestPoD = ({ file, fileList, onSuccess }) => {
		setTimeout(() => {
			onSuccess("ok");
		}, 0);
	};

	const onDownloadAttachInvoice = (info) => {
		window.open(info.url, "_blank");
	};

	const uploadPropsAttachInvoice = {
		name: "file",
		multiple: false,
		fileList: attachInvoice,
	};

	const handleFileUploadChangeAttachInvoice = (info) => {
		const { file } = info;
		let nextState = [];
		let isCSV = file.type === "application/pdf";
		let isLt1M = file.size / 1024 / 1024 < 1;

		switch (info.file.status) {
			case "uploading":
				if (!isCSV) {
					message.error(
						`Only pdf file is allowed to upload and File size should not be more than 1MB`
					);
					updateAttachInvoice([]);
					return true;
				} else if (!isLt1M) {
					message.error(
						"Only pdf file is allowed to upload and File size should not be more than 1MB"
					);
					updateAttachInvoice([]);
					return true;
				} else {
					nextState = [info.file];
				}
				break;
			case "done":
				if (!isCSV) {
					message.error(
						`Only pdf file is allowed to upload and File size should not be more than 1MB`
					);
					updateAttachInvoice([]);
					return true;
				} else if (!isLt1M) {
					message.error(
						"Only pdf file is allowed to upload and File size should not be more than 1MB"
					);
					updateAttachInvoice([]);
					return true;
				} else {
					nextState = [info.file];
				}
				break;

			default:
				nextState = [];
		}
		updateAttachInvoice(nextState);
		handleChange()
	};
	const dummyRequestAttachInvoice = ({ file, fileList, onSuccess }) => {
		setTimeout(() => {
			onSuccess("ok");
		}, 0);
	};

	const changeETRInvoice = (e) => {
		setPreFieldValues(TradeFloorStore.uploadFor_list.find(x => e === x.id))
		if (e) {
			let payload = {
				debtor_id: e,
				trade_id: TradeFloorStore.readETRvalue && TradeFloorStore.readETRvalue?.id,
				etr_type: form.getFieldValue('etr_type')
			}
			if (![1, 5].includes(form.getFieldValue('etr_type'))) {
				TradeFloorStore.getRemainingValues(payload).then((data) => {
					setRemainVal(data)
				})
			}
		}
		form.resetFields(['b_etr', 'betr_id', 'deliver_to', 'invoice_date', 'expected_date', 'face_value']);
		if (formDataList.includes('dETR')) {
			form.setFieldsValue({
				betr_id: 0
			})
		}
		handleChange()
	}

	const calcRemainVal = () => {
		let face_value = form.getFieldValue('face_value');
		setRemainVal(Number(TradeFloorStore.remainingValues).toFixed(2) - Number(face_value))
	}

	const bETRselected = async (e) => {
		setPOlist([])
		form.resetFields(['po_id'])
		setFinalbETR(selectbETR.find(x => e === x.bko_invoices_id))
		let listPO = await preFieldValues && preFieldValues.betr && Object.keys(preFieldValues?.betr).map(key => preFieldValues?.betr[key]).filter(x => x.find(item => item.bko_invoices_id === e && item))
		if (listPO && listPO.length) {
			let tempPolist = []
			listPO[0].forEach(item => {
				tempPolist.push(item)
			})
			setPOlist(tempPolist)
		}
		handleChange()
	}

	const changePaymentTerms = (e) => {
		let expected_date = moment(form.getFieldValue('invoice_date'), 'YYYY/MM/DD')
		if (preFieldValues) {
			let gracePeriod = form.getFieldValue('term');
			expected_date = expected_date.add('days', gracePeriod);
		} else {
			expected_date = expected_date.add('days', TradeFloorStore.readETRvalue && TradeFloorStore.readETRvalue.payment_discount_term);
		}

		form.setFieldsValue({
			expected_date: expected_date
		})
	}

	const invoiceData = (e) => {
		let validThrough = moment(e, 'YYYY/MM/DD');
		if (preFieldValues.data?.average_payment_cycle) {
			let gracePeriod = form.getFieldValue('term');
			validThrough = validThrough.add('days', gracePeriod);
		}
		form.setFieldsValue({
			invoice_date: e,
			expected_date: validThrough
		})
	}

	const uploadProps = {
		name: "file",
		multiple: false,
		fileList: supportingFile,
	};

	const handleFileUploadChangeSupportDoc = (info) => {
		const { file } = info;
		let nextState = [];
		let isCSV = file.type === "application/pdf";
		let isLt1M = file.size / 1024 / 1024 < 1;

		switch (info.file.status) {
			case "uploading":
				if (!isCSV) {
					message.error(
						`Attach Supporting Document must be in pdf format. File Size should be less then 1 MB.`
					);
					updateSupportingFile([]);
					return true;
				} else if (!isLt1M) {
					message.error(
						"Attach Supporting Document must be in pdf format. File Size should be less then 1 MB."
					);
					updateSupportingFile([]);
					return true;
				} else {
					nextState = [info.file];
				}
				break;
			case "done":
				if (!isCSV) {
					message.error(
						`Attach Supporting Document must be in pdf format. File Size should be less then 1 MB.`
					);
					updateSupportingFile([]);
					return true;
				} else if (!isLt1M) {
					message.error(
						"Attach Supporting Document must be in pdf format. File Size should be less then 1 MB."
					);
					updateSupportingFile([]);
					return true;
				} else {
					nextState = [info.file];
				}
				break;

			default:
				nextState = [];
		}
		updateSupportingFile(nextState);
		forceUpdate()
	};

	const dummyRequest = ({ file, fileList, onSuccess }) => {
		setTimeout(() => {
			onSuccess("ok");
		}, 0);
	};

	const onDownload = (info) => {
		window.open(info.url, "_blank");
	};

	useEffect(() => {
		var data = getFormDataList()
		if (JSON.stringify(formDataList) !== JSON.stringify(data)) {
			setFormDataList(data)
		}
		let tempPermission = AppsAuthStore.permissions ? AppsAuthStore.permissions[1005].filter(function (n) { return !this.has(n) }, new Set(['edit', 'list', 'show', 'RSA'])) : []
		setTempETRList(tempPermission)
		if (TradeFloorStore.readETRvalue) {
			if (TradeFloorStore.readETRvalue.etr_type === 1) { setLoanTerm('a') }
			else if (TradeFloorStore.readETRvalue.etr_type === 2) { setLoanTerm('b') }
			else if (TradeFloorStore.readETRvalue.etr_type === 3) { setLoanTerm('c') }
			else if (TradeFloorStore.readETRvalue.etr_type === 4) { setLoanTerm('d') }
			else if (TradeFloorStore.readETRvalue.etr_type === 5) { setLoanTerm('f') }
			else { setLoanTerm('') }
		}
	}, [getFormDataList, setFormDataList, formDataList, AppsAuthStore, TradeFloorStore])

	const openCredebtor = () => {
		setCredebtorDrawer(true)
	}

	useEffect(() => {
		if (preFieldValues) {
			let newData = preFieldValues && preFieldValues.betr && Object.keys(preFieldValues?.betr).map(key => preFieldValues?.betr[key]).map(x => x[0])
			setSelectbETR(newData && ([...newData, dETRwithoutBETR]))
		} else if (TradeFloorStore.readETRvalue) {
			let newData = [{ bko_invoices_id: TradeFloorStore?.readETRvalue?.betr?.id, friendly_name: TradeFloorStore.readETRvalue?.betr?.friendly_name }]
			let cETRnewData = [{ id: TradeFloorStore?.readETRvalue?.betr?.id, friendly_name: TradeFloorStore.readETRvalue?.betr?.friendly_name }]
			if (TradeFloorStore?.readETRvalue?.betr?.id === 0 || TradeFloorStore?.readETRvalue?.betr_id === 0) {
				if (TradeFloorStore?.readETRvalue.etr_type === 4) {
					setSelectbETR([dETRwithoutBETR])
				} else if (TradeFloorStore?.readETRvalue.etr_type === 3) {
					setSelectbETR([cETRwithoutBETR])
				}
			} else {
				if (TradeFloorStore?.readETRvalue.etr_type === 4) {
					setSelectbETR(newData && ([...newData, dETRwithoutBETR]))
				} else if (TradeFloorStore?.readETRvalue.etr_type === 3) {
					setSelectbETR(cETRnewData && ([...cETRnewData, cETRwithoutBETR]))
				}
			}
		}

		setISOcodeAlpha2(preFieldValues && preFieldValues.iso_code_alpha2 === 'IE' ? true : false)
		const settings_json = preFieldValues && preFieldValues.settings_json && JSON.parse(preFieldValues.settings_json)
		if (preFieldValues) {
			let selectEmail;
			if (preFieldValues && preFieldValues.contact) {
				selectEmail = preFieldValues.contact.filter(_x => _x.contact_for === 'Primary' && _x)
			}
			form.setFieldsValue({
				deliver_to: selectEmail && selectEmail.length ? selectEmail[0].id : null,
				payment_discount: settings_json && settings_json.payment_discount,
				currency_id: preFieldValues && preFieldValues?.currency_id,
				term: preFieldValues && preFieldValues.data && preFieldValues.data.average_payment_cycle,
				root_invoice_id: preFieldValues && preFieldValues?.root_invoice_id,
				remaining_value: TradeFloorStore.remainingValues && TradeFloorStore.remainingValues.toFixed(2)
			})
		}
		setCurrencyArray(
			preFieldValues ? [{
				currency_id: preFieldValues && preFieldValues.currency_id,
				currency_name: preFieldValues && preFieldValues.currency_name
			}] : [{
				currency_id: TradeFloorStore.readETRvalue && TradeFloorStore.readETRvalue.currency?.id,
				currency_name: TradeFloorStore.readETRvalue?.currency_id && TradeFloorStore.readETRvalue.currency?.currency_name
			}]
		)
		!preFieldValues && setPOlist([TradeFloorStore.readETRvalue?.po])
	}, [form, preFieldValues, finalbETR, TradeFloorStore.readETRvalue, setISOcodeAlpha2, TradeFloorStore.remainingValues])

	const closeCredebtorDrawer = () => {
		TradeFloorStore.uploadFor_list = null
		setInvoiceLoader(true)
		setCredebtorDrawer(false)
	}

	useEffect(() => {
		if (TradeFloorStore.readETRvalue) {
			form.setFieldsValue({
				remaining_value: TradeFloorStore.remainingValues && TradeFloorStore.remainingValues,
			})
			calcRemainVal()
		}
	})

	return (
		<>
			<Form
				form={form}
				layout='vertical'
				id="tradeAddEditForm"
				onFinish={onFinish}
				initialValues={TradeFloorStore.initialValues}
			>
				<>
					<Row gutter={[8, 8]}>
						<Col span={6}>
							<InputComponent
								required
								allowClear
								type="select"
								label="ETR Type"
								name="etr_type"
								placeholder="Select ETR Type"
								onChange={changeETRType}
								options={{
									values: TradeFloorStore.ETRList(tempETRList),
									value_key: "id",
									text_key: "etr_type"

								}}
								tooltip="Choose ETR Type"
								autoComplete="off"
								rules={vsmTradeFloor.validation.etr_type}
								disabled={formType === 'Edit' ? true : false}
							/>
						</Col>

						{formDataList.includes('aETR') || formDataList.includes('fETR') ? (
							<>
								<Col span={6}>
									<InputComponent
										required
										allowClear
										type="select"
										label="Select Creditor"
										name='debtor_id'
										placeholder="Select Creditor"
										onChange={changeETRInvoice}
										onFocus={() => (invoiceLoader && TradeFloorStore.getAFOrg().then(() => setInvoiceLoader(false)))}
										notFoundContent={invoiceLoader ? <Spin size="small" /> : "No Record Found."}
										options={{
											values: TradeFloorStore.uploadFor_list || [{
												id: TradeFloorStore.readETRvalue && TradeFloorStore.readETRvalue.debtor && TradeFloorStore.readETRvalue.debtor.id,
												organisation_name: TradeFloorStore.readETRvalue && TradeFloorStore.readETRvalue.debtor && TradeFloorStore.readETRvalue.debtor.organisation_name
											}],
											value_key: "id",
											text_key: "organisation_name"
										}}
										tooltip="Select the Credebtor name that appears on the invoice you are about to upload"
										autoComplete="off"
										note={<div onClick={openCredebtor} key="1" className='extra_note' style={{ color: '#4FC6E0' }}>Add a new counterparty</div>}
										disabled={params === 'view'}
										rules={vsmTradeFloor.validation.invoice_for}
									/>
								</Col>

								<Col span={4}>
									<InputComponent
										required
										label="Batch No"
										name="batch_no"
										placeholder="Batch No"
										onChange={handleChange}
										tooltip={'A batch number is a designation given to products made in the same manufacturing run.'}
										disabled={params === 'view'}
										maxLength={10}
										rules={vsmTradeFloor.validation.batch_no}
									/>
								</Col>
								<Col span={4}>
									<InputComponent
										required
										label="Months"
										name="etr_months"
										placeholder="Months"
										onChange={handleChange}
										tooltip={formDataList.includes('aETR') ? 'a-ETR invoice month' : 'f-ETR invoice month'}
										disabled={params === 'view'}
										rules={vsmTradeFloor.validation.etr_months}
										maxLength={3}
									/>
								</Col>
								<Col span={4}>
									<InputComponent
										required
										label="Sell Rate"
										name="a_f_sell_rate"
										placeholder="Sell Rate"
										onChange={handleChange}
										tooltip={formDataList.includes('aETR') ? 'a-ETR sell rate' : 'f-ETR sell rate'}
										disabled={params === 'view'}
										rules={vsmTradeFloor.validation.a_f_sell_rate}
									/>
								</Col>

								<Col span={4}>
									<InputComponent
										allowClear
										type="select"
										label="Currency"
										name="currency_id"
										placeholder="Select Currency"
										onChange={handleChange}
										tooltip="The currency is pre-set according to currency selection made when creating the Debtor and cannot be changed"
										options={{
											values: currencyArray || (
												currencyArray && currencyArray.map((item) => ({
													currency_id: item.currency_id && item.currency_id,
													currency_name: item.currency_name && item.currency_name
												}))
											),
											value_key: "currency_id",
											text_key: "currency_name"
										}}
										disabled
									/>
								</Col>

								<Col span={4}>
									<InputComponent
										required
										type="date"
										name="start_date"
										label="Start Date (YYYY/MM/DD)"
										placeholder="Select Date"
										onChange={() => { handleChange(); form.resetFields(['end_date']) }}
										disabledDate={disabledInvoiceDate}
										tooltip={formDataList.includes('aETR') ? 'a-ETR start date' : 'f-ETR start date'}
										className="date_wrapper"
										disabled={params === 'view'}
										rules={vsmTradeFloor.validation.start_date}
									/>
								</Col>
								<Col span={4}>
									<InputComponent
										required
										type="date"
										name="end_date"
										label="End Date (YYYY/MM/DD)"
										placeholder="Select Date"
										onChange={handleChange}
										disabledDate={disabledEndDate}
										tooltip={formDataList.includes('aETR') ? 'a-ETR end date' : 'f-ETR end date'}
										className="date_wrapper"
										disabled={!form.getFieldValue('start_date')}
										rules={vsmTradeFloor.validation.end_date}
									/>
								</Col>
								<Col span={4}>
									<InputComponent
										required
										type="date"
										name="purchase_date"
										label="Purchase Date (YYYY/MM/DD)"
										placeholder="Select Date"
										onChange={handleChange}
										tooltip="Enter the purchase date"
										className="date_wrapper"
										disabled={params === 'view'}
										rules={vsmTradeFloor.validation.purchase_date}
									/>
								</Col>
								<Col span={4}>
									<InputComponent
										required
										label="Asset Name"
										name="asset_name"
										placeholder="Asset Name"
										tooltip={'An asset is a resource with economic value that an individual or corporation owns or controls with the expectation that it will provide a future benefit.'}
										onChange={handleChange}
										disabled={params === 'view'}
										maxLength={100}
										rules={vsmTradeFloor.validation.asset_name}
									/>
								</Col>

								<Col span={4}>
									<InputComponent
										required
										label="Asset Number"
										name="asset_number"
										placeholder="Asset Number"
										tooltip={'An asset number uniquely identifies the product you or your organization purchased.'}
										onChange={handleChange}
										disabled={params === 'view'}
										maxLength={50}
										rules={vsmTradeFloor.validation.asset_number}
									/>
								</Col>

								<Col span={4}>
									<InputComponent
										required
										label="Value"
										name="value"
										placeholder="Value"
										tooltip={'Value is the monetary, material, or assessed worth of an asset, good, or service.'}
										onChange={handleChange}
										maxLength={10}
										disabled={params === 'view'}
										rules={vsmTradeFloor.validation.a_f_value}
									/>
								</Col>

								<Col span={4}>
									<InputComponent
										required
										label="Quantity Number"
										name="quantity_numbers"
										placeholder="Quantity Number"
										tooltip={'The measurable or countable property or aspect of things.'}
										onChange={handleChange}
										disabled={params === 'view'}
										maxLength={10}
										rules={vsmTradeFloor.validation.quantity_numbers}
									/>
								</Col>

								<Col span={8}>
									<InputComponent
										required
										label="Asset Classfication"
										name="asset_classification"
										placeholder="Asset Classfication"
										tooltip={'Asset classification is a system for assigning assets into groups, based on a number of common characteristics.'}
										onChange={handleChange}
										disabled={params === 'view'}
										maxLength={50}
										rules={vsmTradeFloor.validation.asset_classification}
									/>
								</Col>

								<Col span={8}>
									<InputComponent
										required
										label="Supplier/Manufacturer"
										name="supplier_manufacturer"
										placeholder="Supplier/Manufacturer"
										tooltip={'A supplier is a person, company, or organization that sells or supplies something such as goods or equipment to customers.'}
										onChange={handleChange}
										disabled={params === 'view'}
										rules={vsmTradeFloor.validation.supplier_manufacturer}
									/>
								</Col>

								<Col span={10}>
									<InputComponent
										type="textarea"
										label="Description"
										name="desc"
										placeholder="Description"
										tooltip={formDataList.includes('aETR') ? 'Add a-ETR description' : 'Add f-ETR description'}
										onChange={handleChange}
										disabled={params === 'view'}
									/>
								</Col>

								<Col span={12}>
									<Form.Item
										required
										label="Invoice File/Document"
										className="full-width-dragger"
										name="file_support"
										tooltip="Attach a pdf file supporting this invoice."
										rules={vsmTradeFloor.validation.InvoiceFileDocument}
									>
										<Upload.Dragger
											{...uploadProps}
											onChange={(e) => { handleFileUploadChangeSupportDoc(e); handleChange() }}
											customRequest={dummyRequest}
											showUploadList={{ showDownloadIcon: true, showRemoveIcon: false }}
											onDownload={onDownload}
											fileList={supportingFile.length ? supportingFile : attachInvoice}
											disabled={params === 'view'}
										>
											<p className="ant-upload-drag-icon">
												<InboxOutlined />
											</p>
											<p className="ant-upload-text">
												Drag your PDF file here or select by clicking here
											</p>
										</Upload.Dragger>
									</Form.Item>
								</Col>
							</>
						) : null}

						{formDataList.includes('bETR') ? (
							<>
								<Col span={4}>
									<InputComponent
										required
										type="date"
										name="expected_date"
										label="Expected Date (YYYY/MM/DD)"
										placeholder="Select Date"
										onChange={handleChange}
										tooltip="Mentioned expected date"
										rules={vsmTradeFloor.validation.expected_date_po}
										className="date_wrapper"
										disabled={params === 'view'}
									/>
								</Col>
								<Col span={4}>
									<InputComponent
										required
										label="Friendly Name"
										name="friendly_name"
										placeholder="Enter Friendly Name"
										onChange={handleChange}
										tooltip="Add friendly name for b-ETR"
										rules={vsmTradeFloor.validation.friendy_name}
										maxLength={100}
										disabled={params === 'view'}
									/>
								</Col>
								<Col span={10}>
									<InputComponent
										type="textarea"
										name="description"
										label="Description"
										placeholder="Add Description"
										onChange={handleChange}
										maxLength={1000}
										tooltip={`Add b-ETR description`}
										disabled={params === 'view'}
									/>
								</Col>
								<Col span={12}>
									<Form.Item label="Attach Supporting Document" tooltip="Attach a PDF for the supporting document">
										<Form.Item
											className="full-width-dragger"
											name="file_support"
										>
											<Upload.Dragger
												{...uploadProps}
												onChange={(e) => { handleFileUploadChangeSupportDoc(e); handleChange() }}
												customRequest={dummyRequest}
												showUploadList={{ showDownloadIcon: true }}
												onDownload={onDownload}
												fileList={formType === 'Add' ? supportingFile : attachInvoice}
												disabled={params === 'view'}
											>
												<p className="ant-upload-drag-icon">
													<InboxOutlined />
												</p>
												<p className="ant-upload-text">
													Drag your PDF file here or select by clicking here
												</p>
											</Upload.Dragger>
										</Form.Item>
									</Form.Item>
								</Col>
								<Col span={24}>
									<Card bordered={false} title="Your Trade Terms">
										<Row gutter={[8, 8]}>
											<Col span={8}>
												<InputComponent
													required
													disabled
													initialValue={AppsAuthStore.d_etr_purchase_discount}
													label="d-ETR Monthly Discount Percentage"
													name="d_etr_purchase_discount"
													placeholder="d-ETR Monthly Discount Percentage"
													onChange={handleChange}
													tooltip="The monthly discount percentage is pre-set according to RSA agreement with Credebt Exchange"
													rules={vsmTradeFloor.validation.discount_percentage}
													addonAfter={<PercentageOutlined />}
												/>
											</Col>

											<Col span={8}>
												<InputComponent
													required
													disabled
													initialValue={AppsAuthStore.c_etr_sell_rate}
													label="c-ETR Sell Rate Percentage"
													name="c_etr_sell_rate"
													placeholder="c-ETR Sell Rate Percentage"
													onChange={handleChange}
													tooltip="Your current c-ETR sell rate agreed with Credebt Exchange"
													rules={vsmTradeFloor.validation.sell_rate}
													addonAfter={<PercentageOutlined />}
												/>
											</Col>
										</Row>
									</Card>

									<Divider className='devider_wrapper' />
									<Col span={24} className='mb-10'>
										<Collapse
											defaultActiveKey={activeKey ? ['1'] : null}
											className="site-collapse-custom-collapse"
											expandIconPosition="right"
											expandIcon={({ isActive }) =>
												isActive ? <MinusOutlined /> : <PlusOutlined />
											}
											bordered={false}
										>
											<Panel header="PO Details" key='1'>
												<PODetailsElement
													form={form}
													onChange={handleChange}
													fetchPOFor={fetchPOFor}
													POFor={POFor}
													fetchbETRCurrency={fetchbETRCurrency}
													bETRCurrency={bETRCurrency}
													updatePoFile={updatePoFile}
													poFile={poFile}
													params={params}
												/>
											</Panel>
										</Collapse>
									</Col>
								</Col>
							</>
						) : null}
						{formDataList.includes('cETR') || formDataList.includes('dETR') ? (
							<>
								{formDataList.includes('dETR') ?
									<Col span={6}>
										<InputComponent
											required
											allowClear
											type="select"
											label="Upload Revolving ETR Invoice for"
											name='debtor_id'
											placeholder="Select Debtor"
											onChange={(e) => changeETRInvoice(e)}
											onFocus={() => (invoiceLoader && TradeFloorStore.getUploadFor('Debtor').then(() => setInvoiceLoader(false)))}
											notFoundContent={invoiceLoader ? <Spin size="small" /> : "No Record Found."}
											options={{
												values: TradeFloorStore.uploadFor_list || [{
													id: TradeFloorStore.readETRvalue && TradeFloorStore.readETRvalue.debtor && TradeFloorStore.readETRvalue.debtor.id,
													organisation_name: TradeFloorStore.readETRvalue && TradeFloorStore.readETRvalue.debtor && TradeFloorStore.readETRvalue.debtor.organisation_name
												}],
												value_key: "id",
												text_key: "organisation_name"
											}}
											tooltip="Select the Debtor name that appears on the invoice you are about to upload"
											autoComplete="off"
											rules={vsmTradeFloor.validation.invoice_for}
											note={<div onClick={openCredebtor} key="1" className='extra_note' style={{ color: '#4FC6E0' }}>Add a new counterparty</div>}
											disabled={params === 'view'}
										/>
									</Col>
									:
									<Col span={6}>
										<InputComponent
											required
											allowClear
											type="select"
											label="Upload Revolving ETR Invoice for"
											name='debtor_id'
											placeholder="Select Creditor"
											onChange={(e) => changeETRInvoice(e)}
											onFocus={() => (invoiceLoader && TradeFloorStore.getUploadFor('Creditor').then(() => setInvoiceLoader(false)))}
											notFoundContent={invoiceLoader ? <Spin size="small" /> : "No Record Found."}
											options={{
												values: TradeFloorStore.uploadFor_list || [{
													id: TradeFloorStore.readETRvalue && TradeFloorStore.readETRvalue.debtor && TradeFloorStore.readETRvalue.debtor.id,
													organisation_name: TradeFloorStore.readETRvalue && TradeFloorStore.readETRvalue.debtor && TradeFloorStore.readETRvalue.debtor.organisation_name
												}],
												value_key: "id",
												text_key: "organisation_name"
											}}
											tooltip="Select the Debtor name that appears on the invoice you are about to upload"
											autoComplete="off"
											rules={vsmTradeFloor.validation.invoice_for}
											note={<div onClick={openCredebtor} key="1" className='extra_note' style={{ color: '#4FC6E0' }}>Add a new counterparty</div>}
											disabled={params === 'view'}
										/>
									</Col>
								}
								<Col span={6}>
									{formDataList.includes('dETR') ? (
										<InputComponent
											required
											allowClear
											type="select"
											label="Select b-ETR"
											name="betr_id"
											placeholder="Select b-ETR"
											onChange={e => bETRselected(e)}
											options={{
												values: selectbETR || (
													selectbETR && selectbETR.map((item) => ({
														bko_invoices_id: item.bko_invoices_id && item.bko_invoices_id,
														friendly_name: item.friendly_name && item.friendly_name
													}))
												),
												value_key: "bko_invoices_id",
												text_key: "friendly_name"
											}}
											tooltip="Select the Debtor name that appears on the invoice you are about to upload."
											autoComplete="off"
											rules={vsmTradeFloor.validation.b_etr}
											disabled={params === 'view'}
										/>
									) : (
										<InputComponent
											required
											allowClear
											type="select"
											label="Select b-ETR"
											name="betr_id"
											placeholder="Select b-ETR"
											onFocus={() => (betrLoader && TradeFloorStore.getBETR().then(() => setbetrLoader(false)))}
											notFoundContent={betrLoader ? <Spin size="small" /> : "No Record Found."}
											onChange={handleChange}
											options={{
												values: TradeFloorStore.btrList || (
													selectbETR && selectbETR.map((item) => ({
														id: item.id && item.id,
														friendly_name: item.friendly_name && item.friendly_name
													}))
												),
												value_key: "id",
												text_key: "friendly_name"
											}}
											tooltip="Select the Debtor name that appears on the invoice you are about to upload."
											autoComplete="off"
											rules={vsmTradeFloor.validation.b_etr}
											disabled={params === 'view'}
										/>
									)}
								</Col>
								{formDataList.includes('dETR') &&
									<Col span={6}>
										<InputComponent
											allowClear
											type="select"
											label="Deliver NoA to"
											name="deliver_to"
											placeholder="Choose Email"
											onChange={handleChange}
											options={{
												values: ((preFieldValues && preFieldValues.contact) || (TradeFloorStore.readETRvalue && TradeFloorStore.readETRvalue.contact)) || [{
													id: TradeFloorStore.readETRvalue && TradeFloorStore.readETRvalue.deliver_to && TradeFloorStore.readETRvalue.deliver_to.id && TradeFloorStore.readETRvalue.deliver_to.id,
													email: TradeFloorStore.readETRvalue && TradeFloorStore.readETRvalue.deliver_to && TradeFloorStore.readETRvalue.deliver_to.email && TradeFloorStore.readETRvalue.deliver_to.email
												}],
												value_key: "id",
												text_key: "email"
											}}
											tooltip="Debtor email address"
											autoComplete="off"
											disabled={params === 'view'}
										/>
									</Col>
								}
								{(formDataList.includes('cETR') && ((preFieldValues && preFieldValues.iso_code_alpha2 === 'IE') || (TradeFloorStore.readETRvalue && (TradeFloorStore.readETRvalue.vat_included === 1 || TradeFloorStore.readETRvalue.vat_included === 0)))) &&
									<Col span={5}>
										<InputComponent
											className='mt-30'
											checked={vatIncluded}
											type='checkbox'
											name="vat_included"
											label="VAT Included"
											onChange={e => { setVatIncluded(e.target.checked); handleChange() }}
										/>
									</Col>
								}
							</>
						)
							: null}
					</Row>

					{formDataList.includes('cETR') || formDataList.includes('dETR') ? (
						<Row gutter={[8, 8]}>
							{formDataList.includes('dETR') ? (
								<Col span={4}>
									<InputComponent
										required
										name='term'
										label='Payment Terms'
										placeholder='Payment Terms'
										onChange={(e) => { handleChange(); changePaymentTerms(e) }}
										maxLength={3}
										rules={vsmTradeFloor.validation.payment_terms}
										tooltip='Enter a fixed value to indicate your standard (30 , 60 ,90 days, etc)'
										disabled={params === 'view'}
									/>
								</Col>
							) : (
								<Col span={4}>
									<InputComponent
										required
										label="Payment Discount"
										name="payment_discount"
										placeholder="Payment Discount"
										onChange={handleChange}
										tooltip="Enter a value for payment discount."
										addonAfter={<PercentageOutlined />}
										disabled
									/>
								</Col>
							)
							}
							<Col span={4}>
								<InputComponent
									allowClear
									type="select"
									label="Currency"
									name="currency_id"
									placeholder="Select Currency"
									onChange={handleChange}
									tooltip="The currency is pre-set according to currency selection made when creating the Debtor and cannot be changed"
									options={{
										values: currencyArray || (
											currencyArray && currencyArray.map((item) => ({
												currency_id: item.currency_id && item.currency_id,
												currency_name: item.currency_name && item.currency_name
											}))
										),
										value_key: "currency_id",
										text_key: "currency_name"
									}}
									disabled
								/>
							</Col>
							<Col span={4}>
								<InputComponent
									required
									label="Face Value"
									name="face_value"
									placeholder="Face Value"
									onChange={(e) => { calcRemainVal(e); handleChange() }}
									tooltip="Enter the total invoice value including all delivery charges, support charges, tax, duties, etc. (i.e the Total value the Debtor must pay to extinguish their liability)"
									rules={vsmTradeFloor.validation.face_value}
									note={(TradeFloorStore.remainingValues || TradeFloorStore.remainingValues === 0) ? 'Remaining Value: ' + ((remainVal || remainVal === 0) ? remainVal.toFixed(2) : Number(TradeFloorStore.remainingValues).toFixed(2)) : null}
									disabled={params === 'view'}
									maxLength={10}
								/>
								<InputComponent
									type="text"
									hidden={true}
									name="remaining_value"
								/>
							</Col>


							<Col span={6}>
								<InputComponent
									required
									type='date'
									name='invoice_date'
									label='Invoice Date (YYYY/MM/DD)'
									placeholder='Select date'
									onChange={(e) => { handleChange(); formDataList.includes('dETR') && invoiceData(e) }}
									tooltip="Enter the issue date (i.e. not necessarily today’s date) as it appears on the invoice you are about to sell"
									rules={vsmTradeFloor.validation.invoice_date}
									className='date_wrapper'
									disabledDate={disabledInvoiceDate}
									disabled={params === 'view'}
								/>
							</Col>

							<Col span={6}>
								<InputComponent
									type='date'
									name='expected_date'
									label='Expected Date (YYYY/MM/DD)'
									placeholder='Select date'
									onChange={handleChange}
									tooltip={formDataList.includes('dETR') ? "The Date you select here is CRITICAL. Enter the realistic date that you expect the invoice will definetly paid in full" : formDataList.includes('cETR') ? 'Expected date is the date when you will settle this invoice with Credebt Exchange. This is also the date when the corresponding d-ETR will be raised/traded by you.' : null}
									className='date_wrapper'
									disabledDate={disabledExpectedDate}
									disabled={formDataList.includes('dETR') || params === 'view' ? true : false}
									rules={vsmTradeFloor.validation.expected_date}
								/>
							</Col>
						</Row>

					) : null}

					{formDataList.includes('cETR') || formDataList.includes('dETR') ? (
						<Row gutter={[8, 8]}>
							{formDataList.includes('dETR') && form.getFieldValue('betr_id') !== 0 ?
								<Col span={6}>
									<InputComponent
										required
										allowClear
										type="select"
										label="Select PO Number"
										name="po_id"
										placeholder="Select PO Number"
										onChange={handleChange}
										options={{
											values: POlist || (
												POlist && POlist.map((item) => ({
													po_id: item && item.po_id,
													po_no: item && item.po_no,
													friendly_name: item && item.friendly_name
												}))
											),
											value_key: "po_id",
											text_key: { key: ["po_no", " - ", "friendly_name"] }

										}}
										tooltip="Select If this invoice is supported by an official order or Purchase order number."
										autoComplete="off"
										rules={vsmTradeFloor.validation.pod_select}
										disabled={params === 'view'}
									/>
								</Col>
								:
								<Col span={6}>
									<InputComponent
										required
										label="PoD"
										name="po_no"
										placeholder="PoD"
										onChange={handleChange}
										tooltip={formDataList.includes('dETR') ? "Enter If this invoice is supported by an official order or Purchase order number." : formDataList.includes('cETR') ? 'If this invoice is supported by an official order, or Purchase Order (PO), enter it here.' : null}
										rules={vsmTradeFloor.validation.pod}
										disabled={params === 'view'}
										maxLength={100}
									/>
								</Col>
							}
							{form.getFieldValue('betr_id') === 0 &&
								<Col span={6}>
									<Form.Item
										required
										label="Attach PoD"
										className="full-width-dragger"
										name="po_attach"
										tooltip="Attach a pdf of the purchase order supporting this invoice."
										rules={vsmTradeFloor.validation.po_attach}
									>
										<Upload.Dragger
											{...uploadPropsPoD}
											name="po_attach"
											onChange={handleFileUploadChangePoD}
											customRequest={dummyRequestPoD}
											onDownload={onDownloadPoD}
											fileList={attachPoD}
											disabled={params === 'view'}
										>
											<p className="ant-upload-drag-icon">
												<InboxOutlined />
											</p>
											<p className="ant-upload-text">
												Drag your PDF file here or select by clicking here
											</p>
										</Upload.Dragger>
									</Form.Item>
								</Col>
							}

							<Col span={6}>
								<InputComponent
									required
									label="Invoice No."
									name="invoice_no"
									placeholder="Invoice No."
									onChange={handleChange}
									tooltip="Enter the invoice number as it appears on the invoice you are going to upload"
									rules={vsmTradeFloor.validation.invoice_no}
									disabled={params === 'view'}
									maxLength={20}
								/>
							</Col>

							<Col span={6}>
								<Form.Item
									required
									label="Attach Invoice"
									className="full-width-dragger"
									name="file_support"
									tooltip="Attach the pdf of the actual invoice and ensure the details of the invoice actual match the details entered in this form"
									rules={vsmTradeFloor.validation.attach_invoice}
								>
									<Upload.Dragger
										{...uploadPropsAttachInvoice}
										onChange={handleFileUploadChangeAttachInvoice}
										customRequest={dummyRequestAttachInvoice}
										name="file_support"
										onDownload={onDownloadAttachInvoice}
										disabled={params === 'view'}
									>
										<p className="ant-upload-drag-icon">
											<InboxOutlined />
										</p>
										<p className="ant-upload-text">
											Drag your PDF file here or select by clicking here
										</p>
									</Upload.Dragger>
								</Form.Item>
							</Col>
						</Row>
					) : null}

					{form.getFieldValue('etr_type') ?
						<Row gutter={[8, 8]}>
							<Col span={24}>
								<label required style={{ fontSize: '12px' }}>{loanTerm}-ETR Loan Terms </label>
								<Tooltip placement="top" title={`Accept ${loanTerm}-ETR loan terms`}> <QuestionCircleOutlined /></Tooltip>
								{TradeFloorStore.ETR_loan_term && TradeFloorStore.ETR_loan_term.data && TradeFloorStore.ETR_loan_term.data.length && TradeFloorStore.ETR_loan_term.data[0].page_description ? <div style={scrollDiv} dangerouslySetInnerHTML={{ __html: TradeFloorStore.ETR_loan_term.data[0].page_description }} /> : null}
							</Col>
						</Row>
						: null}
					{formDataList.includes('dETR') &&
						<Col span={6}>
							<InputComponent
								hidden={true}
								name="root_invoice_id"
							/>
						</Col>
					}
				</>
			</Form>
			<AddComponent visible={credebtorDrawer} close={closeCredebtorDrawer} />
		</>
	);
})

export default FormComponent;