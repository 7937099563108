import React, { useEffect, useState } from 'react';
import { Button, Col, message, Row, Table, Upload } from 'antd';
import debounce from 'lodash/debounce'
import { observer } from 'mobx-react';
import EditComponent from './components/EditComponent'
import useStore from '../../../store';
import { PlusOutlined } from '@ant-design/icons';
import { vsmNotify, vsmProfile } from '../../../config/Originator/AppsMessage';
import ChangePassword from '../ChangePass';
import { useHistory, useLocation } from 'react-router-dom';
import DummyProfile from '../../../assets/images/dummy_profile.png'
import { UploadOutlined } from '@ant-design/icons';

const Profile = observer(() => {
    const { Column } = Table;
    let history = useHistory()
    const { AppsAuthStore, UserProfileStore: { getUserProfileDetails }, UserProfileStore } = useStore()
    const [editProfileDrawer, setEditProfileDrawer] = useState(false)
    const [fileList, updateFileList] = useState([]);
    const [openDrawer, setOpenDrawer] = useState(useLocation().search === '?change-password-popup=1')

    history.listen((location, action) => {
        if (location.search === '?change-password-popup=1') {
            closeEditProfileDrawer()
            setOpenDrawer(true)
        }
    })

    // open Edit Profile Drawer
    const openEditProfileDrawer = () => {
        setEditProfileDrawer(true)
    }

    // close Edit Profile Drawer
    const closeEditProfileDrawer = () => setEditProfileDrawer(false)

    const openChangePasswordModal = () => {
        setOpenDrawer(true)
    }

    const closeChangePasswordModal = () => {
        setOpenDrawer(false)
        history.replace(history.location.pathname)
    }

    useEffect(() => {
        getUserProfileDetails(AppsAuthStore.originator_user.id).then(() => {
            if (UserProfileStore.profileDetails && UserProfileStore.profileDetails.orgnisation) {
                updateFileList(UserProfileStore.profileDetails.profile_url ? ([
                    {
                        uid: '-1',
                        name: 'image.png',
                        status: 'done',
                        url: UserProfileStore.profileDetails.profile_url,
                    },
                ]) : [])
            }
        })
    }, [AppsAuthStore.originator_user.id, getUserProfileDetails, UserProfileStore])

    const details_info = [
        {
            key: '1',
            label: <div>First Name</div>,
            value: <p><span>:</span>{UserProfileStore.profileDetails && UserProfileStore.profileDetails.first_name}</p>,
        },
        {
            key: '2',
            label: <div>Last Name</div>,
            value: <p><span>:</span> {UserProfileStore.profileDetails && UserProfileStore.profileDetails.last_name}</p>,
        },
        {
            key: '3',
            label: <div>Organisation Name</div>,
            value: <p><span>:</span>{UserProfileStore.profileDetails && UserProfileStore.profileDetails.orgnisation && UserProfileStore.profileDetails.orgnisation.organisation_name}</p>,
        },
        {
            key: '4',
            label: <div>Registration Country</div>,
            value: <p><span>:</span>{UserProfileStore.profileDetails && UserProfileStore.profileDetails.orgnisation && UserProfileStore.profileDetails.orgnisation.global_countries && UserProfileStore.profileDetails.orgnisation.global_countries.country_name}</p>,
        },
        {
            key: '5',
            label: <div>Registration Number</div>,
            value: <p><span>:</span>{UserProfileStore.profileDetails && UserProfileStore.profileDetails.orgnisation && UserProfileStore.profileDetails.orgnisation.registration_number}</p>,
        },
        {
            key: '6',
            label: <div>VAT Number</div>,
            value: <p><span>:</span>{UserProfileStore.profileDetails && UserProfileStore.profileDetails.orgnisation && UserProfileStore.profileDetails.orgnisation.vat_number}</p>,
        },
        {
            key: '7',
            label: <div>Website</div>,
            value: <p><span>:</span>{UserProfileStore.profileDetails && UserProfileStore.profileDetails.orgnisation && UserProfileStore.profileDetails.orgnisation.website}</p>,
        },
        {
            key: '8',
            label: <div>Phone</div>,
            value: <p><span>:</span>{UserProfileStore.profileDetails && UserProfileStore.profileDetails.orgnisation && UserProfileStore.profileDetails.orgnisation.multiple_phone && UserProfileStore.profileDetails.orgnisation.multiple_phone.map((x, index) => x.contact_type === 'phone' ? x.contact_value : '')}</p>,
        },
        {
            key: '9',
            label: <div>Email</div>,
            value: <p><span>:</span>{UserProfileStore.profileDetails && UserProfileStore.profileDetails.orgnisation && UserProfileStore.profileDetails.orgnisation.mutiple_email && UserProfileStore.profileDetails.orgnisation.mutiple_email.map((x, index) => x.contact_type === 'email' ? x.contact_value : '')}</p>
        },
        {
            key: '10',
            label: <div>NACE Category</div>,
            value: <p><span>:</span>{UserProfileStore.profileDetails && UserProfileStore.profileDetails.orgnisation && UserProfileStore.profileDetails.orgnisation.global_nace_sections && UserProfileStore.profileDetails.orgnisation.global_nace_sections.get_nace_category.category_name}</p>,
        },
        {
            key: '11',
            label: <div>NACE Code</div>,
            value: <p><span>:</span>{UserProfileStore.profileDetails && UserProfileStore.profileDetails.orgnisation && UserProfileStore.profileDetails.orgnisation.global_nace_sections && UserProfileStore.profileDetails.orgnisation.global_nace_sections.code + ' - ' + UserProfileStore.profileDetails.orgnisation.global_nace_sections.section}</p>,
        },
        {
            key: '12',
            label: <div>Address</div>,
            value: <p><span>:</span>{UserProfileStore.profileDetails && UserProfileStore.profileDetails.orgnisation && UserProfileStore.profileDetails.orgnisation.mutiple_addresses && UserProfileStore.profileDetails.orgnisation.mutiple_addresses.map((x, index) =>
                <ul>
                    <li>{
                        // 'Address Type: ' + x.address_1 + ', Address: ' +
                        x.address_type + ': ' + x.address_1 + ', Address: ' +
                        x.address_1 +
                        (x.address_2 ? ', ' + x.address_2 : '') +
                        (x.address_3 ? ', ' + x.address_3 : '') +
                        (x.city ? ', ' + x.city : '') +
                        (x.state_county ? ', ' + x.state_county : '') +
                        (x.postal_code ? ', ' + x.postal_code : '') + ', ' +
                        x.country_name
                    }</li>
                </ul>)}</p>,
        },
    ];

    const handleChange = (info) => {
        if (info.file.status === 'uploading') {
            return;
        } else {
            var formData = new FormData();
            if (info.fileList.length > 0 && info.fileList[0]) {
                formData.append('profile_url', info.fileList[0].hasOwnProperty("originFileObj") ? info.fileList[0].originFileObj : info.fileList[0])
            } else if (info.fileList.length === 0) {
                formData.append('is_delete', 1)
            }
            UserProfileStore.changeProfile(formData).then(() => {
                vsmNotify.success({
                    message: vsmProfile.edit
                })
                AppsAuthStore.initiatAppOptions()
            }).catch(e => {
                var error = e.errors[0].errors[0]
                vsmNotify.error({
                    message: error
                })
            })
        }
    }

    const eventProps = {
        onChange: debounce(({ fileList: newFileList }) => {
            updateFileList(newFileList);
            handleChange();
        }, 500),
        fileList,
        beforeUpload: (file) => {
            let isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png';
            let isLt1M = file.size / 1024 / 1024 < 1;
            if (!isJpgOrPng) {
                message.error('Upload valid image. Only JPG, JPEG or PNG are allowed.');
                return true;
            } else if (!isLt1M) {
                message.error('File size should not be more than 500px x 500px and 1 MB');
                return true;
            }
            else {
                if (file.type.includes("image")) {
                    [file][0]["url"] = URL.createObjectURL(file);
                }
                updateFileList([file]);
                return false;
            }
        },
    };

    // Handle on remove image
    const onRemoveImage = () => {
        updateFileList([]);
    }

    return (
        <>
            <Row gutter={[8, 8]}>
                <Col span={8}>
                    <div className='image_card_wrapper'>
                        <div className='img_wrapper'>
                            <Upload
                                fileList={fileList}
                                accept=".png, .jpg, .jpeg"
                                className="hideeyeicon"
                                listType="picture-card"
                                multiple={false}
                                maxCount={1}
                                showUploadList={true}
                                {...eventProps}
                                onChange={handleChange}
                                onRemove={onRemoveImage}
                                icon={<UploadOutlined />}
                            >
                                {fileList.length >= 1 ? null :
                                    <div>
                                        <PlusOutlined />
                                        <img src={UserProfileStore.profileDetails?.profile_url ? UserProfileStore.profileDetails.profile_url : DummyProfile} alt='Profile Avtar' />
                                    </div>}
                            </Upload>
                        </div>
                        <p className='title_tag'>{UserProfileStore.profileDetails && UserProfileStore.profileDetails.first_name} {UserProfileStore.profileDetails && UserProfileStore.profileDetails.last_name}</p>
                        <p className='desc_tag'>{UserProfileStore.profileDetails && UserProfileStore.profileDetails.email}</p>
                        <div className='button_tag'>
                            {AppsAuthStore.permissions && (AppsAuthStore.checkPermission(1021, 'change')) && <Button style={{ backgroundColor: '#4FC6E0', color: 'white' }} onClick={openChangePasswordModal}>Change Password</Button>}
                            {AppsAuthStore.permissions && (AppsAuthStore.checkPermission(1020, 'edit')) && <Button style={{ marginLeft: '15px' }} onClick={openEditProfileDrawer}>Edit Profile</Button>}
                        </div>
                    </div>
                </Col>
                <Col span={16}>
                    <Table dataSource={details_info} pagination={false} title={false} className="no_header profile_details" bordered>
                        <Column dataIndex="label" key="label" />
                        <Column dataIndex="value" key="value" />
                    </Table>
                </Col>
            </Row>
            {editProfileDrawer && <EditComponent visible={editProfileDrawer} close={closeEditProfileDrawer} />}
            {<ChangePassword visible={openDrawer} close={closeChangePasswordModal} fromProfile={true} />}
        </>
    );
})

export default Profile;