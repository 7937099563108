import React from 'react'
import { AgGridReact } from '@ag-grid-community/react'
import { AllModules } from "@ag-grid-enterprise/all-modules"
import { observer } from 'mobx-react'
import LocalGridConfig from '../../../../../config/LocalGridConfig'
import { vsmCommon } from '../../../../../config/Originator/AppsMessage'
import useStore from '../../../../../store'
import { Button, Switch } from 'antd'
import { EditOutlined } from '@ant-design/icons'

const ListComponent = observer((props) => {
    const { openEditBankDrawer, openSwitchModel } = props
    const { BanksStore, AppsAuthStore, BanksStore: { bank_list } } = useStore()

    const ActionRenderer = (props) => {
        return (
            <div className="action-column">
                {AppsAuthStore.permissions && AppsAuthStore.checkPermission(1024, 'edit') &&
                    <Button title="Edit Trade" type="text" onClick={() => { openEditBankDrawer(props.data.id) }}><EditOutlined /></Button>
                }
            </div>
        )
    }


    const gridOptions = {
        columnDefs: [
            {
                headerName: '# ID',
                field: 'id',
                filter: 'agNumberColumnFilter',
                pinned: 'left',
                lockPosition: 'left',
                width: 80
            }, {
                headerName: 'Bank Name',
                field: 'banks.name',
                tooltipField: 'banks.name',
                width: 185,
            }, {
                headerName: 'Account Type',
                field: 'bank_accounts_type.name',
                tooltipField: 'bank_accounts_type.name',
                width: 130,
                filter: 'agSetColumnFilter',
                filterParams: {
                    defaultToNothingSelected: true,
                },
            }, {
                headerName: 'Currency',
                field: 'currency.currency_code',
                tooltipField: 'currency.currency_code',
                width: 100,
                filter: 'agSetColumnFilter',
                filterParams: {
                    defaultToNothingSelected: true,
                },
            }, {
                headerName: 'Account No. / IBAN',
                field: 'account_number',
                valueGetter: (params) => (params.data && params.data.account_number) ? params.data.account_number : params.data.iban,
                width: 200,
            }, {
                headerName: 'Sort Code',
                field: 'sort_code',
                tooltipField: 'sort_code',
                width: 120,
            }, {
                headerName: 'Status',
                field: 'published',
                width: 120,
                cellRendererFramework: function (data) {
                    return (
                        <span><Switch checked={data.data.status} onChange={() => openSwitchModel(data.data)} /></span>
                    )
                },
                sortable: false,
                filter: 'agSetColumnFilter',
                filterParams: {
                    values: ["Active", "Deactive"],
                    defaultToNothingSelected: true,
                },
            }, {
                headerName: 'Actions',
                field: 'actions',
                type: 'actionColumn',
                minWidth: 100,
                filter: false,
                sortable: false,
                pinned: 'right',
                lockPosition: 'right',
                width: 100
            }]
    }

    let columns = []
    let columnConfig = localStorage.getItem('setting_bank_list')
    if (columnConfig) {
        let data = JSON.parse(columnConfig)
        let cols = gridOptions.columnDefs.find(x => !x.field)
        if (cols) {
            columns.push(cols)
        }
        data.forEach(element => {
            cols = gridOptions.columnDefs.find(x => x.field === element.colId)
            if (cols) {
                columns.push(cols)
            }
        });
    } else {
        columns = gridOptions.columnDefs
    }

    return (
        <div className="ag-theme-alpine grid_wrapper">
            <AgGridReact
                rowData={bank_list}
                rowHeight={34}
                modules={AllModules}
                columnDefs={columns}
                defaultColDef={LocalGridConfig.defaultColDef}
                columnTypes={LocalGridConfig.columnTypes}
                overlayNoRowsTemplate={vsmCommon.noRecord}
                frameworkComponents={{
                    ActionRenderer, openEditBankDrawer, openSwitchModel
                }}
                onGridReady={BanksStore.setupGrid}
                onColumnResized={BanksStore.onGridChanged}
                onColumnMoved={BanksStore.onGridChanged}
                onColumnPinned={BanksStore.onGridChanged}
                gridOptions={LocalGridConfig.options}
                suppressDragLeaveHidesColumns={true}
            />
        </div>
    )
})

export default ListComponent;