import React, { useEffect, useState } from 'react'
import { Button, Col, PageHeader, Row, Spin, Typography } from 'antd'
import { observer } from 'mobx-react'
import DashboardWidget from './components/DashboardWidget'
import AddComponent from '../Credebtors/components/AddComponent'
import { useHistory } from 'react-router-dom'
import useStore from '../../../store'
import etr from '../../../assets/Dashboard/etr.svg'
import creditorImage from '../../../assets/Dashboard/creditor.svg'
import important_note from '../../../assets/Dashboard/important_note.png'

const OriginatorDashboard = observer(() => {
	const history = useHistory()
	const { DashboardStore, AppsAuthStore } = useStore()
	const [credebtor, setCredebtor] = useState(false)
	const { Title, Text } = Typography;

	// open drawer for Credebtor
	const createCredebtor = (type) => {
		DashboardStore.setCredebtorType(type)
		setCredebtor(true)
	}

	// close drawer for Credebtor
	const closeCredebtor = () => setCredebtor(false)

	//go to Trade Floor
	const openTradeFloor = () => {
		history.push('/apps/trading/trade-list?open-trade-popup=1')
	}

	useEffect(() => {
		if (AppsAuthStore.originator_user && AppsAuthStore.originator_token) {
			DashboardStore.getDashboardData()
		}
	}, [DashboardStore, AppsAuthStore])

	return (
		<PageHeader
			title='Dashboard'
			className='dashboard'
		>
			<div className='header_widget mb-15'>
				<div className='important_note'>
					<Title level={5} type="danger">
						<img src={important_note} alt='Important Note' /> IMPORTANT NOTE:-
					</Title>
				</div>
				<Text className="d-flex mb-15">All Counterparties must be approved prior to posting any ETR to the Trade Floor. Allow 72 hours for a Counterparty approval. Sell rates or early payment discount for some Counterparties may differ from shown below</Text>
				<Row gutter={[15, 15]}>
					<Col md={6}>
						<DashboardWidget top={true} footerText="is your organisation's current sell date" />
					</Col>
					<Col md={6}>
						<DashboardWidget top={true} footerText="is your early payment discount" />
					</Col>
				</Row>
			</div>
			{!DashboardStore.dashboardData ?
				<Spin size={'default'} className='m-20 d-flex justify-content-center'></Spin>
				: <Row gutter={[15, 15]}>
					{DashboardStore.dashboardData &&
						Object.entries(DashboardStore.dashboardData).map((item, index) => {
							var selectedValue = Object.entries(item[1])
							var img = index < 4 ? etr : creditorImage
							return (
								<Col md={6} key={index}>
									<DashboardWidget item={item} img={img} footerData={Object.entries(item[1])} defaultSelectValue={selectedValue[0][1].count} />
								</Col>
							)
						})
					}
				</Row>
			}


			<div className='main_buttton_wrapper m-15'>
				{<Button className='buttton_wrapper_1' onClick={openTradeFloor}>
					Open a Trade
				</Button>}
				{AppsAuthStore.permissions && AppsAuthStore.checkPermission(1001, 'DEBTORS') && <Button className='buttton_wrapper_1' onClick={() => { createCredebtor('Debtor') }}>
					Create Debtor
				</Button>}
				{AppsAuthStore.permissions && AppsAuthStore.checkPermission(1001, 'CREDITOR') && <Button className='buttton_wrapper_2' onClick={() => { createCredebtor('Creditor') }}>
					Create Creditor
				</Button>}
			</div>
			<AddComponent visible={credebtor} close={closeCredebtor} params='from_dashboard' />
		</PageHeader>
	);
})

export default OriginatorDashboard;